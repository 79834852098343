import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../_services/global.service';

@Component({
    selector: 'cron-job-history-page',
    templateUrl: './cron-job-history.component.html',
  })

export class CronJobHistoryPageComponent implements OnInit {

    public page:number = 1;
    public historyArray = [];
    public isLoading:boolean = true;
    
    public source = {
        1: 'portfolio',
        2: 'wallet',
        3: 'gateway'
    };

    public jobs = {
        0 : 'Spot Workspace Balance Snapshot',
        1 : 'Spot Workspace Balance Snapshot',
        2 : 'Workspace DeFi Sync',
        3 : 'Re-sync Xero',
        4 : 'Re-sync QuickBooks',
        5 : 'Re-sync Oracle NetSuite',
        6 : 'Disconnected connection',
        8 : 'NFT Floor Price Sync',
        9 : 'Calculate Portfolio Balance'
    };

    public statuses = {
        0: 'Pending',
        1: 'Running',
        2: 'Completed',
        3: 'Error'
    }

    constructor(public global: GlobalService){

    }

    ngOnInit(): void {
        this.getData(this.page);
    }

    public emitLoadData(){
        this.getData(this.page);
    }

    getData(page =1){
        this.global.loadingPortfolioHistoryPageStates = true;
        // this.historyArray = [];
        this.global.httpGET('get_cron_history',{page, portfolio_id:this.global.currentPortfolioId})
        .subscribe( res=>{
           
            let arr = [];
            let jobHistoryData = res.data.data.cron_history;
            let erpStatusData = res.data.data.erp_status;
            let invoiceData = res.data.data.invoice_data;

            for(const item of erpStatusData){
                arr.push({
                    created_at : item.created_at,
                    source: this.source[item.type],
                    status: item.status,
                    job: "Xero GL Account Sync"
                });
            }

            // clarify whether source is required
            for(const item of invoiceData){
                arr.push({
                    created_at : item.created_at,
                    // source: this.source[],
                    status: item.status,
                    job: "Invoice Sync"
                });
            }

            for(const job of jobHistoryData){

                if(job.task_id == 6 || job.task_id == 8 || job.task_id == 9){
                    arr.push({
                        created_at: job.created_at,
                        source: this.source[ job.task_id ],
                        job: this.jobs[ job.task_id ],
                        status: job.status
                    });
                }else{
                    arr.push({
                        created_at: job.created_at,
                        source: this.source[ job.task_id ],
                        job: this.jobs[ job.source ],
                        status: job.status
                    });
                }
            }

            arr.sort((a,b) => {
                return Date.parse(b.created_at) - Date.parse(a.created_at);
            });

            this.historyArray = arr;
            this.global.loadingPortfolioHistoryPageStates = false;
        }, err=>{
            this.global.loadingPortfolioHistoryPageStates = false;
        })
    }
}