import { Component, OnInit, Input, EventEmitter ,Output } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import { PolicyService } from '../../_services/policy.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html'
})

export class AddressListComponent implements OnInit {

	@Input() listShowEmail = false
	@Input() subAddressSelect = false
	

    @Output() selectedAddressEmit = new EventEmitter();

	objectKeys = Object.keys

	public modalRef

	public currentAddressViewIndex = null
	public subAddressesForContact = null
	public foundResults = []
	public searchMode = false
	public search = null
	public viewingAddress = null

	public viewingGroup = false

	public addressGroupSelectedListener:any = null

	constructor(public global: GlobalService, public coinService:CoinService, public policyService:PolicyService, public modalService: NgbModal) {
	}

	ngOnInit() {
		this.global.PAGE_INDEX = 2
		console.log(this.global.PAGE_INDEX);
		window.scroll(0, 0);
		this.global.getAllAddresses()

		this.addressGroupSelectedListener = this.global.addressGroupSelectedEmitter.subscribe(response => {
			
			if(response !== true) {
				this.viewingGroup = response
			}
			
			this.global.addressDisplayCount = null

			console.log(this.viewingGroup)
			this.search = null
			this.resetSearch()
			if(response !== false){
				this.searchAddress()
			} else {
				this.viewingGroup = false
				this.searchResults = []
				this.global.addressDisplayCount = 0
				this.searchMode = false
			}
		});
	}

	ngOnDestroy() {
		if(this.addressGroupSelectedListener!=null) {
			this.addressGroupSelectedListener.unsubscribe()
		}
	}

	public searching = 0

	searchResultsBackup = []
	searchResults = []
	specificSearch = false
	searchAddress(doSearch = false){

		if(doSearch == true && this.search == null){
			// dont do a search
			return
		}
		
		if(doSearch){
			this.specificSearch = true
		}

		this.searchMode = true
		this.searching = 1
		this.global.addressLoading = true

		this.global.httpGET('contact_search',{
			search:this.search, 
			groupId:(this.viewingGroup['id'] === undefined)?null:this.viewingGroup['id']
		}).subscribe(response => {
			this.searching = 0
			this.global.addressLoading = false
			if(response.status==true){

				if(doSearch == false){
					this.searchResultsBackup = response.data
				}

				this.searchResults = response.data
				if(this.searchResults) {
					this.global.addressDisplayCount = this.searchResults.length
				} else {
					this.global.addressDisplayCount = 0
				}
			} 
		}, error => {
			this.searching = 0
			this.global.addressLoading = false
		});

	}



	resetSearch(){
		if(this.specificSearch == true && this.viewingGroup != false){
			this.searchResults = this.searchResultsBackup
		} else {
			this.searchMode = false
		}
		
		this.specificSearch = false

		this.search = null
	}

	addressSelectAction(address, i){
		if(this.subAddressSelect){
			this.subAddressesForContact = address
			console.log(this.subAddressesForContact)
		} else {
			this.viewAddress(address, i)
		}
	}

	viewAddress(address, i){
		this.viewingAddress = address
		this.currentAddressViewIndex = i
		this.selectedAddressEmit.emit(address)
	}

	selectSubAddress(address, contactInfo){
		address['name'] = contactInfo['name']
		this.selectedAddressEmit.emit(address)
	}

}
