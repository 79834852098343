<!-- 
<app-header *ngIf="global._authStatus"></app-header>

<section style="background:#fff" class="fade-in">
	<div class="jumbotron jumbotron-cw text-center">
		<div class="container">
			<div class="container text-center">
				<h4>Contact Us</h4>
			</div>
		</div>
	</div>
	<div  class="container">
		<br/>
		<div class="row">
			<div class="col-lg-4">
				<div class="panel-body ">
					<div class="media">
						<div class="media-body">
							<h3>Email</h3>
							<p style="color: rgba(0, 0, 0, 0.9);">support@cryptoworth.app</p>	
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="panel-body ">
					<div class="media">
						<div class="media-body">
							<h3>Tel</h3>
							<p style="color: rgba(0, 0, 0, 0.9);">+1 438 924 7258</p>	
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="panel-body ">
					<div class="media">
						<div class="media-body">
							<h3>Mailing Address</h3>
							<p style="color: rgba(0, 0, 0, 0.9);">
								18 King Street East, Suite 1400 <br/>
								Toronto, ON M5C 1C4 <br/>
								Canada
							</p>	
						</div>
					</div>
				</div>
			</div>
		</div>
		<br/>
	</div>
</section>



<ng-template #contactInfoModal let-c="close" let-d="dismiss">
	<div class="modal-body">
			
			</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-main btn-lg " (click)="d('Cross click')">Close</button>
	</div>
	<br/><br/>
</ng-template> -->

<section style="background:#fff" class="fade-in">
	<div class="jumbotron-cw pt-4">
		<div class="container pt-6">
			<div class="row pt-4 pb-4">
				<div class="col-lg-6 ">
					<div class="ml-3">
						<h2 class="main-head wt und-white text-uppercase">Contact Us</h2>
						<h4 class="wt">Learn how you can move your business faster</h4>
						<br/>
						<button (click)="openModal(contactInfoModal)" class="btn btn-gs" href="#" role="button">Contact Us</button>
					</div>
					
				</div>
				<div class="col-lg-6 hide-small">
					<img class="float-right"  src="{{global.FE_IMG_URL}}img/chat.png" data-holder-rendered="true" height="250">
				</div>
			</div>
		</div>
	</div>
</section>



<ng-template #contactInfoModal let-c="close" let-d="dismiss">
	<div class="modal-body">
			<div class="jumbotron jumbotron-cw text-center">
					<div class="container">
						<div class="container text-center">
							<h4>Contact Us</h4>
						</div>
					</div>
				</div>
				<div  class="container">
					<br/>
					<div class="row">
						<div class="col-lg-4">
							<div class="panel-body ">
								<div class="media">
									<div class="media-body">
										<h3>Email</h3>
										<p style="color: rgba(0, 0, 0, 0.9);">support@cryptoworth.app</p>	
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="panel-body ">
								<div class="media">
									<div class="media-body">
										<h3>Tel</h3>
										<p style="color: rgba(0, 0, 0, 0.9);">+1 438 924 7258</p>	
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="panel-body ">
								<div class="media">
									<div class="media-body">
										<h3>Mailing Address</h3>
										<p style="color: rgba(0, 0, 0, 0.9);">
											18 King Street East, Suite 1400 <br/>
											Toronto, ON M5C 1C4 <br/>
											Canada
										</p>	
									</div>
								</div>
							</div>
						</div>
					</div>
					<br/>
				</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-main btn-lg " (click)="d('Cross click')">Close</button>
	</div>
	<br/><br/>
</ng-template>
