
import { Component } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import { ViewChild } from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

@Component({
  moduleId: module.id,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent { 

	public isNavbarCollapsed = true
	public resendStatus = 0;
	public modalRef;
	public formState = false;
	public errorCode = 0;
	public bugReport = {
		category_id: null,
		title: null,
		description: null
	}

	public tutorialViewIndex = 0
	public tutorialBarPerc = 5

    constructor(public global: GlobalService, public coinService:CoinService, public modalService: NgbModal) {
	}

	ngOnInit(){
		//if(this.global.)
		console.log('user_details')
		console.log(this.global._userDetails['tutorial'])
		//this.openTutorialModal(this.tutorialModal)

	}

	gotoSearch(text){
		this.global.router.navigate(['search', this.global.searchType, text]);
	}
	socketTest(){
	}
	resendActivationMail(){
		this.resendStatus = 1
		this.global.httpAPIGET(this.global.API_URL + 'resend_activation_mail?uId=' + this.global._userDetails['uId'])
		.subscribe(response => {
			console.log(response)
			if(response.resp_status==true){
				this.resendStatus = 2
			} else {
				this.resendStatus = 3
			}

		}, error => {
			console.log(error);// Error getting the data
			this.resendStatus = 3
		});
	}

	openRePaymentModule(modal){
		this.global.paymentModalRef= this.modalService.open(modal, {size:'lg', backdrop: 'static', keyboard: false})
	}

	skipTutorial(){
		this.global.perSessionShowTutorial = true
		this.modalRef.close()
	}

	// openTutorialModal(modal){
	// 	if(this.global._userDetails['tutorial']==0){
	// 		if(this.global.perSessionShowTutorial==null || !this.global.perSessionShowTutorial)
	// 			this.modalRef = this.modalService.open(modal, { size: 'lg'})
	// 	}	
	// }

	previousTutorialItem(){

		console.log(this.global._userDetails['tutorial'])
		if(this.tutorialViewIndex>0)
			this.tutorialViewIndex = this.tutorialViewIndex-1
			this.tutorialBarPerc = ((this.tutorialViewIndex+1)/7)*100
	}

	nextTutorialItem(){

		console.log(this.global._userDetails['tutorial'])
		if(this.tutorialViewIndex<=7){
			this.tutorialViewIndex = this.tutorialViewIndex+1
			this.tutorialBarPerc = ((this.tutorialViewIndex+1)/7)*100
		}
	}

	hideTutorialPermanent(){
		this.global.httpGET('hide_tutorial_permanent')
		.subscribe(response => {
			this.skipTutorial()
			this.global.doHeartBeat()
		}, error => {
			this.modalRef.close()
		});
	}

	openBugReport(modal) {
		this.formState = false;
		this.errorCode = 0;
		this.bugReport = { category_id: null, title: null, description: null };		
		this.modalRef = this.modalService.open(modal, { size: 'lg'});
	}

	reportBug() {
		if(this.bugReport.category_id == null)
			this.errorCode = 1;
		else if(this.bugReport.title == null || this.bugReport.title == "")
			this.errorCode = 2;
		else if(this.bugReport.description == null || this.bugReport.description == "")
			this.errorCode = 3;
		else if(this.bugReport.title.length > 250)
			this.errorCode = 4;
		else if(this.bugReport.description.length > 2500)
			this.errorCode = 5;
		else {
			this.formState = true;
			this.global.httpPOST('report_bug', { bugReport: this.bugReport })
			.subscribe(response => {
				this.modalRef.close();
			}, error => {});

		}
	}

}