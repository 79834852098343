import { Injectable, Output, EventEmitter } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common'
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { setTimeout } from 'timers';
import { transcode } from 'buffer';
import { response } from 'express';

import { ReportsService } from './reports/reports.service';
import { throwToolbarMixedModesError } from '@angular/material';
import { debug, time } from 'console';
import { environment } from 'environments/environment';


@Injectable()
export class CoinService {

	public debugAllowd = environment.production === false;


	public filteredVirtualWallets = []
	public filteredVirtualWalletsAllWallets = []
	public taxabilityIdentificationDetails = {
		type: 2,
		walletsSelected: [],
		virtualWalletsSelected: [],
		accountsSelected: []
	}
	public sanityCheckRunStatus = false

	// vriables for calculating net worth and graph stats
	public aggregateCoinInfo = {} // XRP: 237.64870521, ETH: 517.89, LTC: 245656.0 ////// in fiat
	public aggregateCoinInfoAmount = {} // BTG: 587.58916165, DCR: 427.64746176  //// in crypto amount
	public aggregateCoinDetailedInfo = [] // 0:  amount: 714.732948,amountInCurrency: 237.57723191519997, chng24h: -1.5402843601895846, image: "xrp.png", name: "Ripple", price: 0.3324, symbol: "XRP"
	public aggregateCoinDetailedInfoObj = {} // ETH:amount: 3, amountInCurrency: 518.49, chng24h: -1.0817307692307614, image: "eth.png", name: "Ethereum", price: 172.83, symbol: "ETH"
	//public aggregateCoinInfoAmountinFiat = {} // ETH: 518.49, GO: 8933.87860407528, LTC: 82.15 // in fiat

	// for dashboard pie chart
	public aggregateCoinValueDataSet = [] // {100, 230} // infiat
	public aggregateCoinNameDataSet = [] //
	public nonZeroBalances = []

	public exchangeRates = []

	public hotWalletTx = []

	public highest = null
	public lowest = null

	public highestPerformance = null
	public lowestPerformance = null


	public walletArraySize = 0
	public targetWalletArraySize = 0
	public globalnetWorthChangePercentage = 0

	// wallet loader
	public walletFetchingStatus = null
	public walletFetch = false
	public coinWalletAggregatedDataGrabSubscription: any = null

	public coinDetails
	public coinAggregatedData
	public CoinInfo
	public noreasonflag
	public sanityInfo = null

	public temp_netWorth = 0
	public noMarketValueSymbols = {}

	public quickSearchMode = false
	public userDeepSearched = false
	public quickSearchTransactionBackup = null

	public supportedWallets = [];

	public customConnectorList = [];

	public txTableDeepSearch = {
		mode: 0,
		query: null
	}

	public userNFTSearched = false
	public NFTSearch = {
		mode: 0,
		query: null
	}

	public transactionSyncPending = {

	}

	public aggregatedCoinColorDataSetRaw = [
		'#037dff',
		'#ffc903',
		'#c103ff',
		'#ff0303',
		'#03dcff',
		'#03ff16',
		'#ff8d00',
		'#40089a',
		'#089a8d',
		'#cddc39',
		'#df9308',
		'#f37f68',
		'#5d77c1',
		'#4e7d1d',
		'#1745a4',
		'#9a9114',
		'#81f548',
		'#71ce45',
		'#bb3258',
		'#f1d338',
		'#5b88fc',
		'#cb2cf1',
		'#7c8ac2',
		'#52a239',
		'#4d732b',
		'#b018f7',
		'#1978a3',
		'#0aa3da',
		'#a068b6',
		'#81e5fd',
		'#dbe802',
		'#38be9d',
		'#f23ba5',
		'#414f2d',
		'#de6f16',
		'#fd9e4c',
		'#7d7ea3',
		'#024a75',
		'#03e4e6',
		'#4be826',
		'#fa4b60',
		'#56ac9f',
		'#2a14a1',
		'#0a45c9',
		'#604ced',
		'#356e0e',
		'#cba2ec',
		'#1aaabe',
		'#7b17a6',
		'#918e6a',
		'#8b7b0b',
		'#f08b61',
		'#382ffe',
		'#6950c1',
		'#472bf5',
		'#5b6eb5',
		'#b10bcd',
		'#30cba0',
		'#483f87',
		'#b09528',
		'#400194',
		'#33ad57',
		'#ff56c3',
		'#f46654',
		'#0a11fc',
		'#461d19',
		'#0e146a',
		'#99775e',
		'#93c28c',
		'#9c1dcd',
		'#23eadd',
		'#c65bab',
		'#fba30d',
		'#7fbd0e',
		'#164165',
		'#9b7084',
		'#b65c1f',
		'#20b626',
		'#3759c2',
		'#dea03b',
		'#383369',
		'#f407b9',
		'#ef4f17',
		'#f23924',
		'#22281c',
		'#0d47a2',
		'#d1085a',
		'#1f17e3',
		'#66a63a',
		'#581fc8',
		'#5c2740',
		'#654526',
		'#af3b25',
		'#5f0e05',
		'#107db9',
		'#380f95',
		'#6ac0e8',
		'#e22b18',
		'#371f1e',
		'#783a7c',
		'#ac2a94',
		'#d203f1',
		'#720817',
		'#6927b1',
		'#412536',
		'#8f0fd3',
		'#5d3ecf',
		'#5c0598',
		'#1d2a74',
		'#113f2c',
		'#370559',
		'#d21a32',
		'#7613f4',
		'#676b5c'
	]

	public aggregateCoinColourDataSet = [
		{
			backgroundColor: this.aggregatedCoinColorDataSetRaw
		}
	];

	// home page main graph
	public lineChartData: Array<any> = [[]]; // y-axis
	public lineChartLabels: Array<any> = []; // x-axis
	public lineChartType: string = 'line';

	public lineChartOptions: any = {
		responsive: true,
		autoSkip: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				gridLines: {
					display: false
				},
				stacked: true,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 15,
					fontColor: '#12263f',
					maxRotation: 0,
					minRotation: 0
				}
			}],
			yAxes: [{
				gridLines: {
					display: true,
					borderDash: [1, 1]
				},
				ticks: {
					callback: function (label, index, labels) {
						let number = label
						let decPlaces = 2
						// 2 decimal places => 100, 3 => 1000, etc
						if (number < 10000) {
							var multiplier = Math.pow(10, decPlaces || 0);
							return Math.round(number * multiplier) / multiplier;
						} else {
							decPlaces = Math.pow(10, decPlaces);

							// Enumerate number abbreviations
							var abbrev = ["k", "m", "b", "t"];

							// Go through the array backwards, so we do the largest first
							for (var i = abbrev.length - 1; i >= 0; i--) {

								// Convert array index to "1000", "1000000", etc
								var size = Math.pow(10, (i + 1) * 3);

								// If the number is bigger or equal do the abbreviation
								if (size <= number) {
									// Here, we multiply by decPlaces, round, and then divide by decPlaces.
									// This gives us nice rounding to a particular decimal place.
									number = Math.round(number * decPlaces / size) / decPlaces;

									// Handle special case where we round up to the next abbreviation
									if ((number == 1000) && (i < abbrev.length - 1)) {
										number = 1;
										i++;
									}

									// Add the letter for the abbreviation
									number += abbrev[i];

									// We are done... stop
									break;
								}
							}
						}

						return number;
					}
				},
				scaleLabel: {
					display: false,
				}
			}]
		},
		tooltips: {
			mode: 'label',
			callbacks: {
				label: function (tooltipItem, data) {
					//let label = data.datasets[tooltipItem.datasetIndex].label;
					let number = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
					let decPlaces = 2
					// 2 decimal places => 100, 3 => 1000, etc
					if (number < 10000) {
						var multiplier = Math.pow(10, decPlaces || 0);
						return Math.round(number * multiplier) / multiplier;
					} else {
						decPlaces = Math.pow(10, decPlaces);

						// Enumerate number abbreviations
						var abbrev = ["k", "m", "b", "t"];

						// Go through the array backwards, so we do the largest first
						for (var i = abbrev.length - 1; i >= 0; i--) {

							// Convert array index to "1000", "1000000", etc
							var size = Math.pow(10, (i + 1) * 3);

							// If the number is bigger or equal do the abbreviation
							if (size <= number) {
								// Here, we multiply by decPlaces, round, and then divide by decPlaces.
								// This gives us nice rounding to a particular decimal place.
								number = Math.round(number * decPlaces / size) / decPlaces;

								// Handle special case where we round up to the next abbreviation
								if ((number == 1000) && (i < abbrev.length - 1)) {
									number = 1;
									i++;
								}

								// Add the letter for the abbreviation
								number += abbrev[i];

								// We are done... stop
								break;
							}
						}
					}

					return ['Amount' + ': ' + number];
				}
			}

		}
	};
	public lineChartLegend: boolean = false;

	public lineChartColors: Array<any> = [
		{ // grey
			backgroundColor: '#fff0',
			borderColor: '#16bdff',
			pointBackgroundColor: '#16bdff',
			pointBorderColor: '#16bdff',
			pointHoverBackgroundColor: '#16bdff',
			pointHoverBorderColor: '#16bdff'
		}

	];

	currentViewingWalletType = null
	currentViewingWalletDetails = null


	public coinExchangeFetchRecordExistant = true
	public mainNetWorthChartState = false

	// individual wallet chart data
	public lineChartFullDataAggregate: Array<any> = [];
	public lineChartFullDataAggregateVirtualWallets: Array<any> = [];


	public lastCalledTimestamp = null

	public loadErrors = []
	public coinAPIPullError = false
	public walletAggregatedCoinInfoChartData = []

	public serviceOutBlockChains = {}
	public marketInfoServiceOutBlockChains = {}

	public lastLoadedTime = null
	public lastLoadedConnectionGroupTime = null
	public userCoinAssetPriceCCAG = null
	public walletTokenBasedCoins = {}

	public connectedExchanges = null;
	public exchangeRefreshingMetaData = null


	public txFilterApplied = false
	public nftFilterApplied = false


	public PVlineChartData: Array<any> = [[]];
	public PVlineChartLabels: Array<any> = [];
	public portfolioAnalysisAssetLocation = {
		'wallets': 0,
		'exchanges': 0
	}

	public pvShowAssetallExpanded = false;
	public PVShowAssets = []

	public pendingCalculations = null
	public inProgressCalculations = null
	public failedCalculations = null
	public cancelledCalculation = null;

	public currentExchangeConnectionDetails = null

	public PVShowTags = []

	public maxDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
	private qbGlAccounts = null;
	private qbGlClasses = null;
	public defaultChartOfAccounts = null;

	@Output() userCurrencyChange: EventEmitter<any> = new EventEmitter();
	@Output() walletAddressValidationStatus: EventEmitter<any> = new EventEmitter();
	@Output() coinCurrencyPriceFetch: EventEmitter<any> = new EventEmitter();
	@Output() coinWalletAggregatedDataGrab: EventEmitter<any> = new EventEmitter();
	@Output() recalculatePages: EventEmitter<any> = new EventEmitter();

	constructor(
		private global: GlobalService,
		public http: Http,
		private route: ActivatedRoute,
		public router: Router,
		public numberPipe: DecimalPipe,
		public reportsService: ReportsService,
	) {

		this.heartbeatSubscriber = this.global.heartbeatEmitter.subscribe(res => {
			this.tablePageSizeNumber = this.global.appSettings.txtable_size;
		})
	}

	public setQbGlAccounts(accounts) {
		this.qbGlAccounts = accounts;
	}

	fetchingQBOClasses = false;

	public getQbGlClasses() {
		return this.qbGlClasses;
	}

	public setQbGlClasses(classess) {
		this.qbGlClasses = classess;
	}

	public getQbDepartments() {
		return this.qboDepartmens;
	}

	private onsDepartments = null;
	public getOnsDepartments() {
		return this.onsDepartments;
	}

	public setOnsDepartments(onsDepartmens) {
		this.onsDepartments = onsDepartmens;
	}

	private onsClasses = null;
	public getOnsClasses() {
		return this.onsClasses;
	}

	public setOnsClasses(classess) {
		this.onsClasses = classess;
	}

	// QB Departments
	public fetchingQBODepartments = false;
	public qboDepartmens = null;
	public setQbDepartments(qboDepartmens) {
		this.qboDepartmens = qboDepartmens;
	}


	public getQbGlAccounts() {
		return this.qbGlAccounts;
	}

	portfolioInit() {
		this.loadPortfolioTaxInfoIncompleted();
		this.global.getReportGenerationTypes();
		this.getHSWallet();
	}

	logout() {

		this.resetWalletLoading()
		this.lastLoadedTime = null
		this.global.lastLoadedTimeDeFi = null
		this.global.lastPortfolio = null
		this.lastLoadedConnectionGroupTime = null
		this.resetPortfolio()
	}

	public conectedGateways = {
		qbo: null,
		xero: null,
		ons: null,
		virtual: null,
		pennylane: null
	}

	txSearchState = false

	onFocusTxSearch(state) {
		this.txSearchState = state
		console.log(this.txSearchState)
	}

	lastUsedClassifications = []
	lastUsedClassificationsIds = []
	resetPortfolio() {
		this.lastUsedClassifications = []
		this.lastUsedClassificationsIds = []
		this.resetWalletLoading()
		this.global.selectedPivotTable = -1
		this.global.pinnedViews = [];
		this.global.showPinIFrame = false
		this.global.currentViewPin = null
		this.global.currentViewPinInfo = null
		this.global.currentViewVanilla = null
		this.global._wallets = null
		this.global._virtualWallets = null
		this.global._exchangesConnected = null
		this.global._exchangesActual = []
		this.global._walletsActual = []
		this.global._portfolioInfo = null
		this.global._portfolioInfo = null
		this.global._portfolio_currency = null
		this.global.currentPortfolioNewNavState = 0
		this.global._portfolio_currency_secondary = null
		this.global.currentPortfolio = null
		this.global.currentPortfolioId = null
		this.global._transactions = null
		this.global._ignoredTxns = []
		this.global._ignoredTxnAssets = []
		this.global._coingeckoUniqueString = null
		this.serviceOutBlockChains = {}
		this.transactionFetchTime = null
		this.global.selectedTx = []
		this.global.selectedTxDetails = []
		this.global.selectFullDropTxns = []
		this.global.selectFullTx = false
		this.global.taxDashboardFetchTime = false
		this.global.currentTransactionDetails = null
		this.global.accountViewMode = 1
		this.global.ERPStateLoader = false
		this.global.ERPStateLoaderFT = false
		this.global.showImportedERPCoAs = false
		this.archiveOnlyPull = false
		this.global.classificationsCount = -1
		this.noMarketValueSymbols = {}
		this.supportedWallets = []
		this.qbGlClasses = []
		this.fetchingQBOClasses = false;
		this.txSearchState = false;
		this.totalClassesCount = 0
		this.fetchingQBODepartments = false;
		this.reportsService.historicalBalanceSourcesParsed = null
		this.resetLogs()
		this.resetTableView()
		this.resetConnectionView()
		this.resetQuickSearch()
		this.conectedGateways = {
			qbo: null,
			xero: null,
			ons: null,
			virtual: null,
			pennylane: null
		}
	}

	resetLogs() {

		this.global.highlightedTransactions = [];
		this.global.showlogContainer = []
		this.global.showlogList = {};
		this.global.showLogLoadingIndex = []
	}

	resetWalletLoading() {

		if (this.coinWalletAggregatedDataGrabSubscription != null)
			this.coinWalletAggregatedDataGrabSubscription.unsubscribe()

		this.walletAggregatedCoinInfoChartData = [];
		this.global.currentTransactionDetails = null
		this.userCoinAssetPriceCCAG = null
		this.lineChartFullDataAggregate = [];
		this.aggregateCoinInfo = {}
		this.global._netWorth = 0
		this.aggregateCoinValueDataSet = []
		this.aggregateCoinNameDataSet = []
		this.aggregateCoinDetailedInfo = []
		this.walletArraySize = 0
		this.targetWalletArraySize = 0
		this.lastLoadedTime = null
		this.lastLoadedConnectionGroupTime = null
		this.global.lastLoadedTimeDeFi = null
		this.global._wallets = null
		this.global._virtualWallets = null
		this.global._dashboardData = null
		this.global._dashboardARAPData = null
		this.highest = null
		this.lowest = null
		this.highestPerformance = null
		this.lowestPerformance = null
		this.coinAPIPullError = false
		this.serviceOutBlockChains = {}
		this.global._transactions = null
		this.global._transactionTags = null
		this.global.ranReconCheck = false
		this.global._classifications = null
		this.global._labelLibrary = null
		this.global._transactionCount = null
		this.global.selectFullTx = null
		this.global.selectedTx = []
		this.global.selectFullDropTxns = []
		this.transactionFetchTime = null
		this.txFilterApplied = false
		this.global.quickFiltersConnectionsMode = 1
		this.nftFilterApplied = false;
		this.PVShowAssets = []
		this.global.flags = {
			portfolioNetworthDidcrepancy: false
		}

		// defi specific
		this.global.DeFiViewWallet = null
		this.global.DeFiViewMode = 0
		this.global.DeFiViewData = null
		this.global.DeFiLastViewWalletId = null
		this.global.DeFiLastViewTime = 0
		this.global.viewingReportId = 0

		this.resetFilters()

		// tax dashboard related variables.
		this.resetTaxDashboardVariables()

		// ui nav items
		this.global.userPortfolioSettings.navbarState = null


	}

	resetFilters() {
		this.global.userPortfolioSettings.txtable_filters = JSON.parse(JSON.stringify(this.global.defaultTxFilter))
		this.global.userPortfolioSettings.nft_filters = JSON.parse(JSON.stringify(this.global.defaultNftFilter))


	}

	getHSWallet() {
		if (this.global.shWallets == null) {
			this.global.httpGET('wallet_hs').subscribe(response => {
				if (response.status == true) {
					this.global.shWallets = response.data
				} else {
				}
			}, error => {
			});
		}
	}

	resetTaxDashboardVariables() {

		this.global._taxDB_proceesedUCGLAssets = []
		this.global._taxDB_processedUCGLConnections = []
		this.global._taxDB_yearCalculatedDataTotal = null
		this.global._taxDB_yearCalculatedCGL = null
		this.global._taxDB_yearCalculatedUCGL = null
		this.global._taxDB_yearData = null
		this.global._taxDB_calculatedTransactions = null
		this.global._taxDB_txCalculationMode = null
		this.global._taxDB_calculatedUCGL = null
		this.global._taxDB_calculatedCGL = null
		this.global._taxDB_calculatedDataTotal = null
		this.global._taxDB_calculatedAssetKeys = null
		this.global._taxDB_calculationSummary = null
		this.global._taxDB_calculationAssetDetails = []
		this.global._taxDB_yearDataDetails
		this.global._taxDB_taxYearView = 0;
		this.global._taxDB_taxlots = null
		this.global._taxDB_currencyDetails = null
		this.global._taxDB_calculatedUCGLUpdated = null
		this.global._taxDB_calculatedUCGLPurchaseTotal = null
		this.global._taxDB_calculatedUCGLCurrentTotal = null
		this.global._taxDB_processedTransactions = []
		this.global._taxDB_processedTransactionsAcquisitions = []
		this.global.txPageAgenda = false;
		this.global.txPageAgendaData = null;
		this.global.txPageAgendaType = null;
		this.global._taxDB_txBehaviorSanityFlag = null;
		this.global.txDashboardLoaded = false
	}

	// this is THE ENTRY FUNCTION
	getWallets() {

		if (this.lastLoadedTime == null) {

			this.lastLoadedTime = moment().unix()
			this.getWalletsRun()

		} else {
			let timeDifference = moment().unix() - this.lastLoadedTime
			if (timeDifference > 500) {

				this.lastLoadedTime = moment().unix()
				this.highestPerformance = null
				// this.getWalletsRun()
				this.getWalletsRun(false, false)

			} else {

				this.coingeckoDataCache = []
				this.getWalletsRun(false, true)
			}
		}
	}


	// updateDeFiInfo(){
	// 	if(!this.portfolioDeFiProtocolUpdating){
	// 		//this.global._wallets.forEach(element => {
	// 			// ethereum DeFi
	// 			//if(element.type_id == 2 || element.type_id == 1974 || element.type_id == 1594){
	// 				this.global.httpGET('get_defi_info',{portfolioId:this.global.currentPortfolioId, currencyCode:this.global._portfolio_currency['code']}).subscribe(response => {

	// 					if(response.status==true){
	//
	// 					}

	// 					this.portfolioDeFiProtocolUpdatingCounter--;

	// 					if(this.portfolioDeFiProtocolUpdatingCounter < 1){
	// 						this.portfolioDeFiProtocolUpdating = false
	// 					}

	// 				}, error => {
	// 					this.portfolioDeFiProtocolUpdating = false
	// 					this.portfolioDeFiProtocolUpdatingCounter--;
	// 				});
	// 			//}

	// 		//});
	// 	}
	// }

	archiveOnlyPull = null
	workspaceLoading = false

	getArchivedWorkspaces(archiveOnly) {
		this.setArchiveFlag(archiveOnly)
		this.getPortfolios()
	}

	getPortfolios(selectPortfolio = false, portfolioId = null, sortColumn = false, sortDirection = null, isObservable = false): Observable<any> {
		// if(portfolioId == null){
		// 	if(this.global._cookieService.check('current_portfolioId')){
		// 		this.global.currentPortfolioId = JSON.parse(this.global._cookieService.get('current_portfolioId'))
		// 	}
		// }

		if (sortDirection == null) { sortDirection = false }

		let portfoliosCall = this.global.httpGET('get_portfolios', {
			uId: this.global._userDetails['uId'],
			sortby: sortColumn,
			archiveOnly: this.archiveOnlyPull,
			order: sortDirection
		})

		if (isObservable) {
			// make sure to hide the loader in the "observed" function
			return portfoliosCall
		} else {
			this.workspaceLoading = true
			portfoliosCall.subscribe(response => {
				this.workspaceLoading = false
				this.global.portfolios = response.data
				if (selectPortfolio) {
					this.selectPortfolio()
				}
			}, error => {
				this.workspaceLoading = false
			});
		}
	}

	setArchiveFlag(archiveOnly) {
		this.global.portfolios = null
		if (archiveOnly != null) {
			this.archiveOnlyPull = archiveOnly
		} else {
			this.archiveOnlyPull = archiveOnly
		}
	}

	getPortfoliosInvitations(selectPortfolio = false, portfolioId = null) {

		this.global.httpGET('get_portfolio_invitations', { uId: this.global._userDetails['uId'] })
			.subscribe(response => {
				this.global.portfolioInvitations = response.data
			})
	}

	selectPortfolio() {
		if (this.global._cookieService.check('current_portfolioId')) {
			this.global.currentPortfolioId = JSON.parse(this.global._cookieService.get('current_portfolioId'))
			this.getWallets()

		}

		this.global.portfolios.forEach(element => {
			if (element.id == this.global.currentPortfolioId) {
				this.global.currentPortfolio = element
				this.global.lastPortfolio = { ...element }
				this.global._cookieService.set('current_portfolio_details', JSON.stringify(element), null, '/')
			}
		});
	}


	public portfolioLoadingPreCheck = false

	loadPortfolio(portfolio) {
		this.loadPortfolioMain(portfolio)
	}

	loadPortfolioNoURLRefresh(portfolio) {
		this.loadPortfolioMain(portfolio, true)
	}

	public onChangePortfolio$: BehaviorSubject<any> = new BehaviorSubject(null);

	loadPortfolioMain(portfolio, noURLrefresh = false) {
		this.global.showLoader()
		this.onChangePortfolio$.next(portfolio.id);
		if (portfolio.disabled == 1) {
			this.router.navigate(['workspace'], { skipLocationChange: false })
			return
		}
		this.resetWalletLoading()
		this.global._cookieService.set('current_portfolioId', JSON.stringify(portfolio.id), null, '/')
		this.updatePortfolioInfo(portfolio)
		//this.doPortfolioHearBeat()

		if (noURLrefresh) {
			this.global.portfolioInit()
		} else {

			this.qbGlAccounts = null;
			this.qbGlClasses = null;
			this.qboDepartmens = null;

			this.onsDepartments = null;
			this.onsClasses = null;

			this.global.router.navigate(['/workspace', portfolio.id, 'overview']);
		}
	}

	doPortfolioSetter(portfolioInfo) {

		this.global._portfolioInfo['reconcileThreshold'] = portfolioInfo.auto_reconcile_threshold;

		this.global._portfolioInfo['enableReconcile'] = portfolioInfo.auto_reconcile == 1 ? true : false;
		this.global._portfolioInfo['reconcileFiat'] = portfolioInfo.auto_reconcile_fiat;

		this.global._portfolioInfo['ignored_assets'] = portfolioInfo.ignored_assets;

		this.global._portfolioInfo['matchSwaps'] = portfolioInfo.match_swaps;
		this.global._portfolioInfo['trackDeFi'] = portfolioInfo.track_defi;
		this.global._portfolioInfo['midnight_snapshot'] = portfolioInfo.midnight_snapshot;
		this.global._portfolioInfo['logo_url'] = encodeURI(portfolioInfo.logo_url);
		this.global._portfolioInfo['calculation_settings'] = portfolioInfo.calculation_settings;
		this.global._portfolioInfo['erp_status'] = portfolioInfo.erp_status;
		this.global._portfolioInfo['erpSyncTypes'] = portfolioInfo.erpSyncTypes;
		this.global._portfolioInfo['workflowInfo'] = portfolioInfo.workflowInfo;
		this.global._portfolioInfo['external'] = portfolioInfo.external;
		this.global._portfolioInfo['FMVInfo'] = portfolioInfo.FMVInfo;
	}

	public portfolioSettingsEmit: EventEmitter<any> = new EventEmitter<any>();

	doPortfolioHearBeat() {
		this.global.httpGET('portfolio_heartbeat', { uId: this.global._userDetails['uId'], portfolioId: this.global.currentPortfolioId })
			.subscribe(response => {

				if (response.data.portfolioInfo != null) {

					if (this.global._portfolioInfo == null) {

						this.global._portfolioInfo = {}
					}

					this.doPortfolioSetter(response.data.portfolioInfo)
				}

				if (response.data.userSettingsPortfolio != null) {

					this.global.userPortfolioSettings = response.data.userSettingsPortfolio
					this.global['userPortfolioSettings']['navbarState'] = response.data.userSettingsPortfolio['navbar_state']
					this.global['userPortfolioSettings']['quick_filters'] = response.data.userSettingsPortfolio['quick_filters']


					if (typeof response.data.userSettingsPortfolio['quick_filters'] === 'undefined') {
						// variable is undefined
						this.global['userPortfolioSettings']['quick_filters'] = 1
					}

					if (response.data.userSettingsPortfolio.txtable_filters && response.data.userSettingsPortfolio.txtable_filters.syncStatus) {
						this.global.userPortfolioSettings.txtable_filters['syncStatus'] = { ...this.global.defaultTxFilter.syncStatus, ...response.data.userSettingsPortfolio.txtable_filters.syncStatus };
					} else {
						if (this.global.userPortfolioSettings.txtable_filters) {
							this.global.userPortfolioSettings.txtable_filters['syncStatus'] = { ...this.global.defaultTxFilter.syncStatus };
						}
					}

					if (response.data.userSettingsPortfolio.txtable_filters && response.data.userSettingsPortfolio.txtable_filters.erpSyncTypes) {

						this.global.userPortfolioSettings.txtable_filters['erpSyncTypes'] = response.data.userSettingsPortfolio.txtable_filters.erpSyncTypes;
					} else {

						if (this.global.userPortfolioSettings.txtable_filters) {
							this.global.userPortfolioSettings.txtable_filters['erpSyncTypes'] = { ...this.global.defaultTxFilter.erpSyncTypes };
						}

					}

					if (response.data.userSettingsPortfolio['connections_view_mode'] != null) {
						this.global['userPortfolioSettings']['connections_view_mode'] = response.data.userSettingsPortfolio['connections_view_mode']
					} else {
						this.global['userPortfolioSettings']['connections_view_mode'] = 'table'
					}

					if (response.data.chartOfAccountMainDefaults != null) {
						this.global.chartOfAccountMainDefaults = response.data.chartOfAccountMainDefaults
					}

					if (response.data.settingsMessages != null) {
						this.global.portfolioSettingsMessages = response.data.settingsMessages
					}

					if (response.data.transactionLocks != null) {
						this.global.portfolioTransactionLocks = response.data.transactionLocks
					}

					if (response.data.fasbDates != null) {
						this.global.portfolioFasbDates = response.data.fasbDates
					}


					// keep the filters last
					// if an error occurs on runtime, all the above code will be executed successfully
					if (
						response.data.userSettingsPortfolio.txtable_filters !== undefined &&
						response.data.userSettingsPortfolio.txtable_filters !== null &&
						this.global.defaultTxFilter['cryptoSymbolText'] == response.data.userSettingsPortfolio.txtable_filters.cryptoSymbolText &&
						this.global.defaultTxFilter['allExchangesConnect'] == response.data.userSettingsPortfolio.txtable_filters.allExchangesConnect &&
						this.global.defaultTxFilter['SyncedAtStartDate'] == response.data.userSettingsPortfolio.txtable_filters.SyncedAtStartDate &&
						this.global.defaultTxFilter['SyncedAtEndDate'] == response.data.userSettingsPortfolio.txtable_filters.SyncedAtEndDate &&
						this.global.defaultTxFilter['allWalletsConnect'] == response.data.userSettingsPortfolio.txtable_filters.allWalletsConnect &&
						this.global.defaultTxFilter['assetsAll'] == response.data.userSettingsPortfolio.txtable_filters.assetsAll &&
						this.global.defaultTxFilter['assetIncludeFees'] == response.data.userSettingsPortfolio.txtable_filters.assetIncludeFees &&
						this.global.defaultTxFilter['deviations'] == response.data.userSettingsPortfolio.txtable_filters.deviations &&
						this.global.defaultTxFilter['endDate'] == response.data.userSettingsPortfolio.txtable_filters.endDate &&
						this.global.defaultTxFilter['outputAddress'] == response.data.userSettingsPortfolio.txtable_filters.outputAddress &&
						this.global.defaultTxFilter['portfolioId'] == response.data.userSettingsPortfolio.txtable_filters.portfolioId &&
						this.global.defaultTxFilter['startDate'] == response.data.userSettingsPortfolio.txtable_filters.startDate &&
						this.global.defaultTxFilter['tagsAll'] == response.data.userSettingsPortfolio.txtable_filters.tagsAll &&
						this.global.defaultTxFilter['taxability'] == response.data.userSettingsPortfolio.txtable_filters.taxability &&
						this.global.defaultTxFilter['classesAll'] == response.data.userSettingsPortfolio.txtable_filters.classesAll &&
						this.global.defaultTxFilter['failedTx'] == response.data.userSettingsPortfolio.txtable_filters.failedTx &&
						this.global.defaultTxFilter['outputAddress'] == response.data.userSettingsPortfolio.txtable_filters.outputAddress &&
						(response.data.userSettingsPortfolio.txtable_filters.outputAddressGroup != undefined && response.data.userSettingsPortfolio.txtable_filters.outputAddressGroup.length == 0) &&
						this.global.defaultTxFilter['inputAddress'] == response.data.userSettingsPortfolio.txtable_filters.inputAddress &&
						(response.data.userSettingsPortfolio.txtable_filters.inputAddressGroup != undefined && response.data.userSettingsPortfolio.txtable_filters.inputAddressGroup.length == 0) &&
						this.global.defaultTxFilter['contractAddress'] == response.data.userSettingsPortfolio.txtable_filters.contractAddress &&
						(response.data.userSettingsPortfolio.txtable_filters.contractAddressGroup != undefined && response.data.userSettingsPortfolio.txtable_filters.contractAddressGroup.length == 0) &&
						this.global.defaultTxFilter['transactionId'] == response.data.userSettingsPortfolio.txtable_filters.transactionId &&
						this.global.defaultTxFilter['tradeId'] == response.data.userSettingsPortfolio.txtable_filters.tradeId &&
						this.global.defaultTxFilter['label'] == response.data.userSettingsPortfolio.txtable_filters.label &&
						this.global.defaultTxFilter['marketPair'] == response.data.userSettingsPortfolio.txtable_filters.marketPair &&
						this.global.defaultTxFilter['marketPairRadio'] == response.data.userSettingsPortfolio.txtable_filters.marketPairRadio &&
						this.global.defaultTxFilter['function'] == response.data.userSettingsPortfolio.txtable_filters.function &&
						this.global.defaultTxFilter['calcUsed'] == response.data.userSettingsPortfolio.txtable_filters.calcUsed &&
						this.global.defaultTxFilter['isPegged'] == response.data.userSettingsPortfolio.txtable_filters.isPegged &&
						this.global.defaultTxFilter['isLocked'] == response.data.userSettingsPortfolio.txtable_filters.isLocked &&
						this.global.defaultTxFilter['isPaired'] == response.data.userSettingsPortfolio.txtable_filters.isPaired &&
						this.global.defaultTxFilter['isInternalPaired'] == response.data.userSettingsPortfolio.txtable_filters.isInternalPaired &&
						this.global.defaultTxFilter['isRolled'] == response.data.userSettingsPortfolio.txtable_filters.isRolled &&
						this.global.defaultTxFilter['dataMerge'] == response.data.userSettingsPortfolio.txtable_filters.dataMerge &&
						this.global.defaultTxFilter['isMultipleEntry'] == response.data.userSettingsPortfolio.txtable_filters.isMultipleEntry &&
						this.global.defaultTxFilter['isSplitTransaction'] == response.data.userSettingsPortfolio.txtable_filters.isSplitTransaction &&
						this.global.defaultTxFilter['userHidden'] == response.data.userSettingsPortfolio.txtable_filters.userHidden &&
						this.global.defaultTxFilter['memo'] == response.data.userSettingsPortfolio.txtable_filters.memo &&
						this.global.defaultTxFilter['programId'] == response.data.userSettingsPortfolio.txtable_filters.programId &&
						this.global.defaultTxFilter['NFTName'] == response.data.userSettingsPortfolio.txtable_filters.NFTName &&
						this.global.defaultTxFilter['NFTSymbol'] == response.data.userSettingsPortfolio.txtable_filters.NFTSymbol &&
						this.global.defaultTxFilter['manuallyPriced'] == response.data.userSettingsPortfolio.txtable_filters.manuallyPriced &&
						this.global.defaultTxFilter['invoiceAttached'] == response.data.userSettingsPortfolio.txtable_filters.invoiceAttached &&
						this.global.defaultTxFilter['billAttached'] == response.data.userSettingsPortfolio.txtable_filters.billAttached &&
						this.global.defaultTxFilter['fileAttached'] == response.data.userSettingsPortfolio.txtable_filters.fileAttached &&
						this.global.defaultTxFilter['hasRuleId'] == response.data.userSettingsPortfolio.txtable_filters.hasRuleId &&
						this.global.defaultTxFilter['syncStatus'] == response.data.userSettingsPortfolio.txtable_filters.syncStatus &&
						this.global.defaultTxFilter['erpSyncTypes'] == response.data.userSettingsPortfolio.txtable_filters.erpSyncTypes &&
						this.global.defaultTxFilter['executorContractAddress'] == response.data.userSettingsPortfolio.txtable_filters.executorContractAddress &&
						(response.data.userSettingsPortfolio.txtable_filters.executorContractAddressGroup != undefined && response.data.userSettingsPortfolio.txtable_filters.executorContractAddressGroup.length == 0) &&
						this.global.defaultTxFilter['methodId'] == response.data.userSettingsPortfolio.txtable_filters.methodId &&
						(response.data.userSettingsPortfolio.txtable_filters.methodIdGroup != undefined && response.data.userSettingsPortfolio.txtable_filters.methodIdGroup.length == 0) &&
						this.global.defaultTxFilter['anyWeb3Product'] == response.data.userSettingsPortfolio.txtable_filters.anyWeb3Product &&
						response.data.userSettingsPortfolio.txtable_filters.inAmountOp == null &&
						response.data.userSettingsPortfolio.txtable_filters.outAmountOp == null &&
						response.data.userSettingsPortfolio.txtable_filters.feeAmountOp == null &&
						response.data.userSettingsPortfolio.txtable_filters.feeValueOp == null &&
						response.data.userSettingsPortfolio.txtable_filters.costBasisOp == null &&
						response.data.userSettingsPortfolio.txtable_filters.salePriceOp == null &&
						response.data.userSettingsPortfolio.txtable_filters.unitPriceOp == null &&
						response.data.userSettingsPortfolio.txtable_filters.fiatValueOp == null &&
						response.data.userSettingsPortfolio.txtable_filters.runningBalanceValueOp == null
					) {


					} else {

						if (response.data.userSettingsPortfolio.txtable_filters == null) {

						} else {

							this.txFilterApplied = true

							if (this.global.newPortfolio) {
								this.txFilterApplied = false
							}
						}

					}


					if (response.data.userSettingsPortfolio.nft_filters == null) {
						this.nftFilterApplied = false;
						response.data.userSettingsPortfolio.nft_filters = this.global.defaultNftFilter;
					} else {
						if (
							this.global.defaultNftFilter['endDate'] == response.data.userSettingsPortfolio.nft_filters.endDate &&
							this.global.defaultNftFilter['startDate'] == response.data.userSettingsPortfolio.nft_filters.startDate
						) {
						} else {
							this.nftFilterApplied = true

						}
					}

					this.portfolioSettingsEmit.emit(response.data.userSettingsPortfolio)
				}


			})

	}

	setPortfolioPermission(permissions) {

		if (permissions.write == 1) {
			this.global._privileges['modifyCurrentPortfolio'] = true
		} else if (permissions.read == 1) {
			this.global._privileges['modifyCurrentPortfolio'] = false
		} else {
			this.global._privileges['modifyCurrentPortfolio'] = false
		}
	}

	updatePortfolioInfo(portfolio) {
		this.global._portfolioInfo = portfolio
		this.global.lastPortfolio = portfolio
		this.global._portfolio_currency = this.global._portfolioInfo.portfolio_currency;
		this.global._portfolio_currency_secondary = this.global._portfolioInfo.portfolio_currency_secondary;
		this.global.txPageAgenda = false;
		this.global.txPageAgendaData = null;
		this.global.txPageAgendaType = null;
		this.global._taxDB_txBehaviorSanityFlag = null;
		this.setQbGlAccounts([]);
	}

	public transactionFetchTime = null
	public txReloading = false
	public txSort = {
		'date': 'DESC',
		'cost': null,
		'sales': null,
		'inAmount': null,
		'outAmount': null,
		'unitPrice': null
	};


	public txSortEnables = {
		date: true,
		cost: false,
		sales: false,
		inAmount: false,
		outAmount: false,
		unitPrice: false
	};




	changeTxSortDate(sort, isCalc = false) {
		this.txSort['date'] = sort;

		this.txSortEnables = {
			date: true,
			cost: false,
			sales: false,
			inAmount: false,
			outAmount: false,
			unitPrice: false
		};

		this.txSort['cost'] = null;
		this.txSort['sales'] = null;

		this.txSort['inAmount'] = null;
		this.txSort['outAmount'] = null;
		this.txSort['unitPrice'] = null;

		if (!isCalc) {
			this.getPortfolioTransactions()
		} else {
			this.getPortfolioTransactionsForCalculations()
		}
	}

	changeTxSortCost(sort, isCalc = false) {
		this.txSort['cost'] = sort;

		this.txSortEnables = {
			date: false,
			cost: true,
			sales: false,
			inAmount: false,
			outAmount: false,
			unitPrice: false
		};

		this.txSort['date'] = null;
		this.txSort['sales'] = null;

		this.txSort['inAmount'] = null;
		this.txSort['outAmount'] = null;
		this.txSort['unitPrice'] = null;

		if (!isCalc) {
			this.getPortfolioTransactions()
		} else {
			this.getPortfolioTransactionsForCalculations()
		}
	}

	changeTxSortSales(sort, isCalc = false) {
		this.txSort['sales'] = sort;

		this.txSortEnables = {
			date: false,
			cost: false,
			sales: true,
			inAmount: false,
			outAmount: false,
			unitPrice: false
		};

		this.txSort['cost'] = null;
		this.txSort['date'] = null;

		this.txSort['inAmount'] = null;
		this.txSort['outAmount'] = null;
		this.txSort['unitPrice'] = null;

		if (!isCalc) {
			this.getPortfolioTransactions()
		} else {
			this.getPortfolioTransactionsForCalculations()
		}
	}

	// in/ out/ unit price
	changeTxSortInAmount(sort, isCalc = false) {

		this.txSortEnables = {
			date: false,
			cost: false,
			sales: false,
			inAmount: true,
			outAmount: false,
			unitPrice: false
		};

		this.txSort['inAmount'] = sort;
		this.txSort['outAmount'] = null;
		this.txSort['unitPrice'] = null;

		this.txSort['cost'] = null;
		this.txSort['sales'] = null;
		this.txSort['date'] = null;

		if (!isCalc) {
			this.getPortfolioTransactions()
		} else {
			this.getPortfolioTransactionsForCalculations()
		}
	}

	changeTxSortOutAmount(sort, isCalc = false) {

		this.txSortEnables = {
			date: false,
			cost: false,
			sales: false,
			inAmount: false,
			outAmount: true,
			unitPrice: false
		};

		this.txSort['inAmount'] = null;
		this.txSort['outAmount'] = sort;
		this.txSort['unitPrice'] = null;

		this.txSort['cost'] = null;
		this.txSort['sales'] = null;
		this.txSort['date'] = null;

		if (!isCalc) {
			this.getPortfolioTransactions()
		} else {
			this.getPortfolioTransactionsForCalculations()
		}
	}

	changeTxSortUnitPrice(sort, isCalc = false) {

		this.txSortEnables = {
			date: false,
			cost: false,
			sales: false,
			inAmount: false,
			outAmount: false,
			unitPrice: true
		};

		this.txSort['inAmount'] = null;
		this.txSort['outAmount'] = null;
		this.txSort['unitPrice'] = sort;

		this.txSort['cost'] = null;
		this.txSort['sales'] = null;
		this.txSort['date'] = null;

		if (!isCalc) {
			this.getPortfolioTransactions()
		} else {
			this.getPortfolioTransactionsForCalculations()
		}
	}

	tablePageNumber: number = 1
	tableSelectedPageNumber: number = 1
	tablePageSizeNumber: number = 50
	tableTotalPages: number = 0
	tableOffset = 0
	public PAGE_NAV = {
		FIRST_PAGE: 0,
		NEXT_PAGE: 1,
		PREV_PAGE: 2,
		LAST_PAGE: 4
	};

	connectionPageNumber: number = 1
	connectionSelectedPageNumber: number = 1
	connectionPageSizeNumber: number = 50
	connectionTotalPages: number = 0
	connectionOffset = 0
	public CON_PAGE_NAV = {
		FIRST_PAGE: 0,
		NEXT_PAGE: 1,
		PREV_PAGE: 2,
		LAST_PAGE: 4
	};

	resetConnectionView() {

		this.connectionPageNumber = 1
		this.connectionSelectedPageNumber = 1
		this.connectionPageSizeNumber = 50
		this.connectionTotalPages = -1
		this.connectionOffset = 0
		this.CON_PAGE_NAV = {
			FIRST_PAGE: 0,
			NEXT_PAGE: 1,
			PREV_PAGE: 2,
			LAST_PAGE: 4
		};
	}

	public heartbeatSubscriber = null;
	resetTableView() {

		this.tablePageNumber = 1
		this.tableSelectedPageNumber = 1
		this.tablePageSizeNumber = 50
		this.tableTotalPages = -1
		this.tableOffset = 0
		this.PAGE_NAV = {
			FIRST_PAGE: 0,
			NEXT_PAGE: 1,
			PREV_PAGE: 2,
			LAST_PAGE: 4
		};
	}

	pageNavSetup(direction: number) {
		if (direction == this.PAGE_NAV.FIRST_PAGE) {
			this.tablePageNumber = 1;
		} else if (direction == this.PAGE_NAV.LAST_PAGE) {
			this.tablePageNumber = this.tableTotalPages;
		} else if (direction === this.PAGE_NAV.NEXT_PAGE) {
			this.tablePageNumber += 1;
		} else if (direction === this.PAGE_NAV.PREV_PAGE) {
			this.tablePageNumber -= 1;
		}
		this.tableSelectedPageNumber = this.tablePageNumber;
		return this.tablePageSizeNumber * (this.tablePageNumber - 1);
	}

	pageNav(offset) {
		this.getPortfolioTransactions(false, offset, true);
	}

	txCountLoading = false
	getPortfolioTransactions(append = false, offset = 0, noTxCountFetch = false) {
		//var offset = 0;
		let filterObject;

		if (this.txFilterApplied != false) {
			filterObject = JSON.stringify(this.global.userPortfolioSettings.txtable_filters)
		} else {
			filterObject = null
		}

		if (!append) {
			this.txReloading = true;
			this.global._transactions = [];
		}

		// also get issues
		//this.global.getPortfolioIssues(true);
		let sort = {};

		for (let i in this.txSort) {
			if (this.txSort[i] !== null) {
				sort[i] = this.txSort[i];
			}
		}

		let options = {
			portfolioId: this.global.currentPortfolioId,
			offset: this.tableOffset,
			txSort: sort,
			filters: filterObject,
			viewSettings: this.global.appSettings.txtable_columns,
			showMulti: this.global.appSettings.txtable_row_expand,
			txConsolidation: this.global.appSettings.txtable_multi_line_consolidation,
			size: this.tablePageSizeNumber,
			specialFunction: this.global.txPageAgenda ? this.global.txPageAgenda : false
		};
		this.global.appSettings.txtable_size = this.tablePageSizeNumber;

		this.global.httpPOST('get_portfolio_transactions', options)
			.subscribe(response => {
				this.txReloading = false
				if (!append) {
					this.global._transactions = response.data

					if (!append && !noTxCountFetch) {
						this.txCountLoading = true
						this.global.httpPOST('get_portfolio_transactions_count', { portfolioId: this.global.currentPortfolioId, filters: filterObject, specialFunction: this.global.txPageAgenda ? this.global.txPageAgenda : false })
							.subscribe(response => {
								this.txCountLoading = false
								this.global._transactionCount = response.data
								this.recalculatePages.emit(true)
							}, error => {
								this.txCountLoading = false
							})
					}

				} else {
					this.global._transactions = this.global._transactions.concat(response.data);
				}
			})
	}

	public calculationfilterTransactionsDetails = null
	getPortfolioTransactionsForCalculations(append = false) {

		var offset = 0;
		let filterObject;

		filterObject = JSON.stringify(this.calculationfilterTransactionsDetails)


		if (this.global._calcualationTransactions != null && append)
			offset = this.global._calcualationTransactions.length;

		if (!append) {
			this.txReloading = true
		}

		let sort = {};

		for (let i in this.txSort) {
			if (this.txSort[i] !== null) {
				sort[i] = this.txSort[i];
			}
		}

		let options = {
			portfolioId: this.global.currentPortfolioId,
			offset: offset,
			txSort: sort,
			filters: filterObject
		};

		this.global.httpPOST('get_portfolio_transactions', options)
			.subscribe(response => {
				this.txReloading = false
				if (!append)
					this.global._calcualationTransactions = response.data
				else
					this.global._calcualationTransactions = this.global._calcualationTransactions.concat(response.data);
			})
	}

	checkIfExcluded(transactionId) {
		if (this.global.specialSelectTx != null) {
			return (this.global.specialSelectTx.includes(transactionId))
		} else {
			return true
		}

	}

	doscheduledTransactionFetch(force = false) {
		if (force == false) {
			if (this.transactionFetchTime == null) {
				this.transactionFetchTime = moment().unix()

				this.getPortfolioTransactions()
			} else {
				let timeDifference = moment().unix() - this.transactionFetchTime
				if (timeDifference > 20) {
					this.transactionFetchTime = moment().unix()
					this.getPortfolioTransactions()
				}
			}
		} else {
			this.getPortfolioTransactions()
		}

	}

	getWalletsForce() {
		this.lastLoadedTime = moment().unix()
		// wallet force set to true to avoid the infinite loop on the connections page
		this.getWalletsRun()
	}

	getDeFiWalletsForce() {
		this.global.lastLoadedTimeDeFi = moment().unix()

	}

	resetWalletLoadingSoft() {
		this.walletAggregatedCoinInfoChartData = [];
		this.lineChartFullDataAggregate = [];
		this.aggregateCoinInfo = {}

		this.global._wallets = null
		this.global._virtualWallets = [];

		this.aggregateCoinValueDataSet = []
		this.aggregateCoinNameDataSet = []
		this.aggregateCoinDetailedInfo = []
		this.aggregateCoinInfoAmount = {}
		this.aggregateCoinDetailedInfoObj = {}
		this.walletArraySize = 0
		this.targetWalletArraySize = 0

		this.temp_netWorth = 0

		// when the portfolio loads for the very first time
		if (this.walletFetchingStatus != null) {
			this.walletFetchingStatus = 1
		}
	}

	public globalLiveWalletSync = false

	connectionListUpdateEmitter: any = new EventEmitter();

	async getConnectionWalletInfo(asset = null) {

		this.loadingPortfolioBlances = true;

		if (this.global.currentPortfolioId == null) {
			return
		}
		await this.global.httpGET("portfolio_wallet_connections", {
			"portfolioId": this.global.currentPortfolioId,
			"asset": asset,
		}).toPromise()
			.then(resp => {
				if (resp.status == 200 && resp.data != null) {
					this.global.walletData = resp.data;
				} else {
					console.log("error loading wallet info");
				}

				this.loadingPortfolioBlances = false;

			}).catch(error => {
				console.error(error);

				this.loadingPortfolioBlances = false;
			});
	}

	async getConnectionVirtualWalletInfo(asset = null) {

		this.loadingPortfolioBlances = true;

		if (this.global.currentPortfolioId == null) {
			return
		}
		await this.global.httpGET("portfolio_virtual_wallet_connections", {
			"portfolioId": this.global.currentPortfolioId,
			"asset": asset,
		}).toPromise()
			.then(resp => {
				if (resp.status == 200 && resp.data != null) {
					this.global.virtualWallets = resp.data;
				} else {
					console.log("error loading virtual wallet info");
				}

				this.loadingPortfolioBlances = false;

			}).catch(error => {
				console.error(error);
				this.loadingPortfolioBlances = false;
			});
	}

	async getPortfolioVersion() {
		if (this.global.currentPortfolioId == null) {
			return
		}

		await this.global.httpGET("get_portfolio_version", {
			"portfolioId": this.global.currentPortfolioId,
		}).toPromise()
			.then(resp => {
				if (resp.status == 200 && resp.data != null) {
					this.global.release = resp.data.release;
				} else {
					console.log("error loading portfolio version");
				}

			}).catch(error => {
				console.error(error);
			});
	}

	public loadingPortfolioVersion = false

	async updatePortfolioVersion(version, id = null) {
		let portfolio = id
		if (portfolio == null) {
			portfolio = this.global.currentPortfolioId
		}

		this.loadingPortfolioVersion = true

		await this.global.httpPOST("update_portfolio_version", {
			"portfolioId": portfolio,
			"version": version,
		}).toPromise()
			.then(resp => {
				if (resp.status == 200 && resp.data != null) {
					console.log(resp.message);
					this.global.release = resp.release;
				} else {
					console.log("error updating portfolio version");
				}

			}).catch(error => {
				console.error(error);
			});

		this.loadingPortfolioVersion = false
	}

	public loadingConnectionAssets = false;

	getAssetsInConnection(parent_id = null) {

		this.loadingConnectionAssets = true;

		if (this.global.currentPortfolioId == null) {
			return
		}
		this.global.httpGET("get_assets_in_wallet", {
			"portfolioId": this.global.currentPortfolioId,
			"vsCurrency": this.global._portfolio_currency.code,
			"parent_id": parent_id,
		}).toPromise()
			.then(resp => {
				if (resp.status == true) {
					this.global.connectionAssets = resp.data;
				} else {
					console.log("error loading asset info");
				}

				this.loadingConnectionAssets = false;

			}).catch(error => {
				console.error(error);

				this.loadingConnectionAssets = false;
			});
	}

	async getWalletsRun(hard = false, hotReload = false) {

		if (!hotReload) {
			this.coinAPIPullError = false
			this.globalLiveWalletSync = true
		}

		this.getSyncedExchanges()
		this.global.httpGET('get_wallets', { uId: this.global._userDetails['uId'], portfolioId: this.global.currentPortfolioId, hotReload: false }).subscribe(response => {

			if (!hotReload) {
				this.resetWalletLoadingSoft()
			}

			if (response.status == true) {

				if (!hotReload) {
					if (this.walletFetchingStatus == null)
						this.walletFetchingStatus = 0

					this.global.currentPortfolio = response.data.current_portfolio
					// this.global.portfolios			= response.data.portfolios

					let groupedWallets = this.walletGrouping(response.data.wallets)
					//this.global.getPortfolioIgnoredAssets()
					let ignoredWallets = response.data['ignoredAssets']

					var filteredWallets = groupedWallets.filter(function (wallet) {
						return !ignoredWallets.includes(wallet.type_id)
					});

					this.global._wallets = filteredWallets
					this.global._virtualWallets = response.data.virtualWallets

					if (response.data.wallets != null) {
						if (response.data.wallets.length > 0) {
							if (this.global._walletsActual === null) {
								this.global._walletsActual = []
							}
							response.data.wallets.forEach(element => {

								if (element.parent_wallet_id == null) {
									this.global._walletsActual.push(element)
								}
							});
						}

					}

					if (this.global._virtualWallets != null) {
						if (this.global._virtualWalletsActual === null) {
							this.global._virtualWalletsActual = []
						}
						this.global._virtualWallets.forEach(element => {
							if (element.exchange_connection_id == 0) {
								this.global._virtualWalletsActual.push(element)
							}
						});

					}

					if (this.global._wallets.length == 0 && this.global._virtualWallets.length == 0) {
						this.globalLiveWalletSync = false
					}

					this.global._assetUniqueString = response.data.uniqueSetString
					this.global._assetUniqueDetailedString = response.data.walletUniqueSetDetailed
					this.global._coingeckoUniqueString = response.data.coingeckoUniqueString

					this.global._portfolioInfo['assetPegs'] = response.data.assetPegs

					this.walletArraySize = this.global._wallets.length + this.global._virtualWallets.length
					this.debugAllowd && console.log(this.walletArraySize)
					this.connectionListUpdateEmitter.emit(true)

					if (this.global.release == null) {
						this.getPortfolioVersion();
					}

					if (this.global.release == "v2" && this.global.coinBalanceInfo.length == 0) {
						this.updatePortfolioBalance()
						this.executePortfolioBalanceAPI()
						this.getPortfolioLoadingStatus()
					}

					this.connectionListUpdateEmitter.emit(true)

					this.getFMVData(this.global._assetUniqueString, hard)
					if (response.data.doHardRefresh == true) {
						this.doHardWalletRefresh(response)
					}

					this.countConnections()
					this.findConnectionsTotalValue()

				} else {
					this.doWalletHotReload(response.data.wallets)
				}

			} else if (response.status == false) {
				this.walletFetchingStatus = 99
			}
		}, error => {
		});
	}

	getCustomConnectors() {
		this.global.httpGET('custom_connectors', { portfolioId: this.global.currentPortfolioId }).subscribe(response => {
			if (response.status == true) {
				this.customConnectorList = response.data
			} else {
			}
		}, error => {
		});
	}


	resetSanityInfo() {
		this.sanityInfo = null
		this.sanityCheckRunStatus = false
	}

	runSanityCheck(id, type) {
		this.taxabilityIdentificationDetails = {
			type: 2,
			walletsSelected: [],
			virtualWalletsSelected: [],
			accountsSelected: []
		}

		if (type === 'WALLET') {
			this.taxabilityIdentificationDetails['walletsSelected'] = [id]
		}
		if (type === 'EXCHANGE') {
			this.taxabilityIdentificationDetails['accountsSelected'] = [id]
		}
		if (type === 'VIRTUAL_WALLET') {
			this.taxabilityIdentificationDetails['virtualWalletsSelected'] = [id]
		}
		this.sanityCheckRunStatus = true

		this.global.httpPOST('wallet_run_sanity_check', {
			portfolioId: this.global.currentPortfolioId,
			assetFlowCountDetails: {
				startDate: "2016-01-01",
				endDate: null
			},
			selectedSources: this.taxabilityIdentificationDetails
		}).subscribe(response => {
			this.sanityCheckRunStatus = true
		}, error => {
			this.sanityCheckRunStatus = false
		});
	}

	getWalletSanityCheckInfo(wallet) {
		this.global.httpGET('wallet_get_sanity_info', {
			portfolioId: this.global.currentPortfolioId,
			walletId: wallet
		}).subscribe(response => {
			if (response.status == true) {
				this.sanityInfo = response.data
			}
		}, error => {
			this.debugAllowd && console.log('Failed!');
		})
	}

	getConnectionSanityCheckInfo(connId, connType) {
		this.global.httpGET('get_connection_sanity_info', {
			portfolioId: this.global.currentPortfolioId,
			connectionId: connId,
			connectionType: connType
		}).subscribe(response => {
			if (response.status == true) {
				this.sanityInfo = response.data
			}
		}, error => {
			this.debugAllowd && console.log('Failed!');
		})
	}

	doWalletHotReload(wallets) {
		this.global._wallets.forEach(wallet => {
			if (wallets.length > 0) {
				wallets.forEach(newWallet => {
					if (wallet.id == newWallet.id) {
						wallet.deep_sync_status = newWallet.deep_sync_status
						wallet.sub_wallet_sync_status = newWallet.sub_wallet_sync_status
						wallet.sub_wallet_sync_status_has_failed = newWallet.sub_wallet_sync_status_has_failed
						wallet.last_sync_transactions = newWallet.last_sync_transactions
					}
				});
			}
		});
	}


	public cssSelector = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];
	walletGrouping(wallets) {
		let markIndex = 0
		wallets.forEach(element => {
			if (element.parent_wallet_id == null) {
				wallets.forEach(innerElement => {
					if (innerElement.parent_wallet_id != null) {
						if (element.id == innerElement.parent_wallet_id) {
							element.group = markIndex
							innerElement.group = markIndex
							element.css = this.cssSelector[markIndex]
							innerElement.css = this.cssSelector[markIndex] + '-sm'
						}

					}
				});

				markIndex++
			}

		});
		return wallets
	}

	doHardWalletRefresh(response) {
		setTimeout(() => {
			this.resetWalletLoadingSoft()
			if (this.walletFetchingStatus == null)
				this.walletFetchingStatus = 0

			this.global.currentPortfolio = response.data.current_portfolio
			this.global.portfolios = response.data.portfolios
			this.global._wallets = response.data.wallets
			this.global._virtualWallets = response.data.virtualWallets

			this.walletArraySize = this.global._wallets.length + this.global._virtualWallets.length

			this.globalLiveWalletSync = true
			this.getFMVData(null, true)

		}, 3000);
	}

	//get the exchanges the user is connected to
	public loadingExchangesConnected = false
	getSyncedExchanges(doGlobalWalletRefresh = false) {
		this.loadingExchangesConnected = true
		this.global.httpPOST('get_exchange_list', { portfolioId: this.global.currentPortfolioId }).subscribe(response => {
			this.loadingExchangesConnected = false
			if (response.status == true) {
				this.global._exchangesConnected = response.data
				this.global._exchangesActual = response.data
				this.connectionListUpdateEmitter.emit(true)
				this.countConnections()
				this.findConnectionsTotalValue()
				// after getting the exhcnages, fetch the asset list from each exchange


			}
		}, error => {
			this.loadingExchangesConnected = false
		});
	}
	totalConnectionsCount = 0

	countConnections() {
		let connectionsCount = 0

		if (this.global._exchangesConnected != null) {
			this.global._exchangesConnected.forEach(element => {
				connectionsCount++
			});
		}

		if (this.global._wallets != null) {
			this.global._wallets.forEach(element => {
				if (element.parent_wallet_id == null) {
					connectionsCount++
				}
			});

		}

		if (this.global._virtualWallets != null) {
			this.global._virtualWallets.forEach(element => {
				if (element.exchange_connection_id == 0) {
					connectionsCount++
				}
			});
		}

		this.totalConnectionsCount = connectionsCount
	}

	refreshUserExchangeData() {
		this.global.exchangeSyncStatus = 1
		this.global.httpGET('refresh_exchanges', { portfolioId: this.global.currentPortfolioId }).subscribe(response => {
			if (response.status == true) {
				this.getWalletsForce()
				this.global.exchangeSyncStatus = 0
				this.exchangeRefreshingMetaData = response.data
			} else {
			}
		}, error => {
		});
	}

	public updatePortfolioBlances = false;

	async updatePortfolioBalance() {

		this.updatePortfolioBlances = true;

		if (this.global.currentPortfolioId == null || this.global._portfolio_currency == null) {
			return
		}

		await this.global.httpPOST("update_portfolio_balance", {
			"portfolioId": this.global.currentPortfolioId,
			"vsCurrency": this.global._portfolio_currency.code,
		}).toPromise()
			.then(resp => {
				if (resp.status == 200) {
					console.log("updating...");
				} else {
					console.log("error on api post portfolio_balance");
				}

				this.updatePortfolioBlances = false;

			}).catch(error => {
				this.debugAllowd && console.error(error);
				this.updatePortfolioBlances = false;
			});
	}

	public loadingPortfolioBlances = false;
	public initPortfolioBalance = false;

	async executePortfolioBalanceAPI() {
		this.loadingPortfolioBlances = true;

		if (this.global._portfolio_currency == null || this.global.currentPortfolioId == null) {
			return;
		}

		await this.global.httpPOST("portfolio_balance", {
			"portfolioId": this.global.currentPortfolioId,
			"vsCurrency": this.global._portfolio_currency.code,
			"cgString": this.global._coingeckoUniqueString,
		}).toPromise()
			.then(resp => {
				if (resp.status == 200 && resp.data != null) {

					this.global.coinBalanceInfo = resp.data.assets;
					this.global.defiBalanceInfo = resp.data.defiBalance;
					this.global.totalProtfolioBalance = resp.data.totalBalance;

					this.global.coinValueDataSet = [];
					this.global.coinNameDataSet = [];

					this.global.coinBalanceInfo.forEach(val => {
						this.global.coinValueDataSet.push(val.amountInCurrency)
						this.global.coinNameDataSet.push(val.symbol)
						this.PVShowAssets[val.symbol] = true
					});

					this.pvShowAssetallExpanded = false

					this.loadingPortfolioBlances = false;
					this.initPortfolioBalance = true;

				} else {
					console.log("error on api post portfolio_balance");
				}

			}).catch(error => {
				this.debugAllowd && console.error(error);
				this.loadingPortfolioBlances = false;
				this.initPortfolioBalance = false;
			});
	}

	async getPortfolioLoadingStatus() {

		if (this.global.currentPortfolioId == null || this.global._portfolio_currency == null) {
			return
		}

		await this.global.httpGET("portfolio_loading_status", {
			"portfolioId": this.global.currentPortfolioId,
			"vsCurrency": this.global._portfolio_currency.code,
		}).toPromise()
			.then(resp => {
				if (resp.status == 200 && resp.data != null) {
					console.log("updating...");
					this.global.portfolioLoadingStatus = resp.data
				} else {
					console.log("error on api post portfolio_balance");
				}


			}).catch(error => {
				this.debugAllowd && console.error(error);
			});
	}

	async executeWalletLoop(hard = false, specificId = null, multi = false) {

		this.global._virtualWallets.forEach(element => {
			this.getPriceInUserCurrency(element)
		});

		if (hard) {
			this.doLiveWalletAmountCache = true
			var root = this;
			let count = 0;
			//this.global._wallets.forEach(element => {
			for (const element of this.global._wallets) {

				count++
				if (element.parent_wallet_type == null) {

					if (specificId != null && specificId != element.id) {
						continue
					}

					element['sync_status'] = 1
					var date = new Date();
					let month = Number(date.getMonth()) + 1;
					let dateString = date.getFullYear() + '-' + month + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':00'
					element.last_sync = dateString

					if (hard) {

						if (count % 4 == 0) {

							await new Promise(r => setTimeout(r, 1000));

							if (this.global.release == "v2") {
								this.getWalletDetailsV2(element)
							} else {
								await this.getWalletDetails(element)
							}

						} else {

							if (this.global.release == "v2") {
								this.getWalletDetailsV2(element)
							} else {
								await this.getWalletDetails(element)
							}

						}

						if (specificId != null) {
							this.findConnectionsTotalValue()
							this.cacheWalletAmounts()
						}

					} else {
						this.getPriceInUserCurrency(element)
					}

				} else {
					this.getPriceInUserCurrency(element)
				}
			}
			//});
		} else {
			this.global._wallets.forEach(element => {
				this.getPriceInUserCurrency(element)
			});
		}
	}


	validateWalletAddress(walletDetails, extended = false) {
		walletDetails['type_id'] = walletDetails.type
		this.getWalletDetails(walletDetails, true, false, extended, null, null, 1)
	}

	handleError(element = null, isMarket = false) {
		this.walletArraySize = this.walletArraySize - 1
		if (!this.serviceOutBlockChains.hasOwnProperty(element['symbol'])) {
			this.serviceOutBlockChains[element['symbol']] = element
		}
		element.sync_status = null
		if (this.walletArraySize == this.targetWalletArraySize) {
			this.globalLiveWalletSync = false
			this.coinWalletAggregatedDataGrab.emit(true)
		}
		this.walletAddressValidationStatus.emit(false)
	}

	public liveWalletAddAmountTemp = null
	public subTokensInWallet = null
	public getBalanceRunning = false
	public getBalanceRunningCheck = false

	async getWalletDetailsV2(element, validate = false, requireEmmiter = false) {
		console.log("Omit get_balance_service");
		if (validate) {
			this.liveWalletAddAmountTemp = element.amount;
			this.walletAddressValidationStatus.emit(true)
		} else {
			this.getPriceInUserCurrency(element, requireEmmiter);
		}

		setTimeout(() => {
			if (!this.getBalanceRunningCheck) {
				this.getBalanceRunning = false
			}
		}, 2000);
	}

	async getWalletDetails(element, validate = false, requireEmmiter = false, extended = false, contractAddress = null, fiatSpecified = null, firstTimeSync = 0) {
		let id = +element.type_id // cast to int
		switch (id) {

			default: {
				this.getBalanceRunning = true
				this.getBalanceRunningCheck = true
				await this.global.httpPOST("get_balance_service", {
					"portfolioId": this.global.currentPortfolioId,
					"api_key": element.address,
					"type_id": element.type_id,
					"first_time_sync": firstTimeSync
				}).toPromise()
					.then(resp => {
						this.getBalanceRunningCheck = false

						if (validate) {
							this.liveWalletAddAmountTemp = resp.data.balance;
							this.walletAddressValidationStatus.emit(true)
						} else {
							// this makes the balance update right away
							element.amount = resp.data.balance;
							this.getPriceInUserCurrency(element, requireEmmiter);
						}

						setTimeout(() => {
							if (!this.getBalanceRunningCheck) {
								this.getBalanceRunning = false
							}
						}, 2000);

						// this.liveWalletAddAmountTemp = (+parsedResponse[0])
						// this.walletAddressValidationStatus.emit(true)
					}).catch(error => {
						this.getBalanceRunningCheck = false
						setTimeout(() => {
							if (!this.getBalanceRunningCheck) {
								this.getBalanceRunning = false
							}
						}, 2000);
						this.debugAllowd && console.error(error);
						this.handleError(element)
					});


				break;
			}
		}
	}

	setGlobalAssetPriceError(element) {

		this.global.flags.portfolioNetworthDidcrepancy = true
		this.handleError(element, true)
		this.coinAPIPullError = true
		let symbolToTake = null

		if (element['symbol'] == null) {
			symbolToTake = element['temp_symbol']
		} else {
			symbolToTake = element['symbol']
		}

		if (!this.noMarketValueSymbols.hasOwnProperty(symbolToTake)) {
			let elementClone = { ...element };
			elementClone['symbol'] = symbolToTake
			elementClone['display_symbol'] = symbolToTake
			elementClone['type'] = symbolToTake

			if (element['symbol'] == null) {
				elementClone['unsupported'] = true
			} else {
				elementClone['unsupported'] = false
			}

			this.noMarketValueSymbols[symbolToTake] = elementClone
		}

	}

	setGlobalAssetPriceObject(element, responseRAW, code, source) {
		if (isNaN(element.amount)) {
			element.amount = 0
		}

		let priceReposne = 0;

		if (responseRAW[element.symbol][code]['PRICE']) {
			priceReposne = responseRAW[element.symbol][code]['PRICE']

		} else {
			this.global.flags.portfolioNetworthDidcrepancy = true
		}

		element.amountInCurrency = priceReposne * element.amount
		element.amountInCurrency = Math.round(element.amountInCurrency * 100) / 100;

		element.is_pegged = responseRAW[element.symbol][code]['PEGGED']

		if (element.is_debt == 1) {
			element.amountInCurrency = element.amountInCurrency * -1
		}


		if (this.walletFetchingStatus == 0) {

			this.global._netWorth = this.global._netWorth + element.amountInCurrency
		} else {

			this.temp_netWorth = this.temp_netWorth + element.amountInCurrency
		}

		// add to the aggregates (onlyfordashboardCalculations)
		if (this.aggregateCoinInfo.hasOwnProperty(element.symbol)) {
			let integerValue = +this.aggregateCoinInfo[element.symbol]
			this.aggregateCoinInfo[element.symbol] = integerValue + +element.amountInCurrency

			let interValueOfCoinAmount = +this.aggregateCoinInfoAmount[element.symbol]
			this.aggregateCoinInfoAmount[element.symbol] = interValueOfCoinAmount + +element.amount

			this.aggregateCoinDetailedInfo.forEach(loopingElement => {
				if (loopingElement.symbol == element.symbol) {
					loopingElement.amountInCurrency = +this.aggregateCoinInfo[element.symbol]
					loopingElement.amount = +loopingElement.amount + +element.amount
				}
			});

			let index = this.aggregateCoinNameDataSet.indexOf(element.symbol);
			this.aggregateCoinValueDataSet[index] = this.aggregateCoinInfo[element.symbol]

		} else {
			this.aggregateCoinInfo[element.symbol] = element.amountInCurrency
			this.aggregateCoinInfoAmount[element.symbol] = element.amount

			let coin = {
				'priceSource': source,
				'asset_name': element.assetName,
				'asset_id': element.type_id,
				'display_symbol': element.display_symbol,
				'symbol': element.symbol,
				'name': element.type,
				'amount': element.amount,
				'amountInCurrency': element.amountInCurrency,
				'image': element.image,
				'price': responseRAW[element.symbol][code]['PRICE'],
				'PEGGED': responseRAW[element.symbol][code]['PEGGED'],
				'chng24h': responseRAW[element.symbol][code]['CHANGEPCT24HOUR'],
				'chng1h': responseRAW[element.symbol][code]['CHANGEHOUR']
			}

			this.aggregateCoinDetailedInfo.push(coin)
			this.aggregateCoinDetailedInfoObj[element.symbol] = coin

			// for dashboard pie chart
			this.aggregateCoinValueDataSet.push(element.amountInCurrency)

			this.aggregateCoinNameDataSet.push(element.symbol)
		}

		element['sync_status'] = 2
		this.aggregateCoinValueDataSet = this.aggregateCoinValueDataSet.slice();

		this.targetWalletArraySize++

		// change here for dynamic chart info
		if (this.walletArraySize == this.targetWalletArraySize) {
			this.coinWalletAggregatedDataGrab.emit(true)
			this.globalLiveWalletSync = false

			if (this.walletFetchingStatus == 1) {
				this.global._netWorth = this.temp_netWorth
				//this.getWalletsRun(false, false)
			}
		}
	}


	formIndividualCoinHistoricalData(data, coinDetail) {

		let MainArray: Array<any> = []

		MainArray[0] = []
		MainArray[1] = []
		MainArray[3] = coinDetail
		if (data.Data.length > 0) {
			data.Data.forEach(element => {
				MainArray[0].push(String(moment.unix(element.time).format("LT")))
				let value = element.close
				MainArray[1].push(+value)
			});
		}

		if (coinDetail.chng24h >= 0) {

			MainArray[2] = [
				{ // grey
					backgroundColor: '##fff',
					borderColor: '#04c51d',
					pointBackgroundColor: '#fff0',
					pointBorderColor: '#fff0',
					pointHoverBackgroundColor: '#fff0',
					pointHoverBorderColor: '#fff0'
				}
			]
		} else {
			MainArray[2] = [
				{ // grey
					backgroundColor: '##fff',
					borderColor: '#ef0000',
					pointBackgroundColor: '#fff0',
					pointBorderColor: '#fff0',
					pointHoverBackgroundColor: '#fff0',
					pointHoverBorderColor: '#fff0'
				}
			]

		}

		this.walletAggregatedCoinInfoChartData.push(MainArray)
		this.walletAggregatedCoinInfoChartData.sort((a, b) => b[3].amountInCurrency - a[3].amountInCurrency);
	}

	updatePortfolioSyncValue() {

		this.global.httpPOST('portfolio_update_sync_value', {
			value: this.global._netWorth,
			currency: this.global._portfolio_currency,
			portfolioId: this.global.currentPortfolioId
		}).subscribe(response => {


		}, error => {
		});
	}

	calculateNetWorthPercentageChange(type, calculateGlobalNetWorthPercentage = false) {
		this.updatePortfolioSyncValue()
		this.coinExchangeFetchRecordExistant = true
		this.globalnetWorthChangePercentage = 0

		this.lineChartData = [[]]
		this.lineChartLabels = []
		this.mainNetWorthChartState = false
		let count = 0

		if (type == 'hour')
			this.walletAggregatedCoinInfoChartData = []

		for (let coinDetail of this.aggregateCoinDetailedInfo) {

			this.globalnetWorthChangePercentage = this.globalnetWorthChangePercentage + +coinDetail.chng24h

			// this.getCoinHistoricalData(type, coinDetail.symbol).subscribe(data => {

			// 	if(type=='hour'){
			// 		this.formIndividualCoinHistoricalData(data, coinDetail)
			// 	}

			// 	if(data.Response=='Success'){

			// 		count++
			// 		if(count==this.aggregateCoinDetailedInfo.length && this.lastCalledTimestamp==null){
			// 			this.mainNetWorthChartState = true
			// 		}

			// 		let _lineChartData:Array<any> = []
			// 		let _lineChartLabels:Array<any> = []
			// 		let i=0;
			// 		if(data.Data.length>0){
			// 			data.Data.forEach(element => {


			// 					if(type=='week' || type=='month'){
			// 						_lineChartLabels.push(String(moment.unix(element.time).format("MMM Do YY")))
			// 					} else if(type=='3months' || type=='6months'){
			// 						let conversionTimeStamp = String(moment.unix(element.time).format("MMM"))
			// 						_lineChartLabels.push(conversionTimeStamp)
			// 					} else if(type=='year'){
			// 						let conversionTimeStamp = String(moment.unix(element.time).format("MMM"))
			// 						_lineChartLabels.push(conversionTimeStamp)
			// 					} else {
			// 						_lineChartLabels.push(String(moment.unix(element.time).format("HH:mm")))
			// 					}

			// 					let value = +element.close * +coinDetail['amount']
			// 					_lineChartData.push(+value)

			// 			});
			// 		}

			// 		if(this.lineChartData[0].length==0){
			// 			this.lineChartData[0] = _lineChartData
			// 		} else {
			// 			for (let index = 0; index < this.lineChartData[0].length; index++) {
			// 				this.lineChartData[0][index] = this.lineChartData[0][index] + _lineChartData[index];
			// 			}
			// 		}

			// 		this.lineChartLabels = _lineChartLabels

			// 	} // end of response.success
			// 	else {
			// 		this.mainNetWorthChartState = true
			// 		this.coinAPIPullError = true
			// 		this.loadErrors.push(coinDetail)
			// 	}


			// })

		};

	}

	calculate24HighLowAssets() {
		for (let wallet of this.aggregateCoinDetailedInfo) {
			// forming the doughnut chart
			if (this.highest == null) {
				this.highest = wallet
			} else {
				if (wallet['amountInCurrency'] > this.highest['amountInCurrency']) {
					this.highest = wallet
				}
			}

			if (this.lowest == null) {
				this.lowest = wallet
			} else {
				if (wallet['amountInCurrency'] < this.lowest['amountInCurrency']) {
					this.lowest = wallet
				}
			}

			if (this.highestPerformance == null) {
				this.highestPerformance = wallet
			} else {
				if (wallet['chng24h'] > this.highestPerformance['chng24h']) {
					this.highestPerformance = wallet
				}
			}

			if (this.lowestPerformance == null) {
				this.lowestPerformance = wallet
			} else {
				if (wallet['chng24h'] < this.lowestPerformance['chng24h']) {
					this.lowestPerformance = wallet
				}
			}
		}
	}

	getIndividualWalletChartData() {

		let MainArray: Array<any> = []
		for (let wallet of this.global._wallets) {
			this.getCoinHistoricalData('hour', wallet.symbol).subscribe(data => {

				MainArray = []
				if (data.Response == 'Success') {

					MainArray[0] = []
					MainArray[1] = []
					let i = 0;
					if (data.Data.length > 0) {
						data.Data.forEach(element => {
							MainArray[0].push(String(moment.unix(element.time).format("LT")))
							let value = element.close
							MainArray[1].push(+value)
						});
					}
				}
				this.lineChartFullDataAggregate.push(MainArray)
			})
		};
		for (let wallet of this.global._virtualWallets) {
			this.getCoinHistoricalData('hour', wallet.symbol).subscribe(data => {

				MainArray = []
				if (data.Response == 'Success') {

					MainArray[0] = []
					MainArray[1] = []
					let i = 0;
					if (data.Data.length > 0) {
						data.Data.forEach(element => {
							MainArray[0].push(String(moment.unix(element.time).format("LT")))
							let value = element.close
							MainArray[1].push(+value)
						});
					}
				}
				this.lineChartFullDataAggregateVirtualWallets.push(MainArray)
			})
		};
	}

	round(value, precision) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	numberToHuman(number, decPlaces) {

		// 2 decimal places => 100, 3 => 1000, etc
		if (number < 10000) {
			var multiplier = Math.pow(10, decPlaces || 0);
			return Math.round(number * multiplier) / multiplier;
		} else {
			decPlaces = Math.pow(10, decPlaces);

			// Enumerate number abbreviations
			var abbrev = ["k", "m", "b", "t"];

			// Go through the array backwards, so we do the largest first
			for (var i = abbrev.length - 1; i >= 0; i--) {

				// Convert array index to "1000", "1000000", etc
				var size = Math.pow(10, (i + 1) * 3);

				// If the number is bigger or equal do the abbreviation
				if (size <= number) {
					// Here, we multiply by decPlaces, round, and then divide by decPlaces.
					// This gives us nice rounding to a particular decimal place.
					number = Math.round(number * decPlaces / size) / decPlaces;

					// Handle special case where we round up to the next abbreviation
					if ((number == 1000) && (i < abbrev.length - 1)) {
						number = 1;
						i++;
					}

					// Add the letter for the abbreviation
					number += abbrev[i];

					// We are done... stop
					break;
				}
			}
		}


		return number;
	}

	changeCurrencyGlobalPoint(currencyCode) {
		this.global.httpGET('change_currency', { uId: this.global._userDetails['uId'], currency_code: currencyCode }).subscribe(response => {
			if (response.resp_status == true) {
				//set the cookie
				this.global._cookieService.set('currency', response.data)
				this.global._portfolio_currency = response.data
				this.getWallets()
				this.userCurrencyChange.emit(true);

			} else {
			}
		}, error => {
		});
	}

	// used for the chart
	getCoinHistoricalData(type, symbol): Observable<any> {

		this.currentViewingWalletType = type
		this.currentViewingWalletDetails = symbol

		this.currentViewingWalletType = type
		this.currentViewingWalletDetails = symbol


		let activeCode = null

		if (this.global._portfolio_currency != null) {

			activeCode = this.global._portfolio_currency['code']
		} else {

			activeCode = this.global._currency['code']
		}

		if (type == 'hour') {
			return this.http.get('https://min-api.cryptocompare.com/data/histominute?fsym=' + symbol + '&tsym=' + activeCode + '&limit=61&aggregate=1&e=CCCAGG')
				.pipe(map(res => res.json()))
		} else if (type == 'day') {
			return this.http.get('https://min-api.cryptocompare.com/data/histominute?fsym=' + symbol + '&tsym=' + activeCode + '&limit=1450&aggregate=1&e=CCCAGG')
				.pipe(map(res => res.json()))
		} else if (type == 'week') {
			return this.http.get('https://min-api.cryptocompare.com/data/histohour?aggregate=1&e=CCCAGG&extraParams=CryptoCompare&fsym=' + symbol + '&limit=169&tryConversion=false&tsym=' + activeCode)
				.pipe(map(res => res.json()))
		} else if (type == 'month') {
			return this.http.get('https://min-api.cryptocompare.com/data/histohour?aggregate=1&e=CCCAGG&extraParams=CryptoCompare&fsym=' + symbol + '&limit=726&tryConversion=false&tsym=' + activeCode)
				.pipe(map(res => res.json()))
		} else if (type == '3months') {
			return this.http.get('https://min-api.cryptocompare.com/data/histoday?aggregate=1&e=CCCAGG&extraParams=CryptoCompare&fsym=' + symbol + '&limit=91&tryConversion=false&tsym=' + activeCode)
				.pipe(map(res => res.json()))
		} else if (type == '6months') {
			return this.http.get('https://min-api.cryptocompare.com/data/histoday?aggregate=1&e=CCCAGG&extraParams=CryptoCompare&fsym=' + symbol + '&limit=181&tryConversion=false&tsym=' + activeCode)
				.pipe(map(res => res.json()))
		} else if (type == 'year') {
			return this.http.get('https://min-api.cryptocompare.com/data/histoday?aggregate=1&e=CCCAGG&extraParams=CryptoCompare&fsym=' + symbol + '&limit=366&tryConversion=false&tsym=' + activeCode)
				.pipe(map(res => res.json()))
		} else if (type == '3year') {
			return this.http.get('https://min-api.cryptocompare.com/data/histoday?aggregate=1&e=CCCAGG&extraParams=CryptoCompare&fsym=' + symbol + '&limit=1106&tryConversion=false&tsym=' + activeCode)
				.pipe(map(res => res.json()))
		} else if (type == '5year') {
			return this.http.get('https://min-api.cryptocompare.com/data/histoday?aggregate=1&e=CCCAGG&extraParams=CryptoCompare&fsym=' + symbol + '&limit=1834&tryConversion=false&tsym=' + activeCode)
				.pipe(map(res => res.json()))
		}

	}


	cacheWalletAmounts() {

		let tempCacheArray = []
		let tempObject = {}
		this.global._wallets.forEach(element => {

			if (!this.serviceOutBlockChains.hasOwnProperty(element['symbol'])) {
				tempObject = {}

				tempObject = {
					walletId: element.id,
					amount: element.amount
				}
				tempCacheArray.push(tempObject)

			}

		});

		this.global.httpPOST('cache_live_wallets', { cacheData: tempCacheArray, cacheSubTokens: this.subTokensInWallet }).subscribe(response => {

		}, error => {
		});


	}

	getPriceAnalytics(fsym, unixTimeStamp): Observable<any> {
		return this.global.http.get('https://min-api.cryptocompare.com/data/pricehistorical?fsym=' + fsym + '&tsyms=' + this.global._portfolio_currency['code'] + '&ts=' + unixTimeStamp)
			.pipe(map(res => res.json()))
	}

	getCurrentTimestamp() {
		var date = new Date();

		let month = Number(date.getMonth()) + 1;

		let dateString = date.getFullYear() + '-' + month + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':00'

		return dateString;
	}

	getEthFromDai(value) {
		return value / 1000000000000000000
	}

	getSymbolCostBasis(stringCurrency) {
		if (stringCurrency != null) {
			let parsedString = stringCurrency.split("__")
			return parsedString[1]
		} else {
			return '-'
		}

	}

	getCodeCostBasis(stringCurrency) {
		if (stringCurrency != null) {

			let parsedString = stringCurrency.split("__")
			return parsedString[0]
		} else {
			return '-'
		}
	}

	classificationLoading = false

	getClassificationsFilterd(isObservable = false, sortCol = null, order = 0): Observable<any> {
		this.classificationLoading = true;
		let options = {
			portfolioId: this.global.currentPortfolioId
		}

		if (sortCol !== null) {
			options['sortBy'] = sortCol;
		}

		if (order !== 0) {
			options['order'] = order;
		}
		let classificationCall = this.global.httpGET('get_classifications', options);

		if (isObservable) {
			// make sure to hide the loader in the "observed" function
			return classificationCall
		} else {
			classificationCall.subscribe(response => {
				this.classificationLoading = false
				this.global._classifications = response.data
				this.classificationAssignGlobal(response.data)
			}, error => {
				this.classificationLoading = false
			});
		}
	}


	getClassifications(isObservable = false, mode = 0, sortDirection = null): Observable<any> {
		this.classificationLoading = true
		let sortBy = null;

		if (mode == 1) {
			sortBy = 'name'
			sortDirection = 1
		}

		let classificationCall = this.global.httpGET('get_classifications', { portfolioId: this.global.currentPortfolioId, sortBy: sortBy, order: sortDirection })

		if (isObservable) {
			// make sure to hide the loader in the "observed" function
			return classificationCall
		} else {
			classificationCall.subscribe(response => {

				this.classificationLoading = false
				this.global._classificationsAscending = response.data

				if (mode == 0) {
					this.global._classifications = response.data
					this.classificationAssignGlobal(response.data)
				}
			}, error => {
				this.classificationLoading = false
			});
		}
	}

	loadingClassificationsCount = false

	getClassificationsCount() {

		this.loadingClassificationsCount = true
		this.global.httpGET('get_classifications_count', { portfolioId: this.global.currentPortfolioId })
			.subscribe(response => {
				this.loadingClassificationsCount = false
				this.global.classificationsCount = response.data
			}, error => {
				this.loadingClassificationsCount = false
			});

	}

	labelLoading = false
	getLabelLibrary(isObservable = false): Observable<any> {
		this.labelLoading = true
		let labelLibraryCall = this.global.httpGET('label_library', { portfolioId: this.global.currentPortfolioId })

		if (isObservable) {
			// make sure to hide the loader in the "observed" function
			return labelLibraryCall
		} else {
			labelLibraryCall.subscribe(response => {
				this.labelLoading = false
				this.global._labelLibrary = response.data
			}, error => {
				this.labelLoading = false
			});
		}

	}

	classificationAssignGlobal(classifications) {
		classifications.forEach(element => {
			this.global._classificationsFiltered[element.id] = element.name
			if (element['L1'].length > 0) {
				element['L1'].forEach(innerelement => {
					this.global._classificationsFiltered[innerelement.id] = innerelement.name
				})

			}
		});
		this.global._classificationsAscending = classifications
	}

	getPortfolioTags(isObservable = false): Observable<any> {
		let tagCall = this.global.httpGET('get_portfolio_tags', { portfolioId: this.global.currentPortfolioId })

		if (isObservable) {
			return tagCall
		} else {
			tagCall.subscribe(response => {
				this.global._transactionTags = response.data

			}, error => {
			});
		}

	}


	CGLCalculated(tx) {
		if ('capital_gain_loss' in tx) {
			return true
		}
	}

	getCalculationReportName(method) {
		if (method == 1) {
			return 'Cost Basis FIFO'
		} else if (method == 2) {
			return 'Cost Basis LIFO'
		} else if (method == 6) {
			return 'Cost Basis HIFO'
		} else if (method == 3) {
			return 'WAC'
		} else if (method == 4) {
			return 'WAC Perpetual (Tranched)'
		} else if (method == 0) {
			return 'Fair Market Values (FMVs) Only'
		} else if (method == 5) {
			return 'WAC Perpetual'
		}
	}

	public incomepleteLoadingMode = false
	loadPortfolioTaxInfoIncompleted() {
		this.incomepleteLoadingMode = true
		this.global.httpGET('get_reports_dashboard_info_full_incompleted', { portfolioId: this.global.currentPortfolioId }).subscribe(response => {

			if (response.status == true) {
				if (response.data != null) {

					this.pendingCalculations = response.data.pending
					this.inProgressCalculations = response.data.in_progress

					if (this.inProgressCalculations != null) {
						this.global.currentPortfolioTaxCalProcessCount = this.inProgressCalculations.calc_count
					}

					this.failedCalculations = response.data.failed
					this.cancelledCalculation = response.data.cancelled;

					this.incomepleteLoadingMode = false

				} else {
					this.incomepleteLoadingMode = false
				}


			} else {
				this.incomepleteLoadingMode = false
			}
		}, error => {
			this.incomepleteLoadingMode = false
		});
	}

	getCalculationPercentage() {
		let percentage = (this.global.currentPortfolioTaxCalProcessCount / this.inProgressCalculations.count) * 100
		return percentage.toFixed(2);
	}

	getCalculationPercentageNew() {
		let calcOne = this.global.currentPortfolioTaxCalProcessCount + this.inProgressCalculations.count
		let percentageNew = (this.global.currentPortfolioTaxCalProcessCount / calcOne) * 100
		return percentageNew.toFixed(2);
	}

	getCalculation() {
		let percentageOld = (this.global.currentPortfolioTaxCalProcessCount + this.inProgressCalculations.count)
		let percentageNew = (this.global.currentPortfolioTaxCalProcessCount / percentageOld)
		return percentageOld;
	}


	gotoLink(url, url2 = null) {
		if (this.gotoLinkCheck(url)) {
			this.resetPortfolio()

			if (url2 == null) {
				this.global.router.navigate([url]);
			} else {
				this.global.router.navigate([url, url2]);
			}
		}

	}

	gotoLinkCheck(url) {
		if (url == 'commerce') {
			if (this.global._packagePurchaseDetails['app_commerce'] == 0) {
				return false
			} else {
				return true
			}
		} else if (url == 'address-book') {
			return true
		} else if (url == 'support') {
			return true
		} else if (url == '/company') {
			return true
		} else if (url == '/settings') {
			return true
		} else {
			return true
		}
	}

	loadingCurrentTxAdress = false
	currentTxAddressList = null;

	loadTxAddresses(txId) {
		this.loadingCurrentTxAdress = true
		this.global.httpGET('get_portfolio_transactions_addresses', {
			portfolioId: this.global.currentPortfolioId,
			transactionId: txId,
		}).subscribe(response => {
			this.currentTxAddressList = response.data
			this.loadingCurrentTxAdress = false
		}, error => {
			this.loadingCurrentTxAdress = false
		});
	}



	coinTableData = 'ALL'
	coinTablePageLoadSize = 10
	coinTableLoading = 0
	coinTableCrytoLoadPage = 0
	coinTable = null
	marketViewLocation = null

	marketCurrentViewSymbol = null
	marketCurrentViewCoin = null
	marketLineChartData: Array<any> = [];
	marketLineChartLabels: Array<any> = [];


	getTopList(direction = null) {
		this.coinTablePageLoadSize = (this.global._authStatus) ? 100 : 10

		if (this.coinTableData == 'ALL') {
			this.coinTableLoading = 1
		} else {
			this.coinTableLoading = 2
		}

		let doFetch = false


		if (direction == 'right') {
			this.coinTableCrytoLoadPage++
			doFetch = true
		} else if (direction == 'left') {
			if (this.coinTableCrytoLoadPage > 0) {
				this.coinTableCrytoLoadPage--
				doFetch = true
			} else {
				doFetch = false
			}
		} else {
			this.coinTableCrytoLoadPage = 0
			doFetch = true
		}

		if (doFetch) {
			this.global.httpAPIGET('https://min-api.cryptocompare.com/data/top/mktcapfull?limit=' + this.coinTablePageLoadSize + '&page=' + this.coinTableCrytoLoadPage + '&tsym=' + this.global._currency['code'] + '&assetClass=' + this.coinTableData)
				.subscribe(response => {
					this.coinTableLoading = 0
					this.coinTable = response.Data
				}, error => {
				});
		}
	}

	public indexedIds = {
		'public_address': {},
		'virtual_wallet': {},
		'exchange': {},
	}

	getSourceInfoV2(transaction, mode = null) {
		let walletInfo = {
			'name': null,
			'fullName': null,
			'image': null,
		}

		if (transaction.wallet_name != null) {
			walletInfo = {
				'name': this.global.shortenNameDisplay(transaction.wallet_name),
				'fullName': transaction.wallet_name,
				'image': transaction.img,
			}
		}

		return walletInfo
	}

	getSourceInfo(transaction, mode = null) {

		if (transaction.sync_source == 'public address') {

			if (this.indexedIds.public_address.hasOwnProperty(transaction.exchange_connection_id)) {
				return this.indexedIds.public_address[transaction.exchange_connection_id]
			} else {
				let walletInfo = {
					'name': null,
					'fullName': null,
					'image': null,

				}

				this.global._wallets.forEach(element => {
					if (element.id == transaction.exchange_connection_id) {
						walletInfo.fullName = element.name
						walletInfo.image = element.image
						walletInfo.name = this.global.shortenNameDisplay(walletInfo.fullName);
						this.indexedIds.public_address[transaction.exchange_connection_id] = walletInfo

						if (mode == null) {
							return this.indexedIds.public_address[transaction.exchange_connection_id]
						}
					}
				});
			}

		} else if (transaction.sync_source == 'virtual_wallet') {

			if (this.indexedIds.virtual_wallet.hasOwnProperty(transaction.exchange_connection_id)) {
				return this.indexedIds.virtual_wallet[transaction.exchange_connection_id]
			} else {
				let walletInfo = {
					'name': null,
					'fullName': null,
					'image': null
				}

				this.global._virtualWallets.forEach(element => {
					if (element.id == transaction.exchange_connection_id) {
						walletInfo.fullName = element.name
						walletInfo.image = element.image
						walletInfo.name = this.global.shortenNameDisplay(walletInfo.fullName);
						this.indexedIds.virtual_wallet[transaction.exchange_connection_id] = walletInfo

						if (mode == null) {
							return this.indexedIds.virtual_wallet[transaction.exchange_connection_id]
						}

					}
				});
			}

		} else if (transaction.sync_source == 'exchange') {

			if (this.indexedIds.exchange.hasOwnProperty(transaction.exchange_connection_id)) {
				return this.indexedIds.exchange[transaction.exchange_connection_id]
			} else {
				let walletInfo = {
					'name': null,
					'fullName': null,
					'image': null
				}
				this.global._exchangesConnected.forEach(element => {
					if (element.exchange_connection_id == transaction.exchange_connection_id || element.exchange_connection_source_id == transaction.exchange_connection_id) {
						walletInfo.fullName = element.name
						walletInfo.image = element.image
						walletInfo.name = this.global.shortenNameDisplay(walletInfo.fullName);
						this.indexedIds.exchange[transaction.exchange_connection_id] = walletInfo

						if (mode == null) {
							return this.indexedIds.exchange[transaction.exchange_connection_id]
						}

					}
				});
			}

		}

	}

	resetNameIndex() {
		this.indexedIds = {
			'public_address': {},
			'virtual_wallet': {},
			'exchange': {},
		}
	}


	public syncingAllStatus = false
	syncAll(mode = 'wallets') {
		this.syncingAllStatus = true
		this.global.httpGET('sync_all', { portfolioId: this.global.currentPortfolioId, mode: mode }).subscribe(response => {
			this.syncingAllStatus = false
			if (mode == 'exchanges' || mode == 'all') {
				this.getSyncedExchanges()
			}

			if (mode == 'wallets' || mode == 'all') {
				this.getWalletsForce()
			}
		}, error => {
			this.syncingAllStatus = false
		});
	}

	pulledOnce = false

	findConnectionsTotalValue(mode = null) {
		if (mode == 2 || mode == null) {
			if (this.global._exchangesConnected != null) {
				this.global._exchangesConnected.forEach(element => {
					element.value = 0
					element.valueCurrencySymbol
					element.valueErrors = false

					if (this.global._virtualWallets != null) {
						this.global._virtualWallets.forEach(vwallet => {
							if (element.exchange_connection_id == vwallet.exchange_connection_id) {
								if (vwallet.amountInCurrency != null) {
									element.value = element.value + vwallet.amountInCurrency
								} else {
									if (this.pulledOnce) {
										element.valueErrors = true
									}
								}
							}
						});
					}
				});
			}
		}

		if (mode == 1 || mode == null) {
			if (this.global._wallets != null) {
				this.global._wallets.forEach(element => {
					element.value = 0
					element.valueCurrencySymbol
					element.valueErrors = false

					if (element.parent_wallet_id == null) {
						if (element.amountInCurrency != null) {
							element.value = element.value + element.amountInCurrency
						} else {
							if (this.pulledOnce) {
								element.valueErrors = true
							}
						}
					}

					this.global._wallets.forEach(wallet => {
						if ((element.id == wallet.parent_wallet_id)) {
							if (wallet.amountInCurrency != null) {
								element.value = element.value + wallet.amountInCurrency
							} else {
								if (this.pulledOnce) {
									element.valueErrors = true
								}
							}
						}
					});
				});
			}

			if (this.global._virtualWallets != null) {

				this.global._virtualWallets.forEach(element => {
					element.value = 0
					element.valueCurrencySymbol
					element.valueErrors = false
					if (element.amountInCurrency != null) {
						element.value = element.value + element.amountInCurrency
					} else {
						if (this.pulledOnce) {
							element.valueErrors = true
						}
					}
				});

			}
		}

	}

	public cryptocompareLoopSlize = 150

	// FMV related
	async getFMVData(fsym = this.global._assetUniqueString, hard = false) {

		let count = 0
		let arrayTickers = this.global._assetUniqueString.split(",");
		let CCAGArray = []

		if (this.global._portfolioInfo != null) { // fix for going and coming back to a portfolio - race condition

			if (!this.global._portfolioInfo['assetPegs']) {
				await this.global.getPortfolioAssetPegs()
			}

			if (this.userCoinAssetPriceCCAG == null) {
				this.userCoinAssetPriceCCAG = { 'RAW': {} }
			}

			if (this.global._portfolioInfo.fmv_mode == 1 || this.global._portfolioInfo.fmv_mode == 99 || this.global._portfolioInfo.fmv_mode == 100) {

				do {

					let callString = null;
					let loopCount = 0

					for (let index = count; index < arrayTickers.length; index++) {
						if (loopCount <= this.cryptocompareLoopSlize - 1) {
							if (callString === null) {
								callString = arrayTickers[index];
							} else {
								callString = callString + ',' + arrayTickers[index];
							}
							loopCount++
						} else {
							break
						}
					}

					await this.global.httpAPIGET('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=' + callString + '&tsyms=' + this.global._portfolio_currency['code'] + '&api_key=' + this.global.getCCAPIKey())
						.toPromise().then(response => {
							this.global.globalPriceFetchError = false
							if (response.RAW !== undefined) {
								CCAGArray.push(response)
							} else {
								this.debugAllowd && console.log('no response CCAGG')
							}

						}).catch(error => {
							this.global.globalPriceFetchError = true
						})


					count = count + this.cryptocompareLoopSlize

				} while (await count < arrayTickers.length);

				CCAGArray.forEach(element => {

					if (this.userCoinAssetPriceCCAG == null) {

						this.userCoinAssetPriceCCAG = element

					} else {

						for (var prop in element['RAW']) {

							if (Object.prototype.hasOwnProperty.call(element['RAW'], prop)) {
								this.userCoinAssetPriceCCAG['RAW'][prop] = element['RAW'][prop]
							}
						}
					}
				});
			}

			if (this.global._portfolioInfo.fmv_mode == 4 || this.global._portfolioInfo.fmv_mode == 3 || this.global._portfolioInfo.fmv_mode == 2 || this.global._portfolioInfo.fmv_mode == 99 || this.global._portfolioInfo.fmv_mode == 100) {

				if (this.global._coingeckoUniqueString != null) {
					//await this.getCoingeckoPriceResponse(hard)
					await this.global.httpGET('get_cg_data', {
						portfolioId: this.global.currentPortfolioId,
						cgString: this.global._coingeckoUniqueString,
						vsCurrency: this.global._portfolio_currency['code']
					}).toPromise().then(response => {
						if (response.status == true && response.data != null) {
							this.global.globalPriceFetchError = false
							this.userCoinAssetPriceCG = response.data

							if (this.userCoinAssetPriceCG != null) {


								for (var cg_key in this.userCoinAssetPriceCG) {
									let key = null
									for (var asset_key in this.global._assetUniqueDetailedString) {
										if (this.global._assetUniqueDetailedString.hasOwnProperty(asset_key)) {
											if (this.global._assetUniqueDetailedString[asset_key].cg_id == cg_key) {
												key = asset_key;
											}
										}
									}

									if (key != null) {
										if (this.userCoinAssetPriceCCAG == null) {
											this.userCoinAssetPriceCCAG = { 'RAW': {} }
										}
										if (!this.userCoinAssetPriceCCAG['RAW'].hasOwnProperty(key)) {

											this.userCoinAssetPriceCCAG['RAW'][key] = {}
											this.userCoinAssetPriceCCAG['RAW'][key][this.global._portfolio_currency['code']] = {}
											this.userCoinAssetPriceCCAG['RAW'][key][this.global._portfolio_currency['code']]['PRICE'] = this.userCoinAssetPriceCG[cg_key][this.global._portfolio_currency['code'].toLowerCase()]
										}
									}
								}
							}
						} else {
							this.global.globalPriceFetchError = true
						}
					}, error => {
						this.global.globalPriceFetchError = true
					});
				} else {
					this.global.globalPriceFetchError = false
					this.userCoinAssetPriceCG = null
				}
			}

			if (this.global._portfolioInfo.fmv_mode == 5) {

				await this.getCoinMarketCapResponse()
			}

			//Assign Asset Pegs Here:

			if (this.userCoinAssetPriceCCAG && this.global._portfolioInfo['assetPegs']) {


				// if the asset is not found on the userCoinAssetPriceCCAG['RAW], it means that it wasnt found on CC or CG
				// The next attemp is to do it via pegging. So add the missing element to the array.
				// and yes, its intentionally wrapped around an empty {}
				{

					for (var asset_key in this.global._assetUniqueDetailedString) {
						if (!this.userCoinAssetPriceCCAG['RAW'].hasOwnProperty(asset_key)) {
							this.userCoinAssetPriceCCAG['RAW'][asset_key] = {}
							this.userCoinAssetPriceCCAG['RAW'][asset_key][this.global._portfolio_currency['code']] = {}
							this.userCoinAssetPriceCCAG['RAW'][asset_key][this.global._portfolio_currency['code']]['PRICE'] = -1;
							this.userCoinAssetPriceCCAG['RAW'][asset_key][this.global._portfolio_currency['code']]['aLostSoul'] = true;
						}
					}

				}

				console.log('-----------------------')
				console.log(this.global._portfolioInfo['assetPegs'])
				this.global._portfolioInfo['assetPegs'].forEach(pegObj => {

					for (var key in this.userCoinAssetPriceCCAG['RAW']) {
						if (this.userCoinAssetPriceCCAG['RAW'].hasOwnProperty(key)) {
							if (key == pegObj['target_symbol']) {
								try {

									if (pegObj['peg_symbol'].toUpperCase() == this.global._portfolio_currency['code'].toUpperCase()) {
										this.userCoinAssetPriceCCAG['RAW'][key][this.global._portfolio_currency['code']]['PRICE'] = 1
										this.userCoinAssetPriceCCAG['RAW'][key][this.global._portfolio_currency['code']]['PEGGED'] = pegObj['peg_symbol']

									} else {
										try {

											this.userCoinAssetPriceCCAG['RAW'][key][this.global._portfolio_currency['code']]['PRICE'] = this.userCoinAssetPriceCCAG['RAW'][pegObj['peg_symbol']][this.global._portfolio_currency['code']]['PRICE']
											this.userCoinAssetPriceCCAG['RAW'][key][this.global._portfolio_currency['code']]['PEGGED'] = pegObj['peg_symbol']

										} catch (error) {

											this.userCoinAssetPriceCCAG['RAW'][asset_key] = {}
											this.userCoinAssetPriceCCAG['RAW'][asset_key][this.global._portfolio_currency['code']] = {}
											this.userCoinAssetPriceCCAG['RAW'][asset_key][this.global._portfolio_currency['code']]['PRICE'] = -1;
											this.userCoinAssetPriceCCAG['RAW'][asset_key][this.global._portfolio_currency['code']]['aLostSoul'] = true;
										}

									}
								} catch (error) {
									this.userCoinAssetPriceCCAG['RAW'][asset_key] = {}
									this.userCoinAssetPriceCCAG['RAW'][asset_key][this.global._portfolio_currency['code']] = {}
									this.userCoinAssetPriceCCAG['RAW'][asset_key][this.global._portfolio_currency['code']]['PRICE'] = -1;
									this.userCoinAssetPriceCCAG['RAW'][asset_key][this.global._portfolio_currency['code']]['aLostSoul'] = true;

								}
							}
						}
					}
				});

				// after pegging if the asset is a 'aLostSoul', then remove from the object
				for (var element in this.userCoinAssetPriceCCAG['RAW']) {
					if (
						this.userCoinAssetPriceCCAG['RAW'][element][this.global._portfolio_currency['code']]['aLostSoul'] &&
						this.userCoinAssetPriceCCAG['RAW'][element][this.global._portfolio_currency['code']]['PRICE'] == -1
					) {
						delete this.userCoinAssetPriceCCAG['RAW'][element]
					}

				};
			}

			// Always add the base currency amount to 1
			this.userCoinAssetPriceCCAG['RAW'][this.global._portfolio_currency['code']] = {}
			this.userCoinAssetPriceCCAG['RAW'][this.global._portfolio_currency['code']][this.global._portfolio_currency['code']] = {}
			this.userCoinAssetPriceCCAG['RAW'][this.global._portfolio_currency['code']][this.global._portfolio_currency['code']]['PRICE'] = 1;

			console.log(this.userCoinAssetPriceCCAG['RAW'])

			if (!this.global.globalPriceFetchError) {
				this.executeWalletLoop(hard)
				this.globalLiveWalletSync = false
			}
		} //


	}

	public doLiveWalletAmountCache = false
	public userCoinAssetPriceCG = []
	// async getCCACGPriceResponse(fsym=null, hard=false){
	// 	if(fsym==null){
	// 		//this.executeWalletLoop(hard)
	// 	} else {
	// 		await this.global.httpAPIGET('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+ fsym +'&tsyms='+ this.global._portfolio_currency['code'] + '&api_key=' + this.global.getCCAPIKey() )
	// 			.toPromise().then(response => {

	// 				this.global.globalPriceFetchError = false
	// 				return response
	// 				if(this.userCoinAssetPriceCCAG == null){
	// 					this.userCoinAssetPriceCCAG = response
	// 				} else {
	// 				 	response['RAW'].forEach(element => {
	// 						this.userCoinAssetPriceCCAG['RAW'].push(element)
	// 					});
	// 				}
	// 			}).catch( error => {
	// 				this.global.globalPriceFetchError = true
	// 			})
	// 	}

	// }


	async getCoinMarketCapResponse() {

		if (this.global._assetUniqueString != null) {

			//await this.global.httpCoingeckoAPIGET('https://pro-api.coingecko.com/api/v3/simple/price?ids='+ this.global._coingeckoUniqueString +'&vs_currencies='+ this.global._portfolio_currency['code'])
			await this.global.httpGET('get_cmc_data?symbols=' + this.global._assetUniqueString + '&currency=' + this.global._portfolio_currency['code'] + '&portfolioId=' + this.global.currentPortfolioId)
				.toPromise().then(response => {
					this.global.globalPriceFetchError = false

					this.userCoinAssetPriceCG = response.data

				}).catch(error => {
					this.global.globalPriceFetchError = true
				})
		} else {

			this.global.globalPriceFetchError = false
			this.userCoinAssetPriceCG = null

		}

		if (this.userCoinAssetPriceCG != null) {


			for (var cg_key in this.userCoinAssetPriceCG) {
				let key = null
				for (var asset_key in this.global._assetUniqueDetailedString) {
					if (this.global._assetUniqueDetailedString.hasOwnProperty(asset_key)) {
						if (this.global._assetUniqueDetailedString[asset_key].cg_id == cg_key) {
							key = asset_key;
						}
					}
				}

				if (key != null) {
					if (this.userCoinAssetPriceCCAG == null) {
						this.userCoinAssetPriceCCAG = { 'RAW': {} }
					}
					if (!this.userCoinAssetPriceCCAG['RAW'].hasOwnProperty(key)) {

						this.userCoinAssetPriceCCAG['RAW'][key] = {}
						this.userCoinAssetPriceCCAG['RAW'][key][this.global._portfolio_currency['code']] = {}
						this.userCoinAssetPriceCCAG['RAW'][key][this.global._portfolio_currency['code']]['PRICE'] = this.userCoinAssetPriceCG[cg_key][this.global._portfolio_currency['code'].toLowerCase()]
					}
				}
			}
		}
	}

	coingeckoDataCache = []

	getPriceInUserCurrency(element, requestEmmiter = false, code = null) {
		let responseRAW = [];
		// if you are fetching a single currency and you need the wallet prices fetched completely (for graphs),
		// then the graph will wait for the emmiter before plotting
		if (requestEmmiter) {
			this.coinCurrencyPriceFetch.emit(true)
		}
		// amountInCurrency
		if (code == null) {
			code = this.global._portfolio_currency['code']
		}

		if (this.userCoinAssetPriceCCAG != null) {
			responseRAW = this.userCoinAssetPriceCCAG['RAW']
		}

		if (element.type_id <= 0) {
			this.setGlobalAssetPriceError(element)
			return
		}

		if (responseRAW != null && responseRAW.hasOwnProperty(element.symbol)) {

			this.setGlobalAssetPriceObject(element, responseRAW, code, 'Cryptocompare')
		} else {

			if (this.global._portfolioInfo.fmv_mode == 100 || this.global._portfolioInfo.fmv_mode == 99 || this.global._portfolioInfo.fmv_mode == 2 || this.global._portfolioInfo.fmv_mode == 3 || this.global._portfolioInfo.fmv_mode == 4 || this.global._portfolioInfo.fmv_mode == 5) {


				// try coingecko API
				if (element.cg_id != null) {
					if (
						this.userCoinAssetPriceCG.hasOwnProperty(element.cg_id) &&
						this.userCoinAssetPriceCG[element.cg_id].hasOwnProperty(this.global._portfolio_currency['code'].toLowerCase())
					) {
						this.setCoinGeckoRespObject(responseRAW, element, code, this.userCoinAssetPriceCG[element.cg_id][this.global._portfolio_currency['code'].toLowerCase()])

					} else {

						this.setGlobalAssetPriceError(element)
					}
				} else {
					this.setGlobalAssetPriceError(element)
				}

			} else {
				this.setGlobalAssetPriceError(element)
			}


		}
	}

	setCoinGeckoRespObject(responseRAW, element, code, price) {
		responseRAW[element.symbol] = {}

		responseRAW[element.symbol][code] = {
			'PRICE': price
		}

		if (this.noMarketValueSymbols.hasOwnProperty(element['symbol'])) {
			delete this.noMarketValueSymbols[element['symbol']]
		}

		this.setGlobalAssetPriceObject(element, responseRAW, code, 'Coingecko')

	}


	getPortfoliosAllowedLimit() {

		this.global.httpGET('portfolio_count_global')
			.subscribe(response => {
				this.global.portfoliosGlobalCount = response.data
			})

	}

	getAppliedTxSearchQuery() {
		if (this.userDeepSearched == true) {
			return this.txTableDeepSearch
		} else {
			return false
		}
	}

	loadExchangeConnection(exchange) {
		this.global.exchangePageMode = 1
		this.global.currentExchangeViewId = exchange.exchange_connection_id
		this.global.portfolioSubViewState('exchanges', exchange.exchange_connection_id)
	}

	backUpFilters = null
	public userDeepSearchTotalResultCount = 0
	resetQuickSearch(soft = true) {
		this.txTableDeepSearch.query = null
		this.txTableDeepSearch.mode = 0
		this.quickSearchMode = false
		this.userDeepSearched = false
		if (this.backUpFilters !== null) {
			this.global.userPortfolioSettings.txtable_filters = this.backUpFilters
		}
		this.userDeepSearchTotalResultCount = 0
		this.quickSearchTransactionBackup = null
		if (!soft)
			this.getPortfolioTransactions(false, this.tableOffset)
	}


	forceAddressBook() {
		this.gotoLink('address-book')
	}


	invalidPortfolioId = false

	doPortfolioDeepInit(portfolioId, viewMode) {

		if (portfolioId == this.global.currentPortfolioId) {
			this.portfolioSettingsEmit.emit(this.global.userPortfolioSettings)
			setTimeout(() => {
				this.global.currentPortfolioId = portfolioId
			}, 500);

			return
		}

		this.global.currentPortfolioId = portfolioId
		this.doPortfolioHearBeat()
		if (this.global._portfolioInfo == null) {
			// used to load the portfolo permissions when the permissions changed and the user refreshed the page
			this.global.portfolioLoadingPreCheck = true
			this.global.httpGET('get_portfolio_deep_permissions', { portfolioId: portfolioId }).subscribe(response => {
				if (response.status) {
					this.global.portfolioLoadingPreCheck = false
					this.setPortfolioPermission(response.data)
					this.global.httpGET('get_portfolio', { portfolioId: portfolioId }).subscribe(responsePortfolio => {
						if (responsePortfolio.status == true) {
							if (responsePortfolio['data']['disabled'] == 1) { this.invalidPortfolioId = true; return; }
							this.global._portfolioInfo = responsePortfolio['data']
							this.global.lastPortfolio = responsePortfolio['data']
							this.doPortfolioSetter(this.global._portfolioInfo)
							this.global._portfolio_currency = this.global._portfolioInfo['portfolio_currency']
							this.global._portfolio_currency_secondary = this.global._portfolioInfo['portfolio_currency_secondary']
							this.portfolioPreloading(portfolioId, viewMode)
							this.global.portfolioInit()
							this.portfolioInit()
						} else {
							this.invalidPortfolioId = true
						}
					}, error => {
					});
				}
			}, error => {
			});

			// get portfolio details

		} else {

			this.global.portfolioLoadingPreCheck = true
			this.global.httpGET('get_portfolio_deep_permissions', { portfolioId: portfolioId }).subscribe(response => {
				if (response.status) {

					this.setPortfolioPermission(response.data)
					this.global.portfolioLoadingPreCheck = false
					this.portfolioPreloading(portfolioId, viewMode)
					this.global.portfolioInit()
					this.portfolioInit()
				} else {
					this.invalidPortfolioId = true
				}
			})
		}
	}


	portfolioPreloading(portfolioId, viewMode) {
		this.global.currentPortfolioId = portfolioId
		if (viewMode == 'overview') {
			this.global.currentPortfolioViewState = 1
			this.global.currentPortfolioViewMode = 'overview'
			this.global.titleService.setTitle(this.global.appName + " - Overview")
			this.global.getDashboardSummary()
			this.global.getDashboardARAPSummary()
			this.getPortfolioTags();
			this.global.getPortfolioIssues(true)
		} else if (viewMode == 'connections') {
			this.global.titleService.setTitle(this.global.appName + " - Connections")
			this.global.currentPortfolioViewMode = 'connections'
			this.global.currentPortfolioViewState = 2
		} else if (viewMode == 'exchanges') {
			this.global.titleService.setTitle(this.global.appName + " - Accounts")
			this.global.currentPortfolioViewMode = 'exchanges'
			this.global.currentPortfolioViewState = 3
		} else if (viewMode == 'transactions') {
			this.global.currentPortfolioViewState = 4
			this.global.currentPortfolioViewMode = 'transactions'
			this.global.titleService.setTitle(this.global.appName + " - Transactions")
		} else if (viewMode == 'analytics') {
			this.global.currentPortfolioViewState = 5
			this.global.currentPortfolioViewMode = 'analytics'
			this.global.titleService.setTitle(this.global.appName + " - Analytics")
		} else if (viewMode == 'history') {
			this.global.currentPortfolioViewState = 7
			this.global.currentPortfolioViewMode = 'history'
			this.global.titleService.setTitle(this.global.appName + " - History")
		} else if (viewMode == 'classifications') {
			this.global.currentPortfolioViewMode = 'classifications'
			this.global.titleService.setTitle(this.global.appName + " - Classifications")
		} else if (viewMode == 'taxlots') {
			this.global.currentPortfolioViewState = 9
			this.global.currentPortfolioViewMode = 'taxlots'
			this.global.titleService.setTitle(this.global.appName + " - Tax lots")
		} else if (viewMode == 'reports') {
			this.global.currentPortfolioViewMode = 'reports'
			this.global.titleService.setTitle(this.global.appName + " - Reports Dashboard")
		} else if (viewMode == 'activity') {
			this.global.currentPortfolioViewMode = 'activity'
			this.global.titleService.setTitle(this.global.appName + " - Activity")
		} else if (viewMode == 'users') {
			this.global.currentPortfolioViewMode = 'users'
			this.global.titleService.setTitle(this.global.appName + " - Users")
		} else if (viewMode == 'portfolio') {
			this.global.currentPortfolioViewMode = 'portfolio'
			this.global.titleService.setTitle(this.global.appName + " - Details")
		} else if (viewMode == 'settings') {
			this.global.currentPortfolioViewMode = 'settings'
			this.global.titleService.setTitle(this.global.appName + " - Settings")
		} else if (viewMode == 'issues') {
			this.global.currentPortfolioViewMode = 'issues'
			this.global.titleService.setTitle(this.global.appName + " - Issues")
		} else if (viewMode == 'defi') {
			this.global.currentPortfolioViewMode = 'defi'
			this.global.titleService.setTitle(this.global.appName + " - DeFi")
		} else if (viewMode == 'gateways') {
			this.global.currentPortfolioViewState = 17
			this.global.currentPortfolioViewMode = 'gateways'
			this.global.titleService.setTitle(this.global.appName + " - Gateways")
		} else if (viewMode == 'payments') {
			this.global.currentPortfolioViewState = 18
			this.global.currentPortfolioViewMode = 'payments'
			this.global.titleService.setTitle(this.global.appName + " - Payments")
		} else if (viewMode == 'invoices') {
			this.global.currentPortfolioViewState = 19
			this.global.currentPortfolioViewMode = 'invoices'
			this.global.titleService.setTitle(this.global.appName + " - Invoices")
		} else if (viewMode == 'bills') {
			this.global.currentPortfolioViewState = 20
			this.global.currentPortfolioViewMode = 'bills'
			this.global.titleService.setTitle(this.global.appName + " - Bills")
		} else if (viewMode == 'coin_info') {
			this.global.currentPortfolioViewState = 21
			this.global.currentPortfolioViewMode = 'coin_info'
			this.global.titleService.setTitle(this.global.appName + " - Coin Info")
		} else if (viewMode == 'wallets') {
			this.global.currentPortfolioViewState = 22
			this.global.currentPortfolioViewMode = 'wallets'
			this.global.titleService.setTitle(this.global.appName + " - Wallet View")
		} else if (viewMode == 'nfts') {
			this.global.currentPortfolioViewState = 24
			this.global.currentPortfolioViewMode = 'nfts'
			this.global.titleService.setTitle(this.global.appName + " - NFTs")
		} else if (viewMode == 'nft') {
			this.global.currentPortfolioViewState = 25
			this.global.currentPortfolioViewMode = 'nft'
			this.global.titleService.setTitle(this.global.appName + " - NFTs")
		} else if (viewMode == 'nft_review') {
			this.global.currentPortfolioViewState = 26
			this.global.currentPortfolioViewMode = 'nft_review'
			this.global.titleService.setTitle(this.global.appName + " - NFT Review")
		} else if (viewMode == 'files') {
			this.global.currentPortfolioViewMode = 'files'
			this.global.titleService.setTitle(this.global.appName + " - Files")
		} else if (viewMode == 'automations') {
			this.global.currentPortfolioViewMode = 'automations'
			this.global.titleService.setTitle(this.global.appName + " - Automations")
		} else if (viewMode == 'vendors') {
			this.global.currentPortfolioViewMode = 'vendors'
			this.global.titleService.setTitle(this.global.appName + " - Vendors")
		} else if (viewMode == 'safts') {
			this.global.currentPortfolioViewMode = 'safts'
			this.global.titleService.setTitle(this.global.appName + " - SAFTs")
		}

		this.targetWalletArraySize = 0
		this.globalnetWorthChangePercentage = 0
		this.coinWalletAggregatedDataGrabSubscription = this.coinWalletAggregatedDataGrab.subscribe(isValid => {
			if (this.doLiveWalletAmountCache) {
				this.cacheWalletAmounts()
				this.doLiveWalletAmountCache = false
			}

			this.updatePortfolioSyncValue()
			this.subTokensInWallet = null
			this.calculate24HighLowAssets()
			//this.calculateNetWorthPercentageChange('hour', true)

		});


		if (this.highestPerformance == null) {
			this.calculate24HighLowAssets()
			//this.calculateNetWorthPercentageChange('hour', true)
		}
		this.getWalletsForce()

		if (this.global.supportedCoins.length == 0)
			this.global.getSupportedCoinList()

		//this.global.getDashboardSummary()
		this.global.getSideBarSummary()
	}

	public navList = [];
	public horizontalNavList = [];
	public navListCache = [];
	public onSetupNavbar = true

	runNavbar(portfolioId) {
		if (portfolioId == this.global.currentPortfolioId) {
			return false;
		} else {
			return true
		}
	}


	txFilterAssetSelectAllSelected = false
	txFilterClassesSelectAllSelected = true
	txFilterTagsSelectAllSelected = true
	findDuplicateTxStatus = 0

	clearTxFilter(force = true) {

		this.resetLogs()

		this.txFilterApplied = false
		this.backUpFilters = null
		this.global.userPortfolioSettings.txtable_filters = { ... this.global.defaultTxFilter }

		this.txFilterAssetSelectAllSelected = true
		this.txFilterClassesSelectAllSelected = true
		this.txFilterTagsSelectAllSelected = true


		if (this.global._exchangesConnected == null) {
			this.global.httpPOST('get_exchange_list', { portfolioId: this.global.currentPortfolioId }).subscribe(response => {
				if (response.status == true) {
					this.global._exchangesConnected = response.data
					this.global._exchangesConnected.forEach(element => {
						if (this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(element.exchange_connection_id) == -1)
							this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].push(element.exchange_connection_id)
					});

				}
			}, error => {
			});
		} else {
			this.global._exchangesConnected.forEach(element => {
				if (this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(element.exchange_connection_id) == -1)
					this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].push(element.exchange_connection_id)
			});
		}

		this.global._wallets.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(element.id) == -1)
				if (element.parent_wallet_id == null)
					this.global.userPortfolioSettings.txtable_filters['walletsConnected'].push(element.id)
		});

		this.global.userPortfolioSettings.txtable_filters.allWalletsConnect = true

		//address groups (update: default should be unselected)

		// this.global.addressGroups.forEach(element => {
		//   if(this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'].indexOf(element.id)== -1){

		//     this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'].push(element.id)
		//   }
		// });

		this.global.userPortfolioSettings.txtable_filters.allAddressGroupsConnect = false;
		this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'] = []

		//connection groups (update: default should be unselected)

		// this.global.connectionGroups.forEach(element => {
		//   if(this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'].indexOf(element.id)== -1){

		//     this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'].push(element.id)
		//   }
		// });

		this.global.userPortfolioSettings.txtable_filters.allConnectionGroupsConnect = false;
		this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'] = []



		this.filteredVirtualWallets.forEach(element => {
			if (element.exchange_connection_id == 0) {
				if (this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].indexOf(element.id) == -1)
					this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].push(element.id)
			}
		});

		this.filterAssets()
		// this.global._allApplicableAssets.forEach(element => {
		//   this.global.userPortfolioSettings.txtable_filters['assetSymbols'].push(element.symbol)
		// })



		this.filterTags()
		this.filterWeb3Products()

		this.emitTxFilterChange(true)

		if (force) {
			this.getPortfolioTransactions()
		} else {
			if (this.global._transactions == null) {
				this.getPortfolioTransactions()
			}
		}

		if (this.findDuplicateTxStatus == 2) { //Refresh duplicates on filter reset via duplicate modal.
			this.findDuplicateTxOperation(1, null)
		}

		this.selectedSavedFilter = null;
		this.resetQuickSearch();

		this.filteredAssets.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['assetSymbols'].indexOf(element.symbol) == -1) {
				this.global.userPortfolioSettings.txtable_filters['assetSymbols'].push(element.symbol)
			}
		});
	}


	emitTxFilterChange(reset = false) {

		if (reset) {

			this.global.userPortfolioSettings.txtable_filters = JSON.parse(JSON.stringify(this.global.defaultTxFilter))
		}
		this.global.userPortfolioSettingsStateChange.emit(true)
	}

	public selectedSavedFilter = null
	filteredAssets = []

	filterAssets() {

		this.filteredAssets = []

		if (this.global.userPortfolioSettings.txtable_filters !== undefined && this.global.userPortfolioSettings.txtable_filters !== null) {

			if (this.global.userPortfolioSettings.txtable_filters.assetsAll) {
				this.txFilterAssetSelectAllSelected = true
			}

			this.global._allApplicableAssets.forEach(element => {

				if (this.global.userPortfolioSettings.txtable_filters['assetSymbols'].indexOf(element.symbol) == -1) {
					if (element.symbol != null) {
						// this.global.userPortfolioSettings.txtable_filters['assetSymbols'].push(element.symbol)
						this.global._allApplicableAssetsUnique.push(element.symbol)
						// this.txFilterApplied = true

					}
				} else {


				}
				//if(this.global.userPortfolioSettings.txtable_filters['assetSymbols'].indexOf(element.symbol)== -1){
				let tokenObject = {
					symbol: element.symbol,
					name: element.type,
					image: element.image
				}

				this.filteredAssets.push(tokenObject)
				//}

			});



		}



	}

	filteredTags = []
	filterTags() {

		this.filteredTags = []

		if (this.global.userPortfolioSettings.txtable_filters !== undefined && this.global.userPortfolioSettings.txtable_filters !== null) {

			if (this.global.userPortfolioSettings.txtable_filters['tagsAll']) {
				this.txFilterTagsSelectAllSelected = true
			} else {
				this.txFilterTagsSelectAllSelected = false
			}

			if (this.global._transactionTags != null) {
				this.global._transactionTags.forEach(element => {

					if (this.global.userPortfolioSettings.txtable_filters.tagsAll && this.global.userPortfolioSettings.txtable_filters['txTags'].indexOf(element.tag_type) == -1) {
						//if(element.tag_type!=null){
						this.global.userPortfolioSettings.txtable_filters['txTags'].push(element.tag_type)

						//}
					}

					if (this.filteredTags.indexOf(element.tag_type) == -1) {
						//if(element.tag_type!=null){
						this.filteredTags.push(element.tag_type)
						//}
					}

				});

			}

		}

	}


	filteredWeb3Products = []
	public txFilterAnyWeb3Product = true;
	filterWeb3Products() {

		this.filteredWeb3Products = []

		if (this.global.userPortfolioSettings.txtable_filters !== undefined && this.global.userPortfolioSettings.txtable_filters !== null) {
			if (this.global.userPortfolioSettings.txtable_filters['anyWeb3Product']) {
				this.txFilterAnyWeb3Product = true
			} else {
				this.txFilterAnyWeb3Product = false
			}

			if (this.global.allLabelledAddressInfo != null) {
				this.global.allLabelledAddressInfo.forEach(element => {

					if (this.global.userPortfolioSettings.txtable_filters.anyWeb3Product && this.global.userPortfolioSettings.txtable_filters['web3ProductsByLabel'].indexOf(element.label) == -1) {
						//if(element.label!=null){
						//this.global.userPortfolioSettings.txtable_filters['web3ProductsByLabel'].push(element)

						//}
					}

					if (this.filteredWeb3Products.indexOf(element) == -1) {
						//if(element!=null){
						this.filteredWeb3Products.push(element)
						//}
					}

				});

			} else {

			}

		}

	}

	duplicateTransactions = null;
	duplicateTransactionsCount = 0
	public formState = false;
	public modalRefTwo;
	public modalRef

	findDuplicateTxOperation(mode = 1, page = null) {
		this.formState = true
		this.findDuplicateTxStatus = 1
		this.global.httpPOST('reconciliation/duplicates', {
			portfolioId: this.global.currentPortfolioId,
			mode: mode, // 1=scan, 2=clean
			//filters: 		this.global.userPortfolioSettings.txtable_filters,
			page: page
		}).subscribe(response => {

			this.formState = false;
			this.findDuplicateTxStatus = 0;

			if (response.status == true) {
				this.findDuplicateTxStatus = 2
				this.duplicateTransactions = response.data[0]
				this.duplicateTransactionsCount = response.data[1]
			} else {
			}

			if (mode == 2) {
				if (this.modalRefTwo != null) {
					this.modalRefTwo.close()

					this.clearTxFilter();
					this.emitTxFilterChange()
				}
			}

		}, error => {
			this.formState = false;
			this.findDuplicateTxStatus = 0;
		})

	}

	duplicatePageChange(value: any) {
		this.findDuplicateTxOperation(1, value.page)
	}

	findTypelessTxStatus = 0
	typelessAssets = null
	typelessAssetsCount = 0
	typelessTransactions = null
	typelessTransactionsCount = 0

	findTypelessTxOperation(mode = 1, asset = null) {
		this.formState = true
		this.findTypelessTxStatus = 1
		this.global.httpPOST('reconciliation/typeless_txns', {
			portfolioId: this.global.currentPortfolioId,
			mode: mode, 										// 1=assets, 2=transactions
			asset: JSON.stringify(asset), 					// Only required for mode 2.
		}).subscribe(response => {

			this.formState = false
			this.findTypelessTxStatus = 0

			if (response.status == true) {
				this.findTypelessTxStatus = 2
				if (mode == 1) {
					this.typelessAssets = response.data
					this.typelessAssetsCount = response.data.length
				} else {
					this.typelessTransactions = response.data
					this.typelessTransactionsCount = response.data.length
				}


			} else {
				this.modalRef.close()
			}

			if (mode == 2) {
				if (this.modalRefTwo != null) {
					this.modalRefTwo.close()
				}
			}

		}, error => {
			this.formState = false;
		})

	}

	registeringAssets = false
	registerAsset(asset, all = false) {

		let registerAssets = JSON.stringify([asset])
		if (all) {
			registerAssets = JSON.stringify(this.typelessAssets)
		}

		this.registeringAssets = true
		this.global.httpPOST('register_asset', {
			portfolioId: this.global.currentPortfolioId,
			registerAssets: registerAssets,
			registerAll: all
		}).subscribe(response => {

			if (response.status == true) {
				if (all) {
					this.typelessAssets.forEach(element => {
						element.registered = true;
					});
				} else {
					let arrIndex = this.typelessAssets.indexOf(asset)
					if (arrIndex !== -1) {
						this.typelessAssets[arrIndex].registered = true
						this.typelessAssetsCount -= 1
					}
				}

			}
			this.registeringAssets = false;
		}, error => {
			this.registeringAssets = false;
		})

	}


	findUnregisteredContracts = 0
	unregisteredContracts = null
	unregisteredContractsCount = 0

	findUnregisteredContractsOperation(mode = 1, asset = null) {

		this.findUnregisteredContracts = 1

		this.global.httpGET('reconciliation/unregistered_contracts', {
			portfolioId: this.global.currentPortfolioId
		}).subscribe(response => {

			this.findUnregisteredContracts = 0

			if (response.status == true) {

				this.findUnregisteredContracts = 2

				this.unregisteredContracts = response.data
				this.unregisteredContractsCount = response.data.length

			}

		}, error => {
			this.findUnregisteredContracts = 0
		})

	}

	showHideWorkflowStatus = false
	hideworkFlowModal = null
	showHideWorkFlow(status) {
		this.showHideWorkflowStatus = true
		this.global.httpGET('show_hide_workflow', {
			portfolioId: this.global.currentPortfolioId,
			showHide: status
		}).subscribe(response => {
			this.doPortfolioHearBeat()
			this.hideworkFlowModal.close()
			this.showHideWorkflowStatus = false
		}, error => {
			this.showHideWorkflowStatus = false
		});

	}

	currentViewClassificationRule = null
	applyingClassificationRuleStatus = 2
	applyingClassificationRule = null
	showRuleSuccessMessage = false
	showRuleSuccessMessagePageFull = false

	applyClassification(classificationRule = null) {

		this.showRuleSuccessMessage = false
		if (classificationRule != null) {
			this.currentViewClassificationRule = classificationRule
			this.applyingClassificationRule = classificationRule.id
		}

		this.applyingClassificationRuleStatus = 1
		this.global.httpGET('apply_classification_rule', {
			classificationRuleId: this.currentViewClassificationRule.id
		}).subscribe(response => {
			this.applyingClassificationRuleStatus = 2
			this.showRuleSuccessMessage = true

			if (classificationRule != null) {
				this.showRuleSuccessMessagePageFull = true
				setTimeout(() => {
					this.showRuleSuccessMessagePageFull = false
				}, 2000);
			}

		}, error => {
			this.applyingClassificationRuleStatus = 3
			setTimeout(() => {
				this.applyingClassificationRuleStatus = 2
			}, 5000);
		});

	}

	retryExportState = 0
	retryExport(history) {
		this.retryExportState = 1
		history.retryState = 1
		this.global.httpGET('retry_export', { exportId: history.id }).subscribe(response => {
			this.retryExportState = 0
			history.retryState = 1
			this.global.loadCBExportHistory()
		}, error => {
			this.retryExportState = 0
		});
	}

	public allConnectionsSelected = true
	public quickSearchFilterAssets = null
	public quickSearchFilterAssetsBackup = null
	public assetQuickFilterSearching = false
	public quickSearchFilterTags = null
	public quickSearchFilterTagsBackup = null
	public quickSearchFilterWeb3Products = null
	public quickSearchFilterWeb3ProductsBackup = null
	public quickSearchFilterAccounts = null
	public quickSearchFilterAccountsBackup = null
	public quickSearchFilterWallets = null
	public quickSearchFilterWalletsBackup = null
	public quickSearchFilterVirtualWalletsBackup = null
	public quickSearchFilterClasses = null
	public quickSearchFilterClassesBackup = null
	public viewMode = 1

	public mathFilterKeys = [
		'inAmount',
		'outAmount',
		'feeAmount',
		'feeValue',
		'costBasis',
		'salePrice',
		'unitPrice',
		'fiatValue',
		'runningBalanceValue'
	]

	public mathFilterNames = [
		'In Amount',
		'Out Amount',
		'Fee Amount',
		'Fee Value',
		'Cost Basis Value',
		'Proceeds Value',
		'Unit Price Value',
		'Fiat Value',
		'Running Balance'
	]

	public additionalInAmountOp = false;
	public additionalOutAmountOp = false;
	public additionalFeeAmountOp = false;
	public additionalFeeValueOp = false;
	public additionalSalePriceOp = false;
	public additionalCostBasisOp = false;
	public additionalUnitPriceOp = false;
	public additionalFiatValueOp = false;
	public txFilterWalletSelectAllSelectd = true;
	public additionalRunningBalanceValueOp = false;
	public filteredClasses = []
	public txFilterExchangeSelectAllSelectd = true
	public totalClassesCount = 0
	public selectAllAllConnectionsState = true
	public txFilterGroupSelectAllSelectd = true;
	public txFilterConnectionGroupSelectAllSelectd = true;
	public AssetDef = false
	public AssetCheck = true

	public filterSelectMode = 1;

	public searchMode = false
	public classificationSearchValue = null
	public classificationSearchResults = null

	resetMathAdditionalOps() {
		this.additionalSalePriceOp = false
		this.additionalCostBasisOp = false
		this.additionalFeeValueOp = false
		this.additionalFeeAmountOp = false
		this.additionalOutAmountOp = false
		this.additionalInAmountOp = false
		this.additionalUnitPriceOp = false
		this.additionalFiatValueOp = false
		this.additionalRunningBalanceValueOp = false
	}

	resetQuickSearchWalletsInFilters() {
		this.quickSearchFilterWallets = null

		if (this.quickSearchFilterWalletsBackup != null) {
			this.global._wallets = this.quickSearchFilterWalletsBackup;
		}

		if (this.quickSearchFilterVirtualWalletsBackup != null) {
			this.filteredVirtualWallets = this.quickSearchFilterVirtualWalletsBackup;
		}

		this.quickSearchFilterWalletsBackup = null
		this.quickSearchFilterVirtualWalletsBackup = null
	}


	resetQuickSearchClassification() {
		this.searchMode = false
		this.classificationSearchValue = null
		this.classificationSearchResults = this.global._classifications
	}

	applyQuickSearchWeb3ProductsInFilters() {
		if (this.quickSearchFilterWeb3Products == '') {
			this.resetQuickSearchWeb3ProductsInFilters()
		} else {
			if (this.quickSearchFilterWeb3ProductsBackup == null) {
				this.quickSearchFilterWeb3ProductsBackup = this.filteredWeb3Products;
			} else {
				this.filteredWeb3Products = this.quickSearchFilterWeb3ProductsBackup;
			}
			const searchResults = this.filteredWeb3Products.filter(item =>

				// Object.keys(item).some(k =>
				item != null &&
				item.label.toString().toLowerCase()
					.includes(this.quickSearchFilterWeb3Products.toLowerCase())
				// )
			);
			this.filteredWeb3Products = searchResults
		}
	}

	resetQuickSearchWeb3ProductsInFilters() {
		this.quickSearchFilterWeb3Products = null

		if (this.quickSearchFilterWeb3ProductsBackup != null) {
			this.filteredWeb3Products = this.quickSearchFilterWeb3ProductsBackup;
		}

		this.quickSearchFilterWeb3ProductsBackup = null
	}

	applyQuickSearchAccountsInFilters() {
		if (this.quickSearchFilterAccounts == '') {
			this.resetQuickSearchAccountsInFilters()
		} else {
			if (this.quickSearchFilterAccountsBackup == null) {
				this.quickSearchFilterAccountsBackup = this.global._exchangesConnected;
			} else {
				this.global._exchangesConnected = this.quickSearchFilterAccountsBackup;
			}
			const searchResults = this.global._exchangesConnected.filter(item =>

				Object.keys(item).some(k =>
					(
						k == 'name'
					) &&
					item[k] != null &&
					item[k].toString().toLowerCase()
						.includes(this.quickSearchFilterAccounts.toLowerCase())
				)
			);
			this.global._exchangesConnected = searchResults
		}
	}


	applyQuickSearchWalletsInFilters() {
		if (this.quickSearchFilterWallets == '') {
			this.resetQuickSearchWalletsInFilters()
		} else {

			if (this.quickSearchFilterWalletsBackup == null) {
				this.quickSearchFilterWalletsBackup = this.global._wallets;
			} else {
				this.global._wallets = this.quickSearchFilterWalletsBackup;
			}

			if (this.quickSearchFilterVirtualWalletsBackup == null) {
				this.quickSearchFilterVirtualWalletsBackup = this.filteredVirtualWallets;
			} else {
				this.filteredVirtualWallets = this.quickSearchFilterVirtualWalletsBackup;
			}

			const searchResults = this.global._wallets.filter(item =>

				Object.keys(item).some(k =>
					(
						k == 'name' ||
						k == 'address'
					) &&
					item[k] != null &&
					item[k].toString().toLowerCase()
						.includes(this.quickSearchFilterWallets.toLowerCase())
				)
			);

			const searchResultsVirtual = this.filteredVirtualWallets.filter(item =>

				Object.keys(item).some(k =>
					(
						k == 'name'
					) &&
					item[k] != null &&
					item[k].toString().toLowerCase()
						.includes(this.quickSearchFilterWallets.toLowerCase())
				)
			);

			this.global._wallets = searchResults
			this.filteredVirtualWallets = searchResultsVirtual

		}

	}


	applyQuickSearchClassesInFilters() {
		if (this.quickSearchFilterClasses == '') {
			this.resetQuickSearchClassesInFilters()
		} else {
			if (this.quickSearchFilterClassesBackup == null) {
				this.quickSearchFilterClassesBackup = this.filteredClasses;
			} else {
				this.filteredClasses = this.quickSearchFilterClassesBackup;
			}
			const searchResults = this.filteredClasses.filter(item =>

				Object.keys(item).some(k =>
					(
						k == 'name'
					) &&
					item[k] != null &&
					item[k].toString().toLowerCase()
						.includes(this.quickSearchFilterClasses.toLowerCase())
				)
			);
			this.filteredClasses = searchResults
		}
	}

	resetSearchFilters() {
		this.resetQuickSearchWalletsInFilters()
		this.resetQuickSearchAccountsInFilters()
		this.resetQuickSearchTagsInFilters()
		this.resetQuickSearchAssetInFilters()
		this.resetQuickSearchClassesInFilters()
		this.resetQuickSearchClassification()
	}


	resetQuickSearchClassesInFilters() {
		this.quickSearchFilterClasses = null

		if (this.quickSearchFilterClassesBackup != null) {
			this.filteredClasses = this.quickSearchFilterClassesBackup;
		}

		this.quickSearchFilterClassesBackup = null
	}


	resetQuickSearchTagsInFilters() {
		this.quickSearchFilterTags = null

		if (this.quickSearchFilterTagsBackup != null) {
			this.filteredTags = this.quickSearchFilterTagsBackup;
		}

		this.quickSearchFilterTagsBackup = null
	}
	resetQuickSearchAssetInFilters() {
		this.quickSearchFilterAssets = null

		if (this.quickSearchFilterAssetsBackup != null) {
			this.filteredAssets = this.quickSearchFilterAssetsBackup;
		}

		this.quickSearchFilterAssetsBackup = null

		this.assetQuickFilterSearching = false
	}

	resetQuickSearchAccountsInFilters() {
		this.quickSearchFilterAccounts = null

		if (this.quickSearchFilterAccountsBackup != null) {
			this.global._exchangesConnected = this.quickSearchFilterAccountsBackup;
		}

		this.quickSearchFilterAccountsBackup = null
	}

	txFilterTagSelect(tag) {

		this.global.userPortfolioSettings.txtable_filters['tagsAll'] = false
		this.txFilterTagsSelectAllSelected = false
		if (this.global.userPortfolioSettings.txtable_filters['txTags'].indexOf(tag) == -1) {
			this.global.userPortfolioSettings.txtable_filters['txTags'].push(tag)
		} else {
			this.global.userPortfolioSettings.txtable_filters['txTags'].splice(this.global.userPortfolioSettings.txtable_filters['txTags'].indexOf(tag), 1)
		}

		// console.log('length: ' + this.global._transactionTags.length + ' - ' + this.global.userPortfolioSettings.txtable_filters['txTags'].length)

		if (this.global._transactionTags.length == this.global.userPortfolioSettings.txtable_filters['txTags'].length) {
			// this.global.userPortfolioSettings.txtable_filters['tagsAll'] = true
			this.txFilterTagsSelectAllSelected = true
		} else {
			this.txFilterTagsSelectAllSelected = false
			this.global.userPortfolioSettings.txtable_filters['tagsAll'] = false
		}

	}


	txCheckedCheck(type) {

		if (this.global.userPortfolioSettings.txtable_filters['type'].indexOf(type) !== -1) {
			return true
		} else {
			return false
		}
	}


	resetFilterView() {
		this.filterSelectMode = 1
		this.resetSearchFilters()
		this.resetMathAdditionalOps()
	}
	// filter globals

	filterDataFilter() {

		try {
			if (!this.global.userPortfolioSettings.txtable_filters['allExchangesConnect']) {
				if (this.global._exchangesActual.length == this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].length) {
					this.global.userPortfolioSettings.txtable_filters['allExchangesConnect'] = true
				} else {
					this.global.userPortfolioSettings.txtable_filters['allExchangesConnect'] = false
				}
			} else {
				this.global.userPortfolioSettings.txtable_filters['exchangeConnected'] = []
			}

			if (!this.global.userPortfolioSettings.txtable_filters['allWalletsConnect']) {
				if (this.global._walletsActual.length + this.global._virtualWalletsActual.length == (this.global.userPortfolioSettings.txtable_filters['walletsConnected'].length + this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].length)) {
					this.global.userPortfolioSettings.txtable_filters['allWalletsConnect'] = true
				} else {
					// console.log(false)
					// console.log('length filter' + (this.global.userPortfolioSettings.txtable_filters['walletsConnected'].length +this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].length ))
					// console.log('length global -' + (this.global._walletsActual.length))
					// console.log('length global 2 -'  + this.global._virtualWalletsActual.length)
					this.global.userPortfolioSettings.txtable_filters['allWalletsConnect'] = false
				}
			} else {
				// this.global.userPortfolioSettings.txtable_filters['walletsConnected'] = []
			}

			// Address groups
			if (!this.global.userPortfolioSettings.txtable_filters['allAddressGroupsConnect']) {

				if (this.global.addressGroups.length == this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'].length) {
					this.global.userPortfolioSettings.txtable_filters['allAddressGroupsConnect'] = true
				} else {
					this.global.userPortfolioSettings.txtable_filters['allAddressGroupsConnect'] = false
				}
			} else {

			}

			// Connection groups
			if (!this.global.userPortfolioSettings.txtable_filters['allConnectionGroupsConnect']) {

				if (this.global.connectionGroups.length == this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'].length) {
					this.global.userPortfolioSettings.txtable_filters['allConnectionGroupsConnect'] = true
				} else {
					this.global.userPortfolioSettings.txtable_filters['allConnectionGroupsConnect'] = false
				}
			} else {

			}


			if (!this.global.userPortfolioSettings.txtable_filters['assetsAll']) {

				if (this.global._allApplicableAssetsUnique.length ==
					this.global.userPortfolioSettings.txtable_filters['assetSymbols'].length) {
					this.txFilterAssetSelectAllSelected = true
				} else {
					this.txFilterAssetSelectAllSelected = false
				}

			} else {
				// console.log('length filter: ' + (this.global._allApplicableAssetsUnique.length) + ' - ' + this.global.userPortfolioSettings.txtable_filters['assetSymbols'].length )
				// console.log(this.filteredAssets)
				// console.log(this.global.userPortfolioSettings.txtable_filters['assetSymbols'])

				this.txFilterAssetSelectAllSelected = true
				this.global.userPortfolioSettings.txtable_filters['assetSymbols'] = []

			}

			if (this.global.userPortfolioSettings.txtable_filters['tagsAll']) {
				this.global.userPortfolioSettings.txtable_filters['txTags'] = []
			}



		} catch (error) {
			console.log(error)
		}

	}

	filterMathFilters() {
		if (this.global.userPortfolioSettings.txtable_filters !== undefined && this.global.userPortfolioSettings.txtable_filters !== null) {
			this.mathFilterKeys.forEach(key => {
				if (this.global.userPortfolioSettings.txtable_filters[key] != null) {
					if (this.global.userPortfolioSettings.txtable_filters[key].length > 1) {
						this.txFilterSetAdditionalOpValue(key, true)
					}
				}
			});
		}
	}


	txFilterClassSelectCheckSelected(classification) {
		if (this.global.userPortfolioSettings.txtable_filters['txClasses'].indexOf(classification.id) !== -1) {
			return true
		} else {
			return false
		}
	}

	txFilterAssetSelectCheckSelected(symbol) {

		if (this.global.userPortfolioSettings.txtable_filters['assetSymbols'].indexOf(symbol) !== -1) {
			return true
		} else {
			return false
		}
	}

	txFilterTagSelectCheckSelected(tag) {
		if (this.global.userPortfolioSettings.txtable_filters['txTags'].indexOf(tag) !== -1) {
			return true
		} else {
			return false
		}
	}

	txFilterWalletSelectCheckSelected(id) {
		if (this.global.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(id) !== -1) {
			return true
		} else {
			return false
		}
	}


	getFilteredSelectedExchanges() {
		if (this.txFilterExchangeSelectAllSelectd) {

			let accountCount = 0;

			if (this.global._exchangesConnected != null) {
				accountCount = accountCount + this.global._exchangesConnected.length
			}

			return accountCount
		} else {
			return this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].length
		}

	}

	// get selected address group count
	getSelectedAddressGroupCount() {
		if (this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected']) {

			if (this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'].length > 0) {

				return this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'].length

			} else {
				return 0
			}
		} else {
			return 0
		}
	}


	// get selected address group count
	getSelectedConnectionGroupCount() {
		if (this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected']) {

			if (this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'].length > 0) {

				return this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'].length

			} else {
				return 0
			}
		} else {
			return 0
		}
	}

	getFiltersSelectedWallets() {
		if (this.txFilterWalletSelectAllSelectd) {
			let walletCount = 0;

			this.global._wallets.forEach(element => {
				if (element.parent_wallet_id == null) {
					walletCount++
				}
			});

			this.global._virtualWallets.forEach(element => {
				if (element.exchange_connection_id == 0) {
					walletCount++
				}
			});

			return walletCount

		} else {
			return this.global.userPortfolioSettings.txtable_filters['walletsConnected'].length + this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].length
		}
	}

	txFilterWalletSelect(id) {
		// console.log('called false')
		console.log(id)
		this.txFilterWalletSelectAllSelectd = false
		this.global.userPortfolioSettings.txtable_filters['allWalletsConnect'] = false
		const arrIndex = this.global.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(id)
		if (arrIndex !== -1) {
			this.global.userPortfolioSettings.txtable_filters['walletsConnected'].splice(arrIndex, 1)
		} else {

			this.global.userPortfolioSettings.txtable_filters['walletsConnected'].push(id)
		}

		this.filterDataFilter()

	}

	txFilterExchangeSelect(id) {
		// console.log(id)
		this.txFilterExchangeSelectAllSelectd = false
		// if(this.global.userPortfolioSettings.txtable_filters !== undefined && this.global.userPortfolioSettings.txtable_filters !== null){
		this.global.userPortfolioSettings.txtable_filters['allExchangesConnect'] = false

		const arrIndex = this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(id)
		if (arrIndex !== -1) {
			this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].splice(arrIndex, 1)
		} else {
			this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].push(id)
		}
		// }
		this.filterDataFilter()
	}


	txFilterGetAdditionalOpValue(key) {
		// No additional Ops for an 'Equal To' Operation!
		if (this.global.userPortfolioSettings.txtable_filters[key + 'Op'] == 4) {
			return false;
		}

		switch (key) {
			case 'inAmount':
				return this.additionalInAmountOp
			case 'outAmount':
				return this.additionalOutAmountOp
			case 'feeAmount':
				return this.additionalFeeAmountOp
			case 'feeValue':
				return this.additionalFeeValueOp
			case 'costBasis':
				return this.additionalCostBasisOp
			case 'salePrice':
				return this.additionalSalePriceOp
			case 'unitPrice':
				return this.additionalUnitPriceOp
			case 'fiatValue':
				return this.additionalFiatValueOp
			case 'runningBalanceValue':
				return this.additionalRunningBalanceValueOp
		}
	}

	txFilterSetAdditionalOpValue(key, value) {
		// Remove extra values in current filters if they remove the additional op.
		if (value == false) {
			this.global.userPortfolioSettings.txtable_filters[key].pop()
		}

		switch (key) {
			case 'inAmount':
				this.additionalInAmountOp = value
				break;
			case 'outAmount':
				this.additionalOutAmountOp = value
				break;
			case 'feeAmount':
				this.additionalFeeAmountOp = value
				break;
			case 'feeValue':
				this.additionalFeeValueOp = value
				break;
			case 'costBasis':
				this.additionalCostBasisOp = value
				break;
			case 'salePrice':
				this.additionalSalePriceOp = value
				break;
			case 'unitPrice':
				this.additionalUnitPriceOp = value
				break;
			case 'fiatValue':
				this.additionalFiatValueOp = value
				break;
			case 'runningBalanceValue':
				this.additionalRunningBalanceValueOp = value
				break;
		}
	}



	txFilterExchangeSelectCheckSelected(id) {
		if (this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(id) !== -1) {
			return true
		} else {
			return false
		}
	}

	txFilterWalletSelectNone() {
		this.txFilterWalletSelectAllSelectd = false
		// console.log('called false')
		this.global.userPortfolioSettings.txtable_filters['allWalletsConnect'] = false
		this.global.userPortfolioSettings.txtable_filters['walletsConnected'] = []
		this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'] = []
	}

	txFilterExchangeSelectAll() {
		this.global.userPortfolioSettings.txtable_filters['allExchangesConnect'] = true
		this.txFilterExchangeSelectAllSelectd = true
		this.global._exchangesConnected.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(element.exchange_connection_id) == -1) {
				this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].push(element.exchange_connection_id)
			}
		});
	}

	txFilterExchangeSelectNone() {
		this.txFilterExchangeSelectAllSelectd = false
		this.global.userPortfolioSettings.txtable_filters['exchangeConnected'] = []
		this.global.userPortfolioSettings.txtable_filters['allExchangesConnect'] = false
	}

	txFilterWalletSelectAll() {
		this.global.userPortfolioSettings.txtable_filters['allWalletsConnect'] = true
		this.txFilterWalletSelectAllSelectd = true
		this.global._wallets.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(element.id) == -1) {
				if (element.parent_wallet_id == null) {
					this.global.userPortfolioSettings.txtable_filters['walletsConnected'].push(element.id)
				}
			}
		});

		this.global._virtualWallets.forEach(element => {
			if (element.exchange_connection_id == 0) {
				if (this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].indexOf(element.id) == -1) {
					this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].push(element.id)
				}
			}
		});
	}

	selectAllAllConnections(state) {

		this.selectAllAllConnectionsState = state

		if (state == false) {
			this.txFilterWalletSelectNone()
			this.txFilterExchangeSelectNone()
		} else if (state == true) {
			this.txFilterWalletSelectAll()
			this.txFilterExchangeSelectAll()
		}

	}

	txFilterClassesSelectAll() {
		this.txFilterClassesSelectAllSelected = true
		this.global.userPortfolioSettings.txtable_filters['classesAll'] = true
		this.filteredClasses.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['txClasses'].indexOf(element.id) == -1) {
				this.global.userPortfolioSettings.txtable_filters['txClasses'].push(element.id)
			}
		});
	}

	txFilterClassesSelectNone() {
		this.txFilterClassesSelectAllSelected = false
		this.global.userPortfolioSettings.txtable_filters['classesAll'] = false
		this.global.userPortfolioSettings.txtable_filters['txClasses'] = []
	}

	txFilterClassSelect(classification) {

		this.txFilterClassesSelectAllSelected = false
		if (this.global.userPortfolioSettings.txtable_filters['txClasses'].indexOf(classification.id) == -1) {
			this.global.userPortfolioSettings.txtable_filters['txClasses'].push(classification.id)
		} else {
			this.global.userPortfolioSettings.txtable_filters['txClasses'].splice(this.global.userPortfolioSettings.txtable_filters['txClasses'].indexOf(classification.id), 1)
		}

		// console.log('length: ' + this.totalClassesCount + ' - ' + this.global.userPortfolioSettings.txtable_filters['txClasses'].length)
		if (this.totalClassesCount == this.global.userPortfolioSettings.txtable_filters['txClasses'].length) {
			this.global.userPortfolioSettings.txtable_filters['classesAll'] = true
			this.txFilterClassesSelectAllSelected = true
		} else {
			this.txFilterClassesSelectAllSelected = false
			this.global.userPortfolioSettings.txtable_filters['classesAll'] = false
		}
	}

	txFilterGroupSelectAll() {
		this.global.userPortfolioSettings.txtable_filters['allAddressGroupsConnect'] = true
		this.txFilterGroupSelectAllSelectd = true
		this.global.addressGroups.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'].indexOf(element.id) == -1) {
				this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'].push(element.id)
			}
		});

		console.log(this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'])
	}

	txFilterGroupSelectNone() {
		this.txFilterGroupSelectAllSelectd = false
		this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'] = []
		this.global.userPortfolioSettings.txtable_filters['allAddressGroupsConnect'] = false

		console.log(this.global.userPortfolioSettings.txtable_filters['addressGroupsConnected'])
	}

	// connection groups
	txFilterConnectionGroupSelectAll() {
		this.global.userPortfolioSettings.txtable_filters['allConnectionGroupsConnect'] = true
		this.txFilterConnectionGroupSelectAllSelectd = true
		this.global.connectionGroups.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'].indexOf(element.id) == -1) {
				this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'].push(element.id)
			}
		});

		console.log(this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'])
	}

	txFilterConnectionGroupSelectNone() {
		this.txFilterConnectionGroupSelectAllSelectd = false
		this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'] = []
		this.global.userPortfolioSettings.txtable_filters['allConnectionGroupsConnect'] = false

		console.log(this.global.userPortfolioSettings.txtable_filters['connectionGroupsConnected'])
	}

	txFilterVirtualWalletSelectCheckSelected(id) {
		if (this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].indexOf(id) !== -1) {
			return true
		} else {
			return false
		}
	}

	txFilterAssetSelect(symbol) {

		if (this.global.userPortfolioSettings.txtable_filters.assetsAll) {

			if (this.assetQuickFilterSearching) {

				this.global.userPortfolioSettings.txtable_filters['assetSymbols'] = []
				this.quickSearchFilterAssetsBackup.forEach(element => {
					this.global.userPortfolioSettings.txtable_filters['assetSymbols'].push(element.symbol)
				})
			} else {
				this.global.userPortfolioSettings.txtable_filters['assetSymbols'] = []
				this.filteredAssets.forEach(element => {
					this.global.userPortfolioSettings.txtable_filters['assetSymbols'].push(element.symbol)
				})
			}

		}

		this.AssetDef = false
		if (this.global.userPortfolioSettings.txtable_filters['assetSymbols'].indexOf(symbol) == -1) {

			this.global.userPortfolioSettings.txtable_filters['assetSymbols'].push(symbol)

		} else {

			while (this.global.userPortfolioSettings.txtable_filters['assetSymbols'].indexOf(symbol) !== -1) {
				this.global.userPortfolioSettings.txtable_filters['assetSymbols'].splice(this.global.userPortfolioSettings.txtable_filters['assetSymbols'].indexOf(symbol), 1)
			}

			// this.global.userPortfolioSettings.txtable_filters['assetSymbols'].splice(this.global.userPortfolioSettings.txtable_filters['assetSymbols'].indexOf(symbol), 1)

		}
		this.global.userPortfolioSettings.txtable_filters['assetsAll'] = false
		this.txFilterAssetSelectAllSelected = false

		this.AssetCheck = false
		// this.global.userPortfolioSettings.txtable_filters['assetSymbols'] = Array.from(new Set(this.global.userPortfolioSettings.txtable_filters['assetSymbols']))
		this.filterDataFilter()

		console.log('asset selected.  Selected assets::')
		console.log(this.global.userPortfolioSettings.txtable_filters['assetSymbols'])
	}

	txFilterTagsSelectAll() {
		this.txFilterTagsSelectAllSelected = true
		this.global.userPortfolioSettings.txtable_filters['tagsAll'] = true
		this.filteredTags.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['txTags'].indexOf(element) == -1) {
				this.global.userPortfolioSettings.txtable_filters['txTags'].push(element)
			}
		});
	}

	txFilterTagsSelectNone() {
		this.txFilterTagsSelectAllSelected = false
		this.global.userPortfolioSettings.txtable_filters['tagsAll'] = false
		this.global.userPortfolioSettings.txtable_filters['txTags'] = []
	}

	txFilterAssetSelectAll() {
		this.AssetDef = false
		this.global.userPortfolioSettings.txtable_filters['assetsAll'] = true
		console.log('assets select all')
		console.log(this.filteredAssets)
		this.txFilterAssetSelectAllSelected = true
		this.filteredAssets.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['assetSymbols'].indexOf(element.symbol) == -1) {
				this.global.userPortfolioSettings.txtable_filters['assetSymbols'].push(element.symbol)
			}
		});
		console.log(this.global.userPortfolioSettings.txtable_filters['assetSymbols'])
	}

	txFilterAssetSelectNone() {
		this.AssetDef = true
		this.txFilterAssetSelectAllSelected = false
		this.global.userPortfolioSettings.txtable_filters['assetsAll'] = false
		this.global.userPortfolioSettings.txtable_filters['assetSymbols'] = []
	}


	filterTxSelectTypeNone() {
		this.global.userPortfolioSettings.txtable_filters['type'] = []
	}

	filterTxSelectType(type) {
		console.log('clicked', type)
		if (type == 'all') {
			if (this.global.userPortfolioSettings.txtable_filters['type'].indexOf(type) == -1) {
				this.global.userPortfolioSettings.txtable_filters['type'].push('all')
				this.global.userPortfolioSettings.txtable_filters['type'].push('send')
				this.global.userPortfolioSettings.txtable_filters['type'].push('receive')
				this.global.userPortfolioSettings.txtable_filters['type'].push('buy')
				this.global.userPortfolioSettings.txtable_filters['type'].push('sell')
			} else {
				this.global.userPortfolioSettings.txtable_filters['type'] = []
			}
		} else {

			const arrIndex = this.global.userPortfolioSettings.txtable_filters['type'].indexOf(type)
			if (arrIndex == -1) {
				this.global.userPortfolioSettings.txtable_filters['type'].push(type)
			} else {
				if (arrIndex !== -1) {
					this.global.userPortfolioSettings.txtable_filters['type'].splice(arrIndex, 1)
				}

				if (this.global.userPortfolioSettings.txtable_filters['type'].indexOf('all') !== -1) {
					this.global.userPortfolioSettings.txtable_filters['type'].splice(this.global.userPortfolioSettings.txtable_filters['type'].indexOf('all'), 1)
				}
			}
		}
	}


	txFilterSetInternalTransferFilter(value) {
		this.global.userPortfolioSettings.txtable_filters['internalTransfer'] = value
	}

	txFilterIncludeAssetFees() {
		this.global.userPortfolioSettings.txtable_filters['assetIncludeFees'] = !this.global.userPortfolioSettings.txtable_filters['assetIncludeFees']
	}



	txFilterSetTaxability(value) {
		this.global.userPortfolioSettings.txtable_filters['taxability'] = value
	}

	txFilterSetIgnoreStatus(value) {
		this.global.userPortfolioSettings.txtable_filters['isIgnored'] = value
	}



	txFilterSetDeviationFilter(value) {
		this.global.userPortfolioSettings.txtable_filters['deviations'] = value
	}

	txFilterSetSupportedAssetsFilter(value) {
		this.global.userPortfolioSettings.txtable_filters['supportedAssets'] = value
	}

	txFilterSyncStatusChange(value) {

		let all = this.global.userPortfolioSettings.txtable_filters.syncStatus.all;
		let synced = this.global.userPortfolioSettings.txtable_filters.syncStatus.synced;
		let failed = this.global.userPortfolioSettings.txtable_filters.syncStatus.failed;
		let ignored = this.global.userPortfolioSettings.txtable_filters.syncStatus.ignored;
		let unSynced = this.global.userPortfolioSettings.txtable_filters.syncStatus.unSynced;
		let updated = this.global.userPortfolioSettings.txtable_filters.syncStatus.updated;
		let virtual = this.global.userPortfolioSettings.txtable_filters.syncStatus.virtual;

		if (value === 0) {
			all = !all;
			if (all === true) {
				synced = true;
				failed = true;
				ignored = true;
				unSynced = true;
				updated = true;
				virtual = true;
			} else {
				synced = false;
				failed = false;
				ignored = false;
				unSynced = false;
				updated = false;
				virtual = false;
			}
		} else {
			if (value == 1) {
				synced = !synced;
			}

			if (value == 2) {
				failed = !failed;
			}

			if (value == 3) {
				ignored = !ignored;
			}

			if (value == 4) {
				unSynced = !unSynced;
			}

			if (value == 5) {
				updated = !updated;
			}

			if (value == 6) {
				virtual = !virtual;
			}
			if (synced && failed && ignored && unSynced && updated && virtual) {
				all = true;
			} else if (!synced || !failed || !ignored || !unSynced || !updated || !virtual) {
				all = false;
			}
		}

		this.global.userPortfolioSettings.txtable_filters.syncStatus.all = all;;
		this.global.userPortfolioSettings.txtable_filters.syncStatus.synced = synced;
		this.global.userPortfolioSettings.txtable_filters.syncStatus.failed = failed;
		this.global.userPortfolioSettings.txtable_filters.syncStatus.ignored = ignored;
		this.global.userPortfolioSettings.txtable_filters.syncStatus.unSynced = unSynced;
		this.global.userPortfolioSettings.txtable_filters.syncStatus.updated = updated;
		this.global.userPortfolioSettings.txtable_filters.syncStatus.virtual = virtual;
	}


	filterTransactions(closeModal = true) {
		this.resetTableView()
		this.resetFilterView()
		this.resetLogs()
		this.filterDataFilter()
		this.resetQuickSearch()
		this.global.removeAllLogs()

		this.global.newPortfolio = false;

		this.txFilterApplied = true
		// console.log(this.global.userPortfolioSettings.txtable_filters)

		this.getPortfolioTransactions()

		if (this.findDuplicateTxStatus == 2) {
			this.findDuplicateTxOperation(1, null)
		}

	}

	applyQuickSearchAssetInFilters() {
		if (this.quickSearchFilterAssets == '') {
			this.resetQuickSearchAssetInFilters()
		} else {
			if (this.quickSearchFilterAssetsBackup == null) {
				this.quickSearchFilterAssetsBackup = this.filteredAssets;
			} else {
				this.filteredAssets = this.quickSearchFilterAssetsBackup;
			}
			const searchResults = this.filteredAssets.filter(item =>

				Object.keys(item).some(k =>
					(
						k == 'name' ||
						k == 'symbol'
					) &&
					item[k] != null &&
					item[k].toString().toLowerCase()
						.includes(this.quickSearchFilterAssets.toLowerCase())
				)
			);
			this.filteredAssets = searchResults

		}

		this.assetQuickFilterSearching = true

	}

	applyQuickSearchTagsInFilters() {
		if (this.quickSearchFilterTags == '') {
			this.resetQuickSearchTagsInFilters()
		} else {
			if (this.quickSearchFilterTagsBackup == null) {
				this.quickSearchFilterTagsBackup = this.filteredTags;
			} else {
				this.filteredTags = this.quickSearchFilterTagsBackup;
			}
			const searchResults = this.filteredTags.filter(item =>

				// Object.keys(item).some(k =>
				item != null &&
				item.toString().toLowerCase()
					.includes(this.quickSearchFilterTags.toLowerCase())
				// )
			);
			this.filteredTags = searchResults

		}

	}


	doForceResetFilters() {
		console.log('Force resetting filters via tx filters')
		console.log(this.global.userPortfolioSettings.txtable_filters)

		this.resetLogs()

		this.txFilterApplied = false
		this.backUpFilters = null
		this.global.userPortfolioSettings.txtable_filters = { ... this.global.defaultTxFilter }

		console.log('resetted filters')
		console.log(this.global.userPortfolioSettings.txtable_filters)

		this.txFilterAssetSelectAllSelected = true
		this.txFilterClassesSelectAllSelected = true
		this.txFilterTagsSelectAllSelected = true

		this.filterTags()
		this.filterWeb3Products()
		this.filterClasses()
		this.filterAssets()

		if (this.global._exchangesConnected == null) {
			this.global.httpPOST('get_exchange_list', { portfolioId: this.global.currentPortfolioId }).subscribe(response => {
				if (response.status == true) {
					this.global._exchangesConnected = response.data
					this.global._exchangesActual = response.data
					this.global._exchangesConnected.forEach(element => {
						if (this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(element.exchange_connection_id) == -1) {
							this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].push(element.exchange_connection_id)
						}
					});

				}
			}, error => {
			});
		} else {
			this.global._exchangesConnected.forEach(element => {
				if (this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(element.exchange_connection_id) == -1) {
					this.global.userPortfolioSettings.txtable_filters['exchangeConnected'].push(element.exchange_connection_id)
				}
			});
		}

		this.global._wallets.forEach(element => {
			if (this.global.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(element.id) == -1) {
				if (element.parent_wallet_id == null) {
					this.global.userPortfolioSettings.txtable_filters['walletsConnected'].push(element.id)
				}
			}
		});

		this.filteredVirtualWallets.forEach(element => {
			if (element.exchange_connection_id == 0) {
				if (this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].indexOf(element.id) == -1) {
					this.global.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].push(element.id)
				}
			}
		});
	}


	filterClasses() {
		const unclassified = {
			id: -1,
			name: 'Unclassified'
		}
		this.filteredClasses = [unclassified]

		if (this.global.userPortfolioSettings.txtable_filters !== undefined && this.global.userPortfolioSettings.txtable_filters !== null) {
			if (this.global.userPortfolioSettings.txtable_filters['txClasses'].indexOf(unclassified.id) == -1 && this.global.userPortfolioSettings.txtable_filters['classesAll']) {
				this.global.userPortfolioSettings.txtable_filters['txClasses'].push(unclassified.id)
			}

			if (this.global.userPortfolioSettings.txtable_filters['classesAll']) {
				this.txFilterClassesSelectAllSelected = true
			} else {
				this.txFilterClassesSelectAllSelected = false
			}

			this.global._classifications.forEach(element => {

				if (this.global.userPortfolioSettings.txtable_filters.classesAll && this.global.userPortfolioSettings.txtable_filters['txClasses'].indexOf(element.id) == -1) {
					this.global.userPortfolioSettings.txtable_filters['txClasses'].push(element.id)

					if (element.L1 != null) {
						element.L1.forEach(childClass => {
							childClass.name = element.name + ' / ' + childClass.name
							this.global.userPortfolioSettings.txtable_filters['txClasses'].push(childClass.id)
						});

					}
				}

				if (this.filteredClasses.indexOf(element) == -1) {
					this.filteredClasses.push(element)
					if (element.L1 != null) {
						element.L1.forEach(childClass => {
							this.filteredClasses.push(childClass)
						});
					}
				}
			});
			this.totalClassesCount = this.filteredClasses.length
		}
	}

	addLastUsedClassification(classification) {
		if (classification != null && this.lastUsedClassificationsIds.indexOf(classification.id) == -1) {

			this.lastUsedClassifications.unshift(classification)
			this.lastUsedClassificationsIds.unshift(classification.id)

			if (this.lastUsedClassifications.length > 3) {
				this.lastUsedClassifications.pop();
				this.lastUsedClassificationsIds.pop();
			}


		}
		console.log(this.lastUsedClassifications)
		console.log(this.lastUsedClassificationsIds)

	}



}
