<!-- not used -->
<div class="py-1" style="color:#fff; background-color:#d64343;font-size: 15px;" *ngIf="global._userDetails['status']==0 && global.hb_packagePurcahseDetails!= null" >
	<div class="container-fluid" >
	
		<div class="row d-flex align-items-center">
			<div class="col-md-10 text-md-left mb-4 mb-md-0">
				<p class="text-white">
					<b><i class="fa fa-exclamation-circle text-white" aria-hidden="true"></i> Warning! We tried to charge your account but the payment was declined.</b> <br/><br/>
					Last payment payment attempt was made on <b><u>{{ global.hb_packagePurcahseDetails['expires_at'] | amDateFormat:'MMM D, Y'}}</u> </b>.
					You have till <b><u>{{ global.hb_packagePurcahseDetails['grace_expiration_at'] | amDateFormat:'MMM D, Y' }}</u></b> to fix the payment issue. <br/>
					If the payment is not made by <b><u>{{ global.hb_packagePurcahseDetails['grace_expiration_at'] | amDateFormat:'MMM D, Y' }}</u></b> your account will be <b>suspended</b> temporary.
				</p>
			</div>
			<div class="col-md-2 text-md-left mb-4 mb-md-0">
				<button class="btn btn-danger border" (click)="openRePaymentModule(rePaymentModal)">
					Pay Now
				</button>
			</div>
		</div>
	</div>
</div>
<div class="py-1" style="color:#fff; background-color:#d64343;font-size: 15px;" *ngIf="global.hb_packagePurcahseDetails!= null && global.hb_packagePurcahseDetails['status']==-1" >
	<div class="container-fluid" >
	
		<div class="row d-flex align-items-center">
			<div class="col-md-10 text-md-left mb-4 mb-md-0">
				<br/>
				<p class="text-white">
					<b><i class="fa fa-exclamation-circle text-white" aria-hidden="true"></i> Subscription Cancelled.</b> <br/>
					Your account validity ends on <b><u>{{ global.hb_packagePurcahseDetails['expires_at'] | amDateFormat:'MMM D, Y'}}</u> </b>. You can re-enable subscription in the billing page.

				</p>
				<br/>
			</div>
			<div class="col-md-2 text-md-left mb-4 mb-md-0">
				<a href="javascript.void(0)" class="btn btn-danger border" [routerLink]="['/billing']">
					Billing Page
				</a>
			</div>
		</div>
	</div>
</div>

<nav class="sticky-top navbar navbar-expand-lg navbar-cw pl-0 pr-0 " *ngIf="global._authStatus==true" >
	<div class="container-fluid max-width">
			<a class="navbar-brand" href="#" [routerLink]="['/portfolios']" style="font-weight: 900;">
				<img src="{{global.FE_IMG_URL}}img/logo/logo_w.png" height="30" class="d-inline-block align-top" alt="" style="padding-right: 2%;margin-right: 12px !important;">  
			</a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
		
	</div>
	
</nav>

<ng-template #rePaymentModal let-c="close" let-d="dismiss" >
	<div class="modal-header" [ngClass]="{'hidden': global.paymentActive==true}">
		<h4 class="modal-title pull-left" >
			Make Payment
		</h4>
		<!-- <button type="button" class="btn btn-gold pull-right" aria-label="Close" (click)="d('Cross click')">
			<i class="fa fa-star" aria-hidden="true"></i> Upgrade Plan
		</button> -->
	</div>
	<div class="modal-body" [ngClass]="{'hidden': global.paymentActive==true}">
		<div class="form-group">
			<app-re-payment></app-re-payment>
		</div>
	</div>
	<div class="modal-footer" [ngClass]="{'hidden': global.paymentActive==true}">
		<button type="button" class="btn btn-primary btn-lg " (click)="d('Cross click')">Close</button>
	</div>
</ng-template>
	
	
<ng-template #bugReportModal let-c="close" let-d="dismiss">
  
	<div class="modal-header">
		<h4 class="modal-title pull-left">Bug Reporting</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
		<span aria-hidden="true">&times;</span>
		</button>
	</div>
	
	<div class="modal-body" *ngIf="!transactionsExported">
		
		<div class="container mt-4 mb-4">

			<ngb-alert *ngIf="errorCode == 1" [type]="'warning'" (close)="closeSubAlert()">
				Please select a Bug Type.
			</ngb-alert>

			<ngb-alert *ngIf="errorCode == 2" [type]="'warning'" (close)="closeSubAlert()">
				Please Enter a title.
			</ngb-alert>

			<ngb-alert *ngIf="errorCode == 3" [type]="'warning'" (close)="closeSubAlert()">
				Please Enter a Description.
			</ngb-alert>

			<ngb-alert *ngIf="errorCode == 4" [type]="'warning'" (close)="closeSubAlert()">
				Title should be less than 250 characters.
			</ngb-alert>

			<ngb-alert *ngIf="errorCode == 5" [type]="'warning'" (close)="closeSubAlert()">
				Description should be less than 2500 characters.
			</ngb-alert>

			<div class="form-group">
				<div class="row margin-bottom-10">
					<div class="col-3">
						Bug Category
					</div>
	
					<div class="col-9">
						<select [(ngModel)]="bugReport.category_id" class="form-control" id="select">
							<option [ngValue]="null" selected disabled>
								Select a type
							</option>
							<option [ngValue]="1">
								General
							</option>
							<option [ngValue]="2">
								Wallets
							</option>
							<option [ngValue]="3">
								Exchange Connections
							</option>
							<option [ngValue]="4">
								Transactions
							</option>
							<option [ngValue]="5">
								Analytics
							</option>
							<option [ngValue]="6">
								Security
							</option>
						</select> 
					</div>
				</div>

				<div class="row margin-bottom-10">
					<div class="col-3">
						Title
					</div>
	
					<div class="col-9">
						<input type="text" [(ngModel)]="bugReport.title" name="title" class="form-control" id="title" aria-describedby="Bug Title" placeholder="Label">
					</div>
				</div>

				<div class="row margin-bottom-10">
					<div class="col-3">
						Description
					</div>
	
					<div class="col-9">
						<textarea type="text" [(ngModel)]="bugReport.description" name="description" class="form-control" id="description" aria-describedby="Bug Description" placeholder="Description">
						</textarea>
					</div>
				</div>
			</div>

		</div>

	</div>

	
	<div class="modal-footer">
		<button *ngIf="!formState" type="button" class="btn btn-primary btn-lg" (click)="reportBug()">
			Report Bug
		</button>
		<button *ngIf="formState" type="button" class="btn btn-primary btn-lg disabled" disabled>
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Report Bug
		</button>
	</div>
	
</ng-template>
