<div class="card m-0 ">
	<div class="card-body pl-0 pt-0 pb-0 pl-3">
		<ng-container>
			<div class="row">
				<div class="col pr-0">

					<section class="py-2 pr-2" style="border-radius:0px;" >
						
						<div class=" py-2">
							<span class="second-nav-header pl-2">
								Address Book 
								<span *ngIf="viewMode == 'mini'">
									<small>(</small><small (click)="coinService.forceAddressBook();closeModalRequestEventTrigger()" class="text-primary pointer">View full</small><small>)</small>
								</span>
							</span>	
							<!-- <button type="button" class="close pull-right pl-3 pt-2" aria-label="Close" (click)="d('Cross click');closeModal()" *ngIf="viewMode == 'mini'">
								<span aria-hidden="true">&times;</span>
							</button> -->
							<app-create-contact></app-create-contact>
						</div>
					</section>
				</div>
			</div>
			<div class="row border-top">
				<div class="col-md-2 border-right pr-0">
					<div aria-orientation="vertical" class="nav flex-column nav-pills mt-2 mb-2" id="v-pills-tab" role="tablist">
						<p class="text-muted mb-2 pointer pl-3"> Groups </p>
						<a class="nav-link pointer text-left" [class.active]="viewingGroup === false" (click)="viewGroup(false)">
							<b>All</b>
						</a>
						<ng-container *ngIf="global.addressGroups">
							<ng-container *ngFor="let group of global.addressGroups; let i = index">
								<div (mouseenter)="grouphovered=i" (mouseleave)="grouphovered=-1" style="height: 37px;">
									
									<a class="nav-link pointer text-left" style="white-space: nowrap;" [class.active]="viewingGroup.id == group.id" (click)="viewGroup(group)">
										<span>
											<span class="text-muted align-middles pr-2" *ngIf="group['type'] == 3">
												<i class="fa fa-briefcase" ngbTooltip="Workspace group" placement="top" aria-hidden="true"></i> 
											</span>
											<span class="text-muted align-middles pr-2" *ngIf="group['type'] == 0">
												<i class="fa fa-user" ngbTooltip="Private group" placement="top" aria-hidden="true"></i> 
											</span>
											<span class="text-muted align-middles pr-2" *ngIf="group['type'] == 1">
												<i class="fa fa-building" ngbTooltip="Organizational group" placement="top" aria-hidden="true"></i> 
											</span>
											<img class="connection-image-sms" *ngIf="group['connector_id']" src="{{global.EXCHANGE_IMG_URL}}{{ group['connector_id'] }}.png?ver={{global.imageVer}}" height="12" alt=""/>
											<b> {{ group.group_name }}</b>
										</span>
									</a>
								</div>

							</ng-container>
						</ng-container>
					</div>
				</div>
				<div class="col bg-white pr-0">
					<div class="row border-bottom mr-2 pl-2 justify-content-between">
						<div class="col my-2 pl-0">
							<span class="text-bold align-middle" *ngIf="viewingGroup === false">All</span>
							<span class="text-bold align-middle mr-1" *ngIf="viewingGroup !== false">
								<span class="text-bold align-middle" *ngIf="viewingGroup['type'] == 0"><i class="fa fa-user" ngbTooltip="Personal group" placement="top" aria-hidden="true"></i></span>
								<span class="text-bold align-middle" *ngIf="viewingGroup['type'] == 1"><i class="fa fa-building" ngbTooltip="Organizational group" placement="top" aria-hidden="true"></i></span>
								<span class="text-bold align-middle" *ngIf="viewingGroup['type'] == 3">
									<i class="fa fa-briefcase" ngbTooltip="Workspace group" placement="top" aria-hidden="true"></i> 
									&nbsp;<span *ngIf="viewingGroup['portfolioInfo'] != null">
										({{global.parseFileName(viewingGroup['portfolioInfo']['name'], 11)}}) &nbsp;- 
									</span>
								</span>
							</span>
							<img class="connection-image-sm" *ngIf="viewingGroup['connector_id']" src="{{global.EXCHANGE_IMG_URL}}{{ viewingGroup['connector_id'] }}.png?ver={{global.imageVer}}" height="15" alt=""/>
							<span *ngIf="viewingGroup['connector_id']">&nbsp;</span>
							<span class="text-bold align-middle" *ngIf="viewingGroup !== false">{{viewingGroup['group_name']}}</span>
							<span class="text-muted align-middle" *ngIf="global.addresses != null && viewingGroup == false">
							- {{ global.addresses.length }} Addresses
							</span>
							<span class="text-muted align-middle" *ngIf="global.addressDisplayCount !== null && viewingGroup !== false">
							- {{ global.addressDisplayCount }} Addresses
							</span>
							<span class="text-muted align-middle" *ngIf="global.addressDisplayCount === null && viewingGroup !== false">
								<img class="loader-icon ml-2" src="{{global.FE_IMG_URL}}img/payment/loader.gif">
							</span>
						</div>
						<div class="d-flex float-right p-2">
							<ng-container *ngIf="!viewingGroup" >
								<button type="button" class="btn btn-hr-min btn-sm ml-2" placement="bottom"  ngbTooltip="Refresh all addresses" style="float:right" *ngIf="!global.addressLoading" (click)="global.getAllAddresses();">
									<i class="fa fa-refresh" aria-hidden="true"></i>
								</button>
								<button type="button" class="btn btn-hr-min btn-sm ml-2" placement="bottom"  ngbTooltip="Refresh all addresses" style="float:right" *ngIf="global.addressLoading">
									<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader.gif">
								</button>
							</ng-container>
							<ng-container *ngIf="viewingGroup !== false" >
								<button class="btn btn-hr-min btn-sm ml-2" *ngIf="viewingGroup !== false" (click)="global.dropdownOpenClose(0);openFullModal(bulkImportAddressGroup)"> <!-- openFullModal(bulkImportAddressGroup) -->
									<i aria-hidden="true" class="fa fa-download"></i>
									Import to Group
								</button>
								<span ngbDropdown class="nav-item active float-right pl-2 nodropcaret" *ngIf="viewingGroup !== false" placement="bottom-right">
									<button (click)="global.dropdownOpenClose(6)" type="button"
										class="btn btn-hr-min btn-sm ml-0" ngbDropdownToggle placement="bottom">
										<i class="fa fa-ellipsis-v" aria-hidden="true"></i>
									</button>
									<ng-container>
										<ul aria-labelledby="dropdownMenu6" ngbDropdownMenu [class.upfront]="global.isdropdownOpen==6">
											<li class="dropdown-item pointer" (click)="global.dropdownOpenClose(6);editingGroup=true;openGroupModal(editAddressGroup);">
												<span><i class="mr-2 fa fa-pencil text-primary"></i> Edit</span>
											</li>
											<li class="dropdown-item pointer text-danger" (click)="global.dropdownOpenClose(6);openModal(deleteGroupConfirm);">
												<span><i class="mr-2 fa fa-trash text-danger"></i> Delete</span>
											</li>
										</ul>
									</ng-container>
								</span>
							</ng-container>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 pl-0">
							<app-address-list (selectedAddressEmit)="viewAddress($event)"></app-address-list>
						</div>
						<div class="bg-white" [class.col-md-7]="viewMode == 'mini'" [class.col-md-6]="viewMode == 'full'">
							<app-address-view-info [viewingAddress]="viewingAddress"></app-address-view-info>
						</div>
					</div>
				</div>


				
			</div>
		</ng-container>
	</div>
</div>


<ng-template #bulkImportAddressGroup let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h4 class="modal-title pull-left" *ngIf="viewingGroup === false">Bulk Group Import</h4>
		<h4 class="modal-title pull-left" *ngIf="viewingGroup !== false">Bulk '{{viewingGroup['group_name']}}' Group Import</h4>
		<button type="button" class="close pull-right" aria-label="Close"
			(click)="d('Cross click');closeImportAddressModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">

		<div class="container">
			<div class="row pt-4 mt-2">
				<div class="col-md-6 border-right">
					<div class="" *ngIf="importAddressGroup.importState==0">
						<div class="form-group">
							<label for="sel1">
								Attach CSV
								<span *ngIf="importAddressGroup.importFileTypeError" class="text-danger"><i
										class="fa fa-times" aria-hidden="true"></i> Invalid CSV</span>
							</label>
							<div class="form-group">
								<!-- <input class="btn btn-primary" type="file"  /> -->
								<div class="input-group pointer">
									<div class="custom-file pointer">
										<input class="pointer" style="cursor: pointer !important;" type="file"
											value="Attach CSV" #fileUpload id="fileUpload" name="fileUpload"
											(change)="uploadFile($event)" class="custom-file-input"
											id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
										<label class="custom-file-label pointer" for="inputGroupFile01"
											[class.rebd]="importAddressGroup.importFileTypeError">
											<span *ngIf="importAddressGroup.uploadEvent==null">Choose your CSV file</span>
											<span *ngIf="importAddressGroup.uploadEvent!=null">
												<span *ngIf="!importAddressGroup.importFileTypeError"><i
														class="fa fa-file-excel-o" aria-hidden="true"></i>
													{{importAddressGroup.uploadEvent.target.files[0].name}}</span>
												<span *ngIf="importAddressGroup.importFileTypeError" class="text-danger"><i
														class="fa fa-times" aria-hidden="true"></i>
													{{importAddressGroup.uploadEvent.target.files[0].name}}</span>
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center" *ngIf="importAddressGroup.importState==1">
						<img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="150"
							class="img-fluid d-inline-block align-top text-center" alt="">
						<h3>Uploading CSV File...</h3>
					</div>
					<div class="text-center" *ngIf="importAddressGroup.importState==2">
						<img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="150"
							class="img-fluid d-inline-block align-top text-center" alt="">
						<h5 class="mb-0 pb-0 text-success">Uploading Complete <i class="fa fa-check"
								aria-hidden="true"></i></h5>
						<h3>Submitting Import...</h3>
					</div>
					<div class="text-center" *ngIf="importAddressGroup.importState==3">
						<h2 class="fade-in text-success"><i class="fa fa-check" aria-hidden="true"></i> Upload Complete</h2>
						<p>Your CSV was uploaded successfully. <br />
							We are syncing the wallets into your portfolio. <br />
						</p>
					</div>
					<div class="text-center" *ngIf="importAddressGroup.importState==4">
						<h2 class="text-danger"><i class="fa fa-times" aria-hidden="true"></i> Upload Failed</h2>
						<p>This operation failed. <br />
							Please check if your using a valid CSV file. <br />
							Retry importing with a valid CSV file
						</p>
						<br />
						<div *ngFor="let error of importAddressGroup.errorList">
							<p class="text-danger mb 0" style="font-size: 1.1em; font-weight: 500; text-align: left; margin-bottom: 0;">
								<i class="fa fa-times" aria-hidden="true"></i> {{error.key}}</p>
							<p class="text-danger ml 2" style="font-size: 1em; font-weight: 300; text-align: left; margin-left: 1.1em;">
								{{error.description}}</p>
						</div>
					</div>
					<br />


					<button
						*ngIf="!importAddressGroup.walletsImported && importAddressGroup.importState==0 && this.importAddressGroup.uploadEvent != null"
						type="button" class="float-right btn btn-primary btn-lg" (click)="importAddressGroupEvent()">
						Import
					</button>
					<button *ngIf="this.importAddressGroup.uploadEvent == null" type="button"
						class="float-right btn btn-primary btn-lg disabled" [disabled]>
						Import
					</button>
					<button *ngIf="importAddressGroup.importState==1" type="button"
						class="float-right btn btn-primary btn-lg disabled" [disabled]>
						<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Uploading CSV File
					</button>

					<button *ngIf="importAddressGroup.importState==2" type="button"
						class="float-right btn btn-primary btn-lg disabled" [disabled]>
						<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Submitting Import
					</button>

					<button *ngIf="importAddressGroup.importState==3 || importAddressGroup.importState==4" type="button"
						class="float-right btn btn-primary btn-lg text-center"
						(click)="d('Cross click'); closeModal();importAddressGroup.importState=0">
						Close
					</button>
					<a *ngIf="global.appData != null && global.appSettings.assistance_links" class="mr-3 float-right mt-1" href="{{global.appData.tutorials.bulk_contact_import.link}}" target="_blank">Need Assistance?</a>
				</div>

				<div class="col-md-6">
					<button class="btn btn-primary mb-4" (click)="openSearchModal(searchPortfolioModal)">
						<i class="fa fa-search ml-2 mr-2" aria-hidden="true" style="font-size: 18px;"></i> Search for cryptocurrencies
					</button>
					<p>Please note that data should be imported using a CSV file. </p>
					<p>All addresses will be imported as part of the current group. Any existing addresses will be updated to include group membership, new addresses will added to both the group and broader address list.</p>
					<p>Download the template: <a class="text-primary"
							href="https://cw-templates-all.s3.us-east-2.amazonaws.com/address_book_import_template.csv">address_group_import_template.csv</a>

					<p class="text-bold">Instructions</p>
					<ol>
						<li>
							<p>
								Download the <a class="text-primary"
									href="https://cw-templates-all.s3.us-east-2.amazonaws.com/address_book_import_template.csv">address_group_import_template.csv</a>
								file.
							</p>
						</li>
						<li>
							<p>
								Add the addresses in the given format.
							</p>
						</li>
						<li>
							<p>
								Upload the file.
							</p>
						</li>
					</ol>
				</div>
			</div>
		</div>

	</div>


</ng-template>


<ng-template #editAddressGroup let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title pull-left" >Edit Group</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">

		<ngb-alert *ngIf="errorCode==1" [type]="'warning'" (close)="closeSubAlert()">
			Please enter a group name
		</ngb-alert>

		<div class="form-group" *ngIf="viewingGroup['group_name']">
			<label for="sel1">Group Name <small>*</small></label>
			<input type="text" [(ngModel)]="viewingGroup['temp_name']" name="groupName" class="form-control"
				id="groupName" placeholder="Group Name (e.g. Invoice Addresses)">
		</div>

		<div class="form-group">
			<label for="sel1">Group Description</label>
			<textarea type="text" maxlength="5000" [(ngModel)]="viewingGroup['temp_desc']" name="groupDescription"
				class="form-control" id="groupDescription" aria-describedby="Group Notes" placeholder="Description">
				{{ viewingGroup['group_desc'] }}
			</textarea>
		</div>
		
		<ng-container *ngIf="global._PRODUCT_ID == 'EP'">
			<div class="form-group" >
				<label for="sel1">Group Type</label>
					<select class="custom-select" [disabled] disabled [(ngModel)]="viewingGroup['temp_type']">
						<option value="null" disabled>Select...</option>
						<option value="0">Personal</option>
						<option value="1">Organizational</option>
						<option value="3">Workspace</option>
					</select>
			</div>
			<div class="form-group mb-1" *ngIf="viewingGroup['temp_type'] == 3 && viewingGroup['portfolioId'] != null">
				<div class="after-coin-select mb-2">
					<div class="selected-coin">
						<table class="table table-tx mb-0 table-nowrap card-table pt-2">
							<tbody>
								<tr class="">
									<td class="border-right">
										{{ viewingGroup['portfolioInfo']['name'] }}
									</td>
									<!-- <td class="padding:7px !important">
										<div class="float-right pointer"
											(click)="viewingGroup['portfolioId']=null;viewingGroup['portfolioName']=null;">
											<i class="fa fa-times" aria-hidden="true"></i>
										</div>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<small class="form-text text-muted">Once the address group type is assigned, it cannot be edited.</small>
		</ng-container>
		<hr/>

		
		<div class="card bg-light">
			<div class="card-body">
				<div class="form-group">
					<label for="sel1">Group Member Addresses</label>
		
						<div class="table-scrollable">
							<table class="table card-table pt-2" *ngIf="global.addresses != null">
								<thead>
									<tr>
										<th>Name</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									<tr class="pointer bg-white" *ngFor="let address of global.addresses; let i = index" [class.tr-select]="isGroupAddressSelected(address.id)" (click)="selectGroupAddress(address.id)">
										<td>
											<a class="" *ngIf="address.name!=null"><span>{{address.name}}</span></a>
											<a *ngIf="address.name==null"><small><i>not named</i></small></a>
										</td>
										<td class="padding:7px !important">
											<span *ngIf="!isGroupAddressSelected(address.id)" class="text-white"><i class="fa fa-check" aria-hidden="true"></i></span>
											<span *ngIf="isGroupAddressSelected(address.id)" class="text-primary"><i class="fa fa-check" aria-hidden="true"></i></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
				</div>

			</div>
		</div>
		

	</div>

	<div class="modal-footer">
		<button *ngIf="editingGroup" type="button" class="btn btn-primary btn-lg btn-block"
			(click)="updateAddressGroup(false)">
			Save Group
		</button>
		<button *ngIf="!editingGroup" type="button" class="btn btn-primary btn-lg btn-block disabled">
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif" /> Save Group
		</button>
	</div>

</ng-template>


<ng-template #deleteGroupConfirm let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title pull-left" >Delete Group</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<span class="text"> Are you sure you want to delete the group - <span class="text text-bold"> {{ viewingGroup.group_name }} </span>?</span>
	</div>

	<div class="modal-footer">
		<button *ngIf="!formState" type="button" class="btn btn-danger btn-lg btn-block"
			(click)="formState = true;deleteAddressGroup()">
			Delete
		</button>
		<button *ngIf="formState" type="button" class="btn btn-danger btn-lg btn-block disabled">
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif" /> Delete
		</button>
	</div>

</ng-template>

<ng-template #selectPortfolioModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="list-group" id="list-tab" role="tablist">
			<table class="table table-tx table-nowrap card-table pt-2"
				*ngIf="global.portfolios.length>=0">
				<thead>
					<tr>
						<th>Name</th>
						<th *ngIf="global.isBusinessAccount()">Type</th>
						<th>Currency</th>
						<th>Date Created</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr class="pointer"
						*ngFor="let portfolio of global.portfolios;let i = index"
						(click)="selectAddressPortfolio(portfolio.id, portfolio.name);d('Cross click')">
						<td>
							<span class="text-primary" *ngIf="portfolio.name!=null"
								ngbTooltip="{{portfolio.name}}"><span>{{ global.shortenNameDisplay(portfolio.name) }}</span></span>
							<span *ngIf="portfolio.name==null"><small><i>not named</i></small></span>
						</td>
						<td *ngIf="global.isBusinessAccount()">
							<span *ngIf="portfolio.company_id==0">Private</span>
							<span *ngIf="portfolio.company_id!=0">Company</span>
						</td>
						<td>
							<span>
								<img src="{{global.CDN_IMG_URL}}countries/c_flags/{{portfolio['portfolio_currency']['code']}}_r.png" width="16" height="16" class="d-inline-block align-top" alt="" style="margin-top: 5px; "> 
								<span> ({{portfolio['portfolio_currency']['code']}})</span>
							</span>
						</td>
						<td>
							<span *ngIf="portfolio.created_at!=null">{{ portfolio.created_at | amDateFormat:'LL' }}</span>
							<span *ngIf="portfolio.created_at==null"><small><i>--</i></small></span>
						</td>
						<td class="padding:7px !important">
							<span *ngIf="viewingGroup.portfolioId != portfolio.id" class="text-white"><i class="fa fa-check" aria-hidden="true"></i></span>
							<span *ngIf="viewingGroup.portfolioId == portfolio.id" class="text-primary"><i class="fa fa-check" aria-hidden="true"></i></span>
						</td>
					</tr>
					
				</tbody>
			</table>

		</div>

	</div>

</ng-template>
