<!-- horizontal bar-->
<nav class="navbar navbar-expand-lg bg-white fixed-top pt-0 pb-0 z2" *ngIf="global.layoutMode==0" [class.z1]="global.isdropdownOpen == 3">

	<a class="sidebar-brand d-flex align-items-center justify-content-center">
		<a (click)="coinService.gotoLink('workspaces')">
			<img src='{{global.FE_IMG_URL}}img/logo/logo.png' height="28px"  class="text-center animated--fade-in" alt="">
			<!-- <img src='{{global.FE_IMG_URL}}img/logo/emblem_dark.png' width="41px"  class="text-center animated--fade-in" alt="" *ngIf="global._PRODUCT_ID == 'EP'"> -->
		</a>
	</a>

	<div class="collapse navbar-collapse" id="navbarSupportedContent">
	  <ul class="navbar-nav mr-auto">
		<ng-container *ngIf="global.appData != null && global.appData.scheduledMaintenance != null">

			<li class="nav-item-no-hover pt-0 pointer active ml-1" (click)="openModal(maintenanceModal)">
				<a class="nav-link pointer text-danger pl-0"  >
					<i style="color: #f6c23e !important;" class="fa fa-exclamation-triangle" placement="right" ngbTooltip="Scheduled Maintenance: {{global.appData.scheduledMaintenance.dateTime}}" aria-hidden="true"></i>
					<br/>
					<p aria-colcount="" style="color: #f6c23e !important; font-size: 9px;" class="pl-1 mt-1 mb-0">
						Scheduled
					</p>
					<p style="color: #f6c23e !important; font-size: 9px;" class="pl-1">
						Maintenance
					</p>
				</a>
			</li>
		</ng-container>
		<ng-container >

			<li class="nav-item-no-hover pt-0 pointer active ml-3" (click)="openModal(maintenanceModal)" *ngIf="global._packagePurchaseDetails != null && global._packagePurchaseDetails.is_trial == 1">
				<a class="nav-link pointer text-danger pl-0"  style="font-size: 12px;background: red;color: #fff;padding: 3px;border-radius: 5px;" ngbTooltip="Trial Account. Features maybe limited.">
					<!-- <i style="color: #fff !important;" class="fa fa-exclamation-triangle" placement="right" ngbTooltip="Trial Account. Features maybe limited." aria-hidden="true"></i> -->
					<!-- <br/> -->
					<p aria-colcount="" style="color: #fff !important; font-size: 10px;" class="pl-1 mt-1 mb-0">
						Trial
					</p>
					<p style="color: #fff !important; font-size: 10px;" class="pl-1 mb-1">
						Account
					</p>
				</a>
			</li>
		</ng-container>
	  </ul>
	  <ul class="nav justify-content-end">
		<!-- <li class="nav-item">
		  <a class="">
				<span (click)="global.layoutMode='mini'">Mini</span>
				<span (click)="global.layoutMode='top'">Top</span>
			</a>
		</li> -->
		<!-- <li class="nav-item">
		  <a class="">
				<span (click)="global.layoutMode='mini'">{{global.currentPortfolioId}} - </span>
				<span (click)="global.layoutMode='top'">{{global.lastPortfolio}}</span>
			</a>
		</li> -->
		<ng-container *ngIf="global._packagePurchaseDetails!=null && global._packagePurchaseDetails['app_accounting'] != 0">
			<li class="nav-item-no-hover py-1 pointer active" >
				<a class="nav-link pointer menu-pl text-primary" *ngIf="global.currentPortfolioId == null && global.lastPortfolio != null" (click)="coinService.loadPortfolio(global.lastPortfolio)">
					<span ngbTooltip="Back to portfolio: {{global['lastPortfolio']['name']}}">
						<i class="fa fa-arrow-left" aria-hidden="true"></i> Back
					</span>
				</a>
			</li>
		</ng-container>

		<li class="nav-item-no-hover py-1 pointer active" ngbTooltip="Accounting" *ngIf="global._packagePurchaseDetails!=null && global._packagePurchaseDetails['app_accounting'] != 0">

			<a class="nav-link pointer menu-pl" (click)="coinService.gotoLink('workspaces')">
				<div>
					<i style="font-size: 19px;" class="fa fa-briefcase" aria-hidden="true" ></i>
				</div>
			</a>
		</li>
		<li class="nav-item-no-hover py-1 pointer active" ngbTooltip="Explore">
			<a class="nav-link pointer menu-pl"  (click)="coinService.gotoLink('explore')" >
				<div>
					<i style="font-size: 19px;" class="fa fa-binoculars" aria-hidden="true"></i>
				</div>
			</a>
		</li>
		<li class="nav-item-no-hover py-1 pointer active" ngbTooltip="Address Book">
			<a class="nav-link pointer menu-pl" (click)="addressBookVisit(addressViewModal)" >
				<div>
					<i style="font-size: 19px;" class="fa fa-address-book-o"  aria-hidden="true"></i>
				</div>
			</a>
		</li>
		<!-- <li class="nav-item-no-hover py-1 pointer active" ngbTooltip="Marketplace">
			<a class="nav-link pointer menu-pl"  href="https://www.cryptoworth.com/marketplace" target="_blank" >
				<div>
					<i style="font-size: 19px;" class="fa fa-shopping-basket" aria-hidden="true"></i>
				</div>
			</a>
		</li> -->
		<li class="nav-item-no-hover py-1 pointer active" ngbTooltip="Library">
			<a class="nav-link pointer menu-pl"  (click)="coinService.gotoLink('library')" >
				<div>
					<i style="font-size: 19px;" class="fa fa-book" aria-hidden="true"></i>
				</div>
			</a>
		</li>



		<li ngbDropdown class="nav-item active nodropcaret pt-1" >

			<div ngbDropdown placement="bottom-right" >

				<div>
					<div class="modal-backdrop fade show" *ngIf="global.isdropdownOpen=='supportHeader'" (click)="global.dropdownOpenClose(0)"></div>
					<a class="nav-link pointer menu-pl"  [class.text-white]="global.isdropdownOpen=='supportHeader'" [class.upfront]="global.isdropdownOpen=='supportHeader'"  id="dropdownBasic2"  (click)="global.dropdownOpenClose('supportHeader')" ngbDropdownToggle>
						<i style="font-size: 19px;" class="fa fa-question-circle"  aria-hidden="true"></i>
						<span class="pb-2" style="vertical-align: top;">
							Help
						</span>
					</a>
				</div>
				<div class="main-dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic2" [class.upfront]="global.isdropdownOpen=='supportHeader'">

					<a target="_blank" href="https://help.cryptoworth.com" class="dropdown-item notify-item pointer"  (click)="global.dropdownOpenClose(0);">
						<i class="mr-2 fa fa-question-circle" aria-hidden="true"></i>Help Center
					</a>
					<a target="_blank" href="https://www.youtube.com/@cryptoworthofficial" class="dropdown-item notify-item pointer"  (click)="global.dropdownOpenClose(0);">
						<i class="mr-2 fa fa-youtube-play" aria-hidden="true"></i>YouTube Channel
					</a>
					<a target="_blank" href="https://blog.cryptoworth.com" class="dropdown-item notify-item pointer"  (click)="global.dropdownOpenClose(0);">
						<i class="mr-2 fa fa-rss-square" aria-hidden="true"></i>Blog
					</a>
					<a class="dropdown-item notify-item pointer" (click)="showOnboardingPopup(onboardingModal);global.dropdownOpenClose(0);">
						<i class="mr-2 fa fa-file-text" aria-hidden="true"></i>Onboarding Checklist
					</a>
					<a target="_blank" href="https://app.swaggerhub.com/apis-docs/Cryptoworth-Dev/Cryptoworth-Portfolio-Private-API/1.0.0#/" class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)">
						<i class="mr-2 fa fa-code" aria-hidden="true"></i>Developer Docs
					</a>
					<hr class="min-hr"/>

					<a target="_blank" href="{{global.appData.tutorials.known_issues_common.link}}" class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)">
						<i class="mr-2 fa fa-exclamation-circle" aria-hidden="true"></i>Known Issues
					</a>

					<hr class="min-hr"/>
					<!-- item-->
					<a target="_blank" href="https://status.cryptoworth.app/" class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)">
						<i class="mr-2 fa fa-wifi" aria-hidden="true" ></i>Platform Status
					</a>
					<hr class="min-hr"/>
					<a class="span dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0);openShareFeedbackModal(shareFeedbackModal)" >
						<i class="mr-2 fa fa-paper-plane-o" aria-hidden="true"></i>Share Feedback
					</a>


				</div>
			</div>

		</li>
		<!-- <li class="nav-item-no-hover dropdown active pointer">
			<a class="nav-link pointer menu-pl" ngbTooltip="Help & Support" (click)="coinService.gotoLink('support')">
				<i class="fa fa-question-circle " aria-hidden="true"></i>
			</a>
		</li> -->
		<li ngbDropdown class="nav-item active nodropcaret pt-1 pb-2" >

			<div ngbDropdown  placement="bottom-right" ngbTooltip="User options">

				<div>
					<div class="modal-backdrop fade show" *ngIf="global.isdropdownOpen==1" (click)="global.dropdownOpenClose(0)"></div>
					<a class="nav-link pointer p-0"  [class.upfront]="global.isdropdownOpen==1"  id="dropdownBasic2"  (click)="global.dropdownOpenClose(1)" ngbDropdownToggle>
						<span class="user-display-top" *ngIf=" global._userDetails['firstName'] != null && global._userDetails['lastName'] != null">
							{{ global._userDetails['first_name'][0].toUpperCase() }}{{ global._userDetails['last_name'][0].toUpperCase() }}
							<br/>
						</span>
						<span class="user-display-top" *ngIf=" global._userDetails['firstName'] == null || global._userDetails['lastName'] == null" >
							<i style="color: #fff !important;margin-right: 0px;" class="fa fa-user text-white" aria-hidden="true"></i>
							<br/>
						</span>
					</a>
				</div>
				<a class="nav-link" id="dropdownBasic2" [class.upfront]="global.isdropdownOpen==1"  *ngIf=" global._userDetails['firstName']== null && global._userDetails['lastName'] == null" (click)="global.dropdownOpenClose(1)" ngbDropdownToggle>
					<i class="fa fa-sort-desc" aria-hidden="true"></i>
				</a>
				<div class="main-dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic2" [class.upfront]="global.isdropdownOpen==1">
					<div class=" dropdown-header noti-title pointer pb-0" >
						<span class="text-overflow m-0 text-center pointer" >
							<span class="pointer txtoverflow"  *ngIf=" global._userDetails['first_name'] != null || global._userDetails['last_name'] != null">
								<span *ngIf=" global._userDetails['firstName'] != null">{{ global._userDetails['first_name'] }}</span>
								<span *ngIf="global._userDetails['lastName'] != null"> {{ global._userDetails['last_name'] }}</span>
								<br/>
							</span>
							<small class="pointer">
								<b>{{ global._userDetails['email']}}</b>
							</small>
						</span>
					</div>
					<span>
						<p class="pointer text-primary mb-0"  style="font-size: 12px !important;display: block;padding: .5rem 1.5rem;" (click)="global.dropdownOpenClose(0)" [routerLink]="['/logout']">
							Logout
						</p>
					</span>
					<hr class="min-hr"/>
					<span class="dropdown-item notify-item pointer" *ngIf="global._companyDetails!=null" (click)="global.dropdownOpenClose(0); coinService.gotoLink('company', global._companyDetails.id)">
						Organization
					</span>
					<span class="dropdown-item notify-item pointer" *ngIf="global._companyDetails==null" (click)="global.dropdownOpenClose(0); openModal(upgradePlanEPModal)">
						Organization
					</span>

					<!-- item-->
					<span class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)"(click)="coinService.gotoLink('settings');global.viewSettings = true">
						Settings
					</span>

					<!-- item-->
					<span class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)" [routerLink]="['/billing']" *ngIf="global._packageDetails != null && global._isOwner">
						Billings
					</span>
					<hr class="min-hr"/>
					<a class="span dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)"  (click)="openLayoutModal(layoutModal)">
						Layout & Style
					</a>

					<hr class="min-hr"/>
					<div class=" dropdown-header noti-title">

							<a href="https://cryptoworth.com/legal/terms" target="_blank" (click)="global.dropdownOpenClose(0)">
									<small>Terms</small>
							</a>&nbsp;&nbsp;

							<a href="https://cryptoworth.com/legal/privacy" target="_blank" (click)="global.dropdownOpenClose(0)">
								<small>Privacy</small>
							</a> &nbsp;&nbsp;

							<a target="_blank" href="https://cryptoworth.com/legal/disclaimer" >
								<small>Disclaimer</small>

							</a>

					</div>

					<hr style="margin: 0px"/>
					<div class=" dropdown-header noti-title">
						<small>
							<span *ngIf="global.socketConnectinStatus==1">
								<i class="fa fa-circle" style="color:#ff810e;"></i> Checking the server heart-beat.
							</span>
							<span *ngIf="global.socketConnectinStatus==2">
								<i class="fa fa-circle" style="color:#04c51d;"></i> Connected
							</span>
							<span *ngIf="global.socketConnectinStatus==3">
								<i class="fa fa-circle" style="color:#ef0000;"></i> Offline
							</span>
						</small>
					</div>
					<div class="bg-light">
						<div class="row py-2 px-3">
							<div class="col">
								Download Apps:
								<span class="pull-right">
									<a class="mr-2 ml-2" href="https://apps.apple.com/us/app/cryptoworth/id1581143312?ign-mpt=uo%3D2" target="_blank">
										<i class="fa fa-apple" aria-hidden="true"></i>
									</a>
									<a class="ml-1" href="https://play.google.com/store/apps/details?id=com.cryptoworth.app" target="_blank">
										<i class="fa fa-android" aria-hidden="true"></i>
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

		</li>
	  </ul>
	</div>
</nav>

<!-- vertical bar-->
<ul class="navbar-nav bg-white sidebar sidebar-dark accordion position-fixed shadow-slight z2" *ngIf="global.layoutMode==1" id="accordionSidebar"  [class.z1]="global.isdropdownOpen == 3">
	<div class="row">
		<div class=" fullvh pr-0 col-md-3 col-md-12" >
			<a class="sidebar-brand d-flex align-items-center justify-content-center">
				<a (click)="coinService.gotoLink('workspaces')">
					<img src='{{global.FE_IMG_URL}}img/logo/emblem.png' width="41px"  class="text-center animated--fade-in" alt="">
					<!-- <img src='{{global.FE_IMG_URL}}img/logo/emblem_dark.png' width="41px"  class="text-center animated--fade-in" alt="" *ngIf="global._PRODUCT_ID == 'EP'"> -->
				</a>
			</a>

			<!-- Divider -->

			<!-- <li class="nav-item dropdown active pointer">

				<div>
					<p (click)="global.layoutMode='mini'">Mini</p>
					<p (click)="global.layoutMode='top'">Top</p>
				</div>
			</li> -->

			<!-- Nav Item - Dashboard -->
			<ng-container *ngIf="global._packagePurchaseDetails!=null && global._packagePurchaseDetails['app_accounting'] != 0">
				<li class="nav-item-no-hover py-1 pointer active"  *ngIf="global.currentPortfolioId == null && global.lastPortfolio != null">
					<a class="nav-link pointer menu-pl text-primary pt-2 pb-0" (click)="coinService.loadPortfolio(global.lastPortfolio)">
						<span ngbTooltip="Back to portfolio: {{global['lastPortfolio']['name']}}">
							<i class="fa fa-arrow-left" aria-hidden="true"></i> Back
						</span>
					</a>
				</li>
			</ng-container>

			<li class="nav-item-no-hover pointer active " *ngIf="global._packagePurchaseDetails!=null && global._packagePurchaseDetails['app_accounting'] != 0">
				<a class="nav-link pointer menu-pl" (click)="coinService.gotoLink('workspaces')">
					<div>
						<i class="fa fa-briefcase icon-md" aria-hidden="true" ngbTooltip="Workspaces"></i>
					</div>
				</a>
			</li>
			<li class="nav-item-no-hover py-1 pointer active" ngbTooltip="Explore" *ngIf="global.authService.ENV_SET != 'prod'">
				<a class="nav-link pointer menu-pl"  (click)="coinService.gotoLink('explore')" >
					<div>
						<i style="font-size: 19px;" class="fa fa-binoculars" aria-hidden="true"></i>
					</div>
				</a>
			</li>
			<li class="nav-item-no-hover pt-0 pointer active ">
				<a class="nav-link pointer menu-pl" (click)="addressBookVisit(addressViewModal)" >
					<div>
						<i class="fa fa-address-book-o icon-md" placement="right" ngbTooltip="Address Book" aria-hidden="true"></i>
					</div>
				</a>
			</li>
			<!-- <li class="nav-item-no-hover py-1 pointer active" ngbTooltip="Marketplace">
				<a class="nav-link pointer menu-pl" href="https://www.cryptoworth.com/marketplace" target="_blank">
					<div>
						<i class="fa fa-shopping-basket icon-md" aria-hidden="true"></i>
					</div>
				</a>
			</li> -->
			<li class="nav-item-no-hover py-1 pointer active" ngbTooltip="Library">
				<a class="nav-link pointer menu-pl"  (click)="coinService.gotoLink('library')" >
					<div>
						<i class="fa fa-book icon-md" aria-hidden="true"></i>
					</div>
				</a>
			</li>


			<ng-container *ngIf="global.appData != null && global.appData.scheduledMaintenance != null">

				<li class="nav-item-no-hover pt-0 pointer active ml-1" (click)="openModal(maintenanceModal)">
					<a class="nav-link pointer text-danger pl-0"  >
						<i style="color: #f6c23e !important;" class="fa fa-exclamation-triangle icon-md" placement="right" ngbTooltip="Scheduled Maintenance: {{global.appData.scheduledMaintenance.dateTime}}" aria-hidden="true"></i>
						<br/>
						<p aria-colcount="" style="color: #f6c23e !important; font-size: 9px;" class="pl-1 mt-1 mb-0">
							Scheduled
						</p>
						<p style="color: #f6c23e !important; font-size: 9px;" class="pl-1">
							Maintenance
						</p>
					</a>
				</li>
			</ng-container>

			<ng-container >

				<li class="nav-item-no-hover pt-0 pointer active ml-3" (click)="openModal(maintenanceModal)" *ngIf="global._packagePurchaseDetails != null && global._packagePurchaseDetails.is_trial == 1">
					<a class="nav-link pointer text-danger pl-0"  style="font-size: 12px;background: red;color: #fff;padding: 3px;border-radius: 5px;" ngbTooltip="Trial Account. Features maybe limited.">
						<!-- <i style="color: #fff !important;" class="fa fa-exclamation-triangle icon-md" placement="right" ngbTooltip="Trial Account. Features maybe limited." aria-hidden="true"></i> -->
						<!-- <br/> -->
						<p aria-colcount="" style="color: #fff !important; font-size: 10px;" class="pl-1 mt-1 mb-0">
							Trial
						</p>
						<p style="color: #fff !important; font-size: 10px;" class="pl-1 mb-1">
							Account
						</p>
					</a>
				</li>
			</ng-container>


			<div class="">

				<ul class="navbar-nav sidebars sb-footer-nav">
					<!-- <li class="nav-item-no-hover dropdown active pointer">

						<div>
							<a class="nav-link menu-pl pb-0" placement="right" ngbTooltip="Help & Support" (click)="coinService.gotoLink('support')">
								<i class="fa fa-question-circle icon-md" aria-hidden="true"></i>
							</a>
						</div>
					</li> -->

					<li ngbDropdown class="nav-item-no-hover dropdown active pointer nodropcaret " >

						<div ngbDropdown  placement="right-bottom" ngbTooltip="Help">

							<div>
								<div class="modal-backdrop fade show" *ngIf="global.isdropdownOpen=='supportHeader'" (click)="global.dropdownOpenClose(0)"></div>
								<a class="nav-link pointer menu-pl"  [class.text-white]="global.isdropdownOpen=='supportHeader'" [class.upfront]="global.isdropdownOpen=='supportHeader'"  id="dropdownBasic2"  (click)="global.dropdownOpenClose('supportHeader')" ngbDropdownToggle>
									<i class="fa fa-question-circle icon-md" aria-hidden="true"></i>
								</a>
							</div>
							<div class="main-dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic2" [class.upfront]="global.isdropdownOpen=='supportHeader'">

								<a target="_blank" href="https://help.cryptoworth.com" class="dropdown-item notify-item pointer"  (click)="global.dropdownOpenClose(0);">
									<i class="mr-2 fa fa-question-circle" aria-hidden="true"></i> Help Center
								</a>
								<a target="_blank" href="https://www.youtube.com/@cryptoworthofficial" class="dropdown-item notify-item pointer"  (click)="global.dropdownOpenClose(0);">
									<i class="mr-2 fa fa-youtube-play" aria-hidden="true"></i> Youtube Channel
								</a>
								<a target="_blank" href="https://blog.cryptoworth.com" class="dropdown-item notify-item pointer"  (click)="global.dropdownOpenClose(0);">
									<i class="mr-2 fa fa-rss-square" aria-hidden="true"></i> Blog
								</a>
								<a class="dropdown-item notify-item pointer" (click)="showOnboardingPopup(onboardingModal);global.dropdownOpenClose(0);">
									<i class="mr-2 fa fa-file-text" aria-hidden="true"></i>Onboarding Checklist
								</a>
								<a target="_blank" href="https://app.swaggerhub.com/apis-docs/Cryptoworth-Dev/Cryptoworth-Portfolio-Private-API/1.0.0#/" class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)">
									<i class="mr-2 fa fa-code" aria-hidden="true"></i> Developer Docs
								</a>

								<hr class="min-hr"/>
								<!-- item-->
								<a target="_blank" href="https://status.cryptoworth.app/" class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)">
									<i class="mr-2 fa fa-wifi" aria-hidden="true" ></i> Platform Status
								</a>
								<hr class="min-hr"/>
								<a class="span dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0);openShareFeedbackModal(shareFeedbackModal)">
									<i class="mr-2 fa fa-paper-plane-o" aria-hidden="true"></i> Share feedback
								</a>


							</div>
						</div>

					</li>

					<!-- <li class="nav-item dropdown active nodropcaret">

						<div>
							<a class="nav-link pb-2" style="padding-left: 12px !important;">
								<small class="text-center">
									<small *ngIf="global.serverInfo!=null">
										{{ global.serverInfo['version'] }}
									</small> <br/>
									<span *ngIf="global.socketConnectinStatus==1">
										<img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="10"  class="img-fluid d-inline-block align-top text-center" alt="">
									</span>
									<span *ngIf="global.socketConnectinStatus==2">
										<i class="fa fa-wifi" aria-hidden="true" style="color:#04c51d !important;"></i>
									</span>
									<span *ngIf="global.socketConnectinStatus==3">
										<i class="fa fa-wifi" style="color:#ef0000 !important; "></i>
									</span>
								</small>
							</a>

						</div>
					</li>
					 -->
					<li ngbDropdown class="nav-item active nodropcaret " >

						<div ngbDropdown placement="right-bottom" ngbTooltip="User options">


							<div>
								<div class="modal-backdrop fade show" *ngIf="global.isdropdownOpen==1" (click)="global.dropdownOpenClose(0)"></div>
								<a class="nav-link pointer"  [class.upfront]="global.isdropdownOpen==1"  id="dropdownBasic2"  (click)="global.dropdownOpenClose(1)" ngbDropdownToggle>
									<span class="user-display" *ngIf=" global._userDetails['firstName'] != null && global._userDetails['lastName'] != null">
										{{ global._userDetails['first_name'][0].toUpperCase() }}{{ global._userDetails['last_name'][0].toUpperCase() }}
										<br/>
									</span>
									<span class="user-display" *ngIf=" global._userDetails['firstName'] == null || global._userDetails['lastName'] == null" >
										<i style="color: #fff !important;margin-right: 0px;" class="fa fa-user text-white" aria-hidden="true"></i>
										<br/>
									</span>
								</a>
							</div>
							<a class="nav-link" id="dropdownBasic2" [class.upfront]="global.isdropdownOpen==1"  *ngIf=" global._userDetails['firstName']== null && global._userDetails['lastName'] == null" (click)="global.dropdownOpenClose(1)" ngbDropdownToggle>
								<i class="fa fa-sort-desc" aria-hidden="true"></i>
							</a>

							<div ngbDropdownMenu class="main-dropdown-menu" aria-labelledby="dropdownBasic2" [class.upfront]="global.isdropdownOpen==1">
								<div class=" dropdown-header noti-title pointer pb-0" >
									<span class="text-overflow m-0 text-center pointer" >
										<span class="pointer txtoverflow"  *ngIf=" global._userDetails['first_name'] != null || global._userDetails['last_name'] != null">
											<span *ngIf=" global._userDetails['firstName'] != null">{{ global._userDetails['first_name'] }}</span>
											<span *ngIf="global._userDetails['lastName'] != null"> {{ global._userDetails['last_name'] }}</span>
											<br/>
										</span>
										<small class="pointer">
											<b>{{ global._userDetails['email']}}</b>
										</small>
									</span>
								</div>
								<span>
									<p class="pointer text-primary mb-0"  style="font-size: 12px !important;display: block;padding: .5rem 1.5rem;" (click)="global.dropdownOpenClose(0)" [routerLink]="['/logout']">
										Logout
									</p>
								</span>
								<hr class="min-hr"/>
								<span class="dropdown-item notify-item pointer" *ngIf="global._companyDetails!=null" (click)="global.dropdownOpenClose(0); coinService.gotoLink('company', global._companyDetails.id)">
									Organization
								</span>
								<span class="dropdown-item notify-item pointer" *ngIf="global._companyDetails==null" (click)="global.dropdownOpenClose(0); openModal(upgradePlanEPModal)">
									Organization
								</span>

								<!-- item-->
								<span class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)"(click)="coinService.gotoLink('settings');global.viewSettings = true">
									Settings
								</span>

								<!-- item-->
								<span class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)" [routerLink]="['/billing']" *ngIf="global._packageDetails != null && global._isOwner">
									Billings
								</span>
								<hr class="min-hr"/>
								<a class="span dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)"  (click)="openLayoutModal(layoutModal)">
									Layout & Style
								</a>

								<hr class="min-hr"/>
								<div class=" dropdown-header noti-title">

										<a href="https://cryptoworth.com/legal/terms" target="_blank" (click)="global.dropdownOpenClose(0)">
												<small>Terms</small>
										</a>&nbsp;&nbsp;

										<a href="https://cryptoworth.com/legal/privacy" target="_blank" (click)="global.dropdownOpenClose(0)">
											<small>Privacy</small>
										</a> &nbsp;&nbsp;

										<a target="_blank" href="https://cryptoworth.com/legal/disclaimer" >
											<small>Disclaimer</small>

										</a>

								</div>

								<hr style="margin: 0px"/>
								<div class=" dropdown-header noti-title">
									<small>
										<span *ngIf="global.socketConnectinStatus==1">
											<i class="fa fa-circle" style="color:#ff810e;"></i> Checking the server heart-beat.
										</span>
										<span *ngIf="global.socketConnectinStatus==2">
											<i class="fa fa-circle" style="color:#04c51d;"></i> Connected
										</span>
										<span *ngIf="global.socketConnectinStatus==3">
											<i class="fa fa-circle" style="color:#ef0000;"></i> Offline
										</span>
									</small>
								</div>
								<div class="bg-light">
									<div class="row py-2 px-3">
										<div class="col">
											Download Apps:
											<span class="pull-right">
												<a class="mr-2 ml-2" href="https://apps.apple.com/us/app/cryptoworth/id1581143312?ign-mpt=uo%3D2" target="_blank">
													<i class="fa fa-apple" aria-hidden="true"></i>
												</a>
												<a class="ml-1" href="https://play.google.com/store/apps/details?id=com.cryptoworth.app" target="_blank">
													<i class="fa fa-android" aria-hidden="true"></i>
												</a>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>

					</li>

				</ul>

			</div>
		</div>

	</div>

</ul>

<app-onboarding></app-onboarding>

<ng-template #layoutModal let-c="close" let-d="dismiss">

    <div class="modal-header bb-0">
        <!-- <h4 class="modal-title pull-left">Layout</h4> -->
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pt-0">
		<app-layout></app-layout>
		<br/>
		<br/>
    </div>

</ng-template>



<ng-template #shareFeedbackModal let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h4 class="modal-title pull-left">Share Feedback</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
		<ng-container *ngIf="feedbackSent == 0">
			<p>Help us improve the platform by providing us a feedback or telling us what's on your wishlist.</p>
			<div class="form-group">
				<textarea type="text" maxlength="5000" [(ngModel)]="feedbackDescription" name="feedbackDescription"
					class="form-control" id="feedbackDescription" aria-describedby="Your feedbac" placeholder="Your feedback">
				</textarea>
				Have a specific issue? Contact our support team via our <a class="text-primary pointer" target="_blank" href="https://help.cryptoworth.com">Help Centre <i class="fa fa-external-link" aria-hidden="true"></i></a>.
			</div>
		</ng-container>
		<ng-container *ngIf="feedbackSent == 1">
			<div class="alert alert-success" role="alert">
				Thank you for your valuable feedback. Our team will get back to you shortly if required.
			</div>
		</ng-container>
		<ng-container *ngIf="feedbackSent == -1">
			<div class="alert alert-danger" role="alert">
				Something went wrong. Please contact the support team via the <a class="text-primary pointer" target="_blank" href="https://help.cryptoworth.com">Help Centre <i class="fa fa-external-link" aria-hidden="true"></i></a>.
			</div>
		</ng-container>
    </div>
	<div class="modal-footer" *ngIf="feedbackSent == 0">
		<button *ngIf="!sendingFeedback" type="button" class="btn btn-primary btn-lg btn-block"
			(click)="sendFeedback()">
			Send Feedback
		</button>
		<button *ngIf="sendingFeedback" type="button" class="btn btn-primary btn-lg btn-block disabled">
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif" /> Send Feedback
		</button>
	</div>
</ng-template>


<ng-template #upgradePlanEPModal let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h4 class="modal-title pull-left">Upgrade Plan</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-upgrade-plan-ep (click)="closeModal();"></app-upgrade-plan-ep>
    </div>
</ng-template>



<ng-template #addressViewModal let-c="close" let-d="dismiss">

    <div class="modal-header bb-0">
        <a  class="close pull-right py-2 pr-4" aria-label="Close" (click)="d('Cross click');closeModal()">
        	<span aria-hidden="true">&times;</span>
		</a>
    </div>
    <div class="modal-body p-0">
        <app-address-book-view [viewMode]="'mini'" (closeModalRequestEvent)="closeModal()"></app-address-book-view>
    </div>
</ng-template>


<ng-template #maintenanceModal let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h4 class="modal-title pull-left">Scheduled Maintenance</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Cryptoworth will perform a scheduled maintenance on <span class="text-bold">{{ global.appData.scheduledMaintenance.dateTime }}</span>.
		<br/><br/>
		The system will not be accessible within the time window. Sorry for any inconvenience caused.
    </div>
</ng-template>



