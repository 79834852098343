<app-header-guest *ngIf="!global._authStatus"></app-header-guest>
<app-header *ngIf="global._authStatus"></app-header>

<div>
    <div class="container text-center">
        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-8">
                <br/>
                <form >
                    <div  class="alert alert-danger" role="alert">
                        <h1>
                            Sorry, the page you requested is not available.
                        </h1>
                    </div>
                </form>
            </div>
            <div class="col-md-2">
            </div>
        </div>
    </div>
</div>